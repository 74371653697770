import { useState } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import {
    Avatar,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Popover,
    Stack,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import SwitchButton from '@common/components/buttons/SwitchButton';
import DialogButton from '@common/components/dialogs/DialogButton';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import DragDropFile from '@common/components/dragDropFile/DragDropFile';
import selectorLanguages from '@common/helpers/selectorLanguages.json';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import useWorkspace from '@common/hooks/useWorkspace';
import palette from '@common/theme/palette/palette';
import { getLanguageFlag } from '@common/utils/languages';
import { getWriteSignedUrl, uploadToGCS } from '@setup/api/gcs';

TemplateMenuConfig.propTypes = {
    isActive: PropTypes.bool,
    handleSetActive: PropTypes.func.isRequired,
    isShared: PropTypes.bool,
    handleShare: PropTypes.func.isRequired,
    reportLanguage: PropTypes.string.isRequired,
    handleChangeReportLanguage: PropTypes.func.isRequired
};

export default function TemplateMenuConfig({
    isActive,
    handleSetActive,
    isShared,
    handleShare,
    reportLanguage,
    handleChangeReportLanguage
}) {
    const { getPlan } = useUser();
    const { workspaceExists } = useWorkspace();

    const [anchorElChip, setAnchorElChip] = useState(null);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleOpenList = (event) => {
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setAnchorElChip(null);
    };

    const handleOnChange = () => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
        } else {
            handleSetActive();
        }
    };

    const handleChangeLanguage = async (languageCode) => {
        try {
            await handleChangeReportLanguage(languageCode);
            handleCloseList();
        } catch (error) {
            console.log('Error changing language');
        }
    };

    const handleOnFileSelection = (file) => {
        if (file.type !== 'application/pdf') {
            setAlertMessage('Solo se permiten archivos en formato PDF.');
            return;
        } else if (file.size > 200000000) {
            setAlertMessage('El archivo es demasiado grande. El tamaño máximo permitido es 200MB.');
            return;
        }
        try {
            setFile(file);
            setFilename(file.name);
        } catch (error) {
            setFilename('');
            console.error(error);
        }
    };

    const hanldeUpload = async () => {
        const filetype = file.type || 'unknown';
        setLoading(true);
        console.log(file);
        try {
            const signedUrl = await getWriteSignedUrl({
                filename: `documents/${filename}`,
                filetype
            });
            await uploadToGCS({ signedUrl, file });
            console.log('File created: ', filename);

            setLoading(false);
            handleCloseDialog();
            return true;
        } catch (error) {
            console.error(error.message);
            handleCloseDialog();
        }
    };

    const handleCloseDialog = () => {
        setAlertMessage('');
        setLoading(false);
    };

    // useEffect(() => {
    //     (async () => {
    //         // TODO: retrieve writingStyleId from template
    //         // const file = await openaiRetrieveFile('file-T7GafUs82PqrXjd7oxAYVMkN');
    //         console.log('File', file);
    //         setFile(file);
    //     })();
    // }, []);

    return (
        <>
            <Grid container direction='column' alignItems='left' spacing={2}>
                <Grid item xs={12}>
                    <FormControl component='fieldset' variant='standard'>
                        <FormLabel component='legend' color='primary'>
                            Activar por defecto
                        </FormLabel>
                        <FormControlLabel
                            // We just run onChange for non-activated templates
                            control={<SwitchButton checked={isActive} onChange={handleOnChange} />}
                            disabled={isActive}
                            label={
                                isActive
                                    ? 'Plantilla activada por defecto para tus reuniones'
                                    : 'Activa esta plantilla por defecto para tus reuniones'
                            }
                            labelPlacement='start'
                            sx={{
                                mt: -1,
                                ml: 0,
                                '& .MuiFormControlLabel-label': {
                                    color: palette.primary[400], // Customize the label color
                                    typography: 'body2' // Customize the label typography
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                {workspaceExists && (
                    <Grid item xs={12}>
                        <FormControl component='fieldset' variant='standard'>
                            <FormLabel component='legend' color='primary'>
                                Compartir
                            </FormLabel>
                            <FormControlLabel
                                // We just run onChange for non-activated templates
                                control={<SwitchButton checked={isShared} onChange={handleShare} />}
                                label={
                                    isShared
                                        ? 'Tu equipo tiene acceso a esta plantilla'
                                        : 'Tu equipo tendrá acceso a esta plantilla'
                                }
                                labelPlacement='start'
                                sx={{
                                    mt: -1,
                                    ml: 0,
                                    '& .MuiFormControlLabel-label': {
                                        color: palette.primary[400], // Customize the label color
                                        typography: 'body2' // Customize the label typography
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormControl component='fieldset' variant='standard'>
                        <FormLabel component='legend' color='primary'>
                            Idioma del informe
                        </FormLabel>
                    </FormControl>
                    <Stack spacing={1} direction='row' alignItems='center'>
                        <Chip
                            sx={{
                                borderRadius: 2,
                                justifyContent: 'left'
                            }}
                            label={
                                selectorLanguages.find(
                                    (language) => language.code === reportLanguage
                                ).label
                            }
                            color={'chip_grey'}
                            size='small'
                            avatar={
                                reportLanguage && <Avatar src={getLanguageFlag(reportLanguage)} />
                            }
                            icon={!reportLanguage && <AutoAwesomeIcon />}
                        />
                        <IconButtonWithTooltip
                            icon={<ExpandMoreOutlinedIcon size='small' />}
                            tooltipText={'Cambiar idioma del informe'}
                            onClick={handleOpenList}
                        />
                    </Stack>

                    <Popover
                        sx={{ mt: '45px' }}
                        id='menu-filters'
                        anchorEl={anchorElChip}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        open={Boolean(anchorElChip)}
                        onClose={handleCloseList}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction={'column'}
                            sx={{ alignItems: 'left', m: 0 }}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    spacing={0}
                                    direction={'column'}
                                    sx={{ alignItems: 'left' }}
                                >
                                    {selectorLanguages.map((language, index) => (
                                        <Grid
                                            item
                                            key={language.code}
                                            sx={{
                                                mt: 1,
                                                ml: 1,
                                                mr: 1,
                                                mb: selectorLanguages.length - 1 === index ? 1 : 0
                                            }}
                                        >
                                            <Chip
                                                sx={{
                                                    flexGrow: 1,
                                                    borderRadius: 2,
                                                    justifyContent: 'left'
                                                }}
                                                label={language.label}
                                                onClick={async () =>
                                                    await handleChangeLanguage(language.code)
                                                }
                                                color={'chip_grey'}
                                                size='small'
                                                avatar={
                                                    language.code && (
                                                        <Avatar
                                                            src={getLanguageFlag(language.code)}
                                                        />
                                                    )
                                                }
                                                icon={!language.code && <AutoAwesomeIcon />}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Popover>
                    {file && (
                        <Grid item sx={{ mt: 1 }}>
                            <Stack
                                direction={'row'}
                                spacing={2}
                                p={1}
                                border='1px solid'
                                borderColor={palette.primary[300]}
                                borderRadius={2}
                                backgroundColor={palette.primary[50]}
                                sx={{ alignItems: 'center' }}
                            >
                                <DescriptionRoundedIcon fontSize='large' />
                                <Stack direction={'column'} spacing={0.5} flexGrow={1}>
                                    <Typography variant='body2' color={palette.primary[800]}>
                                        {filename}
                                    </Typography>
                                    <Typography variant='caption' color={palette.primary[500]}>
                                        Estilo de redacción
                                    </Typography>
                                </Stack>
                                <IconButtonWithTooltip
                                    icon={<CloseRoundedIcon />}
                                    tooltipText={'Eliminar ejemplo'}
                                    onClick={() => setFile(null)}
                                />
                            </Stack>
                        </Grid>
                    )}
                    {!file && (
                        <DialogButton
                            withButton={true}
                            buttonText='Añadir estilo de redacción'
                            buttonIcon={<StyleOutlinedIcon fontSize='small' />}
                            buttonVariant='text'
                            dialogTitle='Añadir estilo de redacción'
                            dialogText='El estilo de redacción te permite personalizar el resultado de tus informes.'
                            onClose={handleCloseDialog}
                            onSuccess={hanldeUpload}
                            successButtonText='Guardar'
                            successButtonLoadingText='Guardando'
                            loading={loading}
                        >
                            {file ? (
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    p={1}
                                    border='1px solid'
                                    borderColor={palette.primary[300]}
                                    borderRadius={2}
                                    backgroundColor={palette.primary[50]}
                                    sx={{ alignItems: 'center' }}
                                >
                                    <DescriptionRoundedIcon fontSize='large' />
                                    <Stack direction={'column'} spacing={0.5} flexGrow={1}>
                                        <Typography variant='body1' color={palette.primary[800]}>
                                            {file.name}
                                        </Typography>
                                        <Typography variant='body2' color={palette.primary[500]}>
                                            PDF {(file.size / (1024 * 1024)).toFixed(2)} MB
                                        </Typography>
                                    </Stack>
                                    <IconButtonWithTooltip
                                        icon={<CloseRoundedIcon />}
                                        tooltipText={'Eliminar ejemplo'}
                                        onClick={() => setFile(null)}
                                    />
                                </Stack>
                            ) : (
                                <DragDropFile
                                    handleFile={handleOnFileSelection}
                                    filename={filename}
                                    alertFile={alertMessage}
                                    alertMessage={alertMessage}
                                    infoMessage='PDF (max. 200MB)'
                                    icon={<StyleOutlinedIcon />}
                                />
                            )}
                        </DialogButton>
                    )}
                </Grid>
            </Grid>
            <PricingDialog openDialog={openPremiumDialog} setOpenDialog={setOpenPremiumDialog} />
        </>
    );
}
