import { useEffect, useState } from 'react';
import rolesByCategoryRaw from '@common/helpers/rolesByCategory.json';
import { getAllRoles } from '@setup/api/participants/participants';

export function useRoles() {
    const [rolesByCategory, setRolesByCategory] = useState(() => rolesByCategoryRaw);
    const [roles, setRoles] = useState([]);

    const handleCompleteRolesData = (rawRoles) => {
        let updatedFullRolesList = [];

        const completeRoles = rolesByCategoryRaw.map((category) => {
            return {
                ...category,
                roles: category.roles.map((role) => {
                    const rawRole = rawRoles.find((rawRole) => rawRole.role === role.role);
                    const completeRole = { ...role, ...rawRole };
                    updatedFullRolesList = [...updatedFullRolesList, completeRole];
                    return completeRole;
                })
            };
        });

        setRoles(updatedFullRolesList);
        return completeRoles;
    };

    const handleGetRoleById = (roleId) => {
        for (const category of rolesByCategory) {
            const role = category.roles.find((role) => role.id === roleId);
            if (role) {
                return role;
            }
        }
        return null;
    };

    useEffect(() => {
        (async () => {
            const rawRoles = await getAllRoles();
            const completeRoles = handleCompleteRolesData(rawRoles);
            setRolesByCategory(completeRoles);
        })();
    }, []);

    return { roles, handleGetRoleById, rolesByCategory };
}
