import React, { useEffect, useRef, useState } from 'react';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Chip, Divider, Grid, IconButton, InputBase, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import IconWithCircularLoader from '@common/components/loaders/IconWithCircularLoading';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import zIndex from '@common/theme/zIndex';
import { chat, completion } from '@setup/api/nlp';
import { copyReportBlocksContent, createReportBlock } from '@setup/api/reportBlocks/reportBlocks';
import ChatMessage from './ChatMessage';

Chat.propTypes = {
    meetingId: PropTypes.number.isRequired,
    meetingDuration: PropTypes.number.isRequired,
    messages: PropTypes.arrayOf(PropTypes.any).isRequired,
    setMessages: PropTypes.func.isRequired,
    meetingTitle: PropTypes.string.isRequired,
    loadingCache: PropTypes.bool.isRequired,
    setBlocks: PropTypes.func.isRequired,
    blocks: PropTypes.arrayOf(PropTypes.object),
    participants: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default function Chat({
    meetingId,
    messages,
    setMessages,
    loadingCache,
    setBlocks,
    blocks,
    participants
}) {
    const notification = useNotification();
    const { getPlan } = useUser();
    const userUseCase = useSelector((state) => state.user.userUseCase);
    const userGivenName = useSelector((state) => state.user.userGivenName);

    const lastMessageRef = useRef(null);

    const [inputValue, setInputValue] = useState('');
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [quickReplying, setQuickReplying] = useState('');
    const [showMessages, setShowMessages] = useState(false);
    const [openInput, setOpenInput] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loadingAddToReport, setloadingAddToReport] = useState(false);
    const [suggestedName, setSuggestedName] = useState('');
    const [mentionActive, setMentionActive] = useState(false);

    useEffect(() => {
        if (lastMessageRef.current && messages.length > 1) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSubmitMessage = async (userMessage) => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
            return;
        }
        setSubmitting(true);

        while (loadingCache) {
            // To reduce user waiting time
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        if (inputValue.trim()) {
            setMessages([
                ...messages,
                { role: 'user', content: inputValue },
                {
                    role: 'assistant',
                    content: '',
                    isLoading: true
                }
            ]);
            setInputValue('');
            if (quickReplying) {
                await handleNewCompletionMessage(userMessage);
            } else {
                await handleNewChatMessage(userMessage);
            }
            setSubmitting(false);
        }
    };

    const handleKeyDown = async (event) => {
        if (mentionActive && event.key === 'Tab') {
            event.preventDefault();
            completeMention();
            const mentionPattern = /@([\w]*)$/;
            const updatedInputValue = inputValue.replace(mentionPattern, suggestedName);
            setInputValue(updatedInputValue);
        } else if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            await handleSubmitMessage(inputValue);
        }
    };

    const handleNewChatMessage = async (userMessage) => {
        const chatResponse = await chat({
            newMessage: userMessage,
            thread: messages,
            meetingId
        });
        setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1), // Remove the last message
            { role: 'assistant', content: chatResponse } // Add the assistant's response
        ]);
    };

    const handleNewCompletionMessage = async (userMessage) => {
        const reportContent = await copyReportBlocksContent(meetingId);
        const completionResponse = await completion({
            prompt:
                `Aquí tienes el informe de Toma de Requerimientos generado en la reunión con el cliente para definir una oferta de empleo: ${reportContent}.` +
                (quickReplying === 'jobDescription'
                    ? 'Utiliza el informe para crear una descripción de para la oferta de empleo.'
                    : 'Utiliza el informe para crear un post para LinkedIn para publicar el lanzamiento de una nueva oferta de empleo a tu red de contactos.') +
                'Adapta el contenido según las siguientes indicaciones: ' +
                userMessage +
                'No uses markdown.',
            system: 'Eres un consultor de selección.',
            config: {
                model: 'gpt-4o',
                temperature: 0.7
            },
            user: userGivenName
        });

        setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1), // Remove the last message
            { role: 'assistant', content: completionResponse.content } // Add the assistant's response
        ]);
        setQuickReplying('');
    };

    const handleChange = (event) => {
        const text = event.target.value;
        setInputValue(text);
        detectMention(text);
    };

    const handleQuickReply = async (type) => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
            return;
        }
        setQuickReplying(type);
        setMessages([
            ...messages,
            {
                // Just to show next message
                role: 'system',
                content: '',
                hide: true
            },
            {
                role: 'assistant',
                content:
                    'Dame un ejemplo o varios para adaptarme. Usaré la información del informe de Voicit, indícame cualquier detalle adicional que quieras que tenga en cuenta.'
            }
        ]);
        setInputValue('');
    };
    const handleAddResponseToReport = async (responseMessageIndex) => {
        try {
            setloadingAddToReport(true);
            const response = messages[responseMessageIndex].content;
            const userQuestion = messages[responseMessageIndex - 1].content;
            console.log('Info to add: ', response, userQuestion);

            const updatedBlocks = [...blocks];

            const { reportBlock: dividerBlock } = await createReportBlock({
                meetingId,
                htmlContent: '',
                htmlTag: 'p',
                position: updatedBlocks.length - 1,
                style: ''
            });
            updatedBlocks.push(dividerBlock);

            const { reportBlock: headerBlock } = await createReportBlock({
                meetingId,
                htmlContent: `<b>${userQuestion}</b>`,
                htmlTag: 'p',
                position: updatedBlocks.length - 1,
                style: ''
            });
            updatedBlocks.push(headerBlock);

            const { reportBlock: responseBlock } = await createReportBlock({
                meetingId,
                htmlContent: response,
                htmlTag: 'p',
                position: updatedBlocks.length - 1,
                style: ''
            });
            updatedBlocks.push(responseBlock);

            setBlocks(updatedBlocks);
            setloadingAddToReport(false);
        } catch (e) {
            notification('dragging-blocks-error');
        }
    };

    const detectMention = (text) => {
        const match = text.match(/@([\w]*)$/);
        if (match) {
            const query = match[1].toLowerCase();
            const foundParticipant = participants.find((participant) =>
                participant.name.toLowerCase().startsWith(query)
            );
            console.log(foundParticipant);
            setMentionActive(!!foundParticipant?.name);
            setSuggestedName(foundParticipant?.name || '');
        } else {
            setMentionActive(false);
            setSuggestedName('');
        }
    };

    const completeMention = () => {
        if (!suggestedName) return;
        const newText = inputValue.replace(/@([\w]*)$/, `@${suggestedName} `);
        setInputValue(newText);
        setMentionActive(false);
        setSuggestedName('');
    };

    return (
        <>
            <Grid
                id='chat'
                container
                direction='column'
                sx={{
                    height: 'auto',
                    position: 'relative',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    mb: 2
                }}
            >
                {showMessages && messages.length > 1 && (
                    <>
                        <Grid item sx={{ mb: 2 }}>
                            <Divider>
                                <Chip label='Chat' size='small' />
                            </Divider>
                        </Grid>
                        <Grid item mb={2}>
                            <Typography
                                variant='body2'
                                textAlign='center'
                                color={palette.primary[300]}
                            >
                                Al salir desaparecerán los mensajes.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                flexGrow: 1,
                                mb: 5
                            }}
                        >
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    ref={index === messages.length - 1 ? lastMessageRef : null}
                                    style={
                                        index === messages.length - 1
                                            ? { marginBottom: '20px' }
                                            : {}
                                    }
                                >
                                    {message.hide ? null : (
                                        <ChatMessage
                                            role={message.role}
                                            content={message.content}
                                            isLoading={
                                                message.isLoading ? message.isLoading : false
                                            }
                                            position={index}
                                            messagesCount={messages.length}
                                            handleAddResponseToReport={handleAddResponseToReport}
                                            loadingAddToReport={loadingAddToReport}
                                        />
                                    )}
                                </div>
                            ))}
                        </Grid>
                    </>
                )}
                {openInput && (
                    <Grid
                        container
                        direction='column'
                        sx={{
                            position: 'fixed',
                            mt: 2,
                            p: '10%',
                            pt: 3,
                            pb: 1,
                            bottom: 0,
                            width: '100%',
                            right: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.4)', // Slightly transparent background
                            backdropFilter: 'blur(10px)', // Blur effect
                            borderTop: `1px solid ${palette.primary[100]}`, // Optional: Add a top border to separate it visually
                            zIndex: zIndex.appbar
                        }}
                    >
                        <Grid item flexGrow={1} display={'flex'} justifyContent={'center'}>
                            <Stack
                                direction='row'
                                spacing={1}
                                alignItems='center'
                                sx={{ width: '100%' }}
                            >
                                <Stack
                                    direction='row'
                                    spacing={1}
                                    alignItems='center'
                                    sx={{
                                        backgroundColor: palette.primary[50],
                                        padding: 1,
                                        borderRadius: 2,
                                        boxSizing: 'border-box',
                                        border: `1px solid ${palette.primary[200]}`,
                                        width: '100%'
                                    }}
                                >
                                    <Box sx={{ position: 'relative', width: '100%' }}>
                                        <InputBase
                                            sx={{
                                                pl: 1,
                                                flex: 1,
                                                color: palette.primary[800]
                                            }}
                                            placeholder={
                                                quickReplying
                                                    ? 'Añade un ejemplo y los detalles que necesites'
                                                    : '¿Qué quieres saber?  @ + tab para mencionar participantes'
                                            }
                                            onKeyDown={handleKeyDown}
                                            onChange={handleChange}
                                            onFocus={() => setShowMessages(true)}
                                            value={inputValue}
                                            autoFocus={true}
                                            fullWidth={true}
                                            multiline={true}
                                            inputProps={{
                                                style: {
                                                    whiteSpace: 'pre-wrap'
                                                    // position: 'relative'
                                                }
                                            }}
                                        />
                                        {mentionActive && suggestedName && (
                                            <Typography
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    pl: 1,
                                                    pb: 0.2,
                                                    transform: 'translateY(-50%)',
                                                    color: palette.primary[600],
                                                    opacity: 0.7,
                                                    pointerEvents: 'none',
                                                    zIndex: 1
                                                }}
                                            >
                                                {inputValue}
                                                <span>
                                                    {suggestedName.replace(
                                                        inputValue.match(/@([\w]*)$/)?.[1] || '',
                                                        ''
                                                    )}
                                                </span>
                                            </Typography>
                                        )}
                                    </Box>
                                    <IconButton
                                        type='button'
                                        sx={{ p: 1, borderRadius: 2 }}
                                        aria-label='search'
                                        onClick={() => handleSubmitMessage(inputValue)}
                                        disabled={!inputValue.trim()}
                                    >
                                        <IconWithCircularLoader
                                            loading={submitting}
                                            icon={<ArrowUpwardRoundedIcon fontSize='small' />}
                                            size={'small'}
                                        />
                                    </IconButton>
                                </Stack>
                                <IconButton
                                    type='button'
                                    sx={{ p: 1, borderRadius: 2 }}
                                    aria-label='search'
                                    onClick={() => setOpenInput(false)}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            </Stack>
                        </Grid>

                        {userUseCase === 'hiring' && (
                            <Grid item display='flex' justifyContent='center' mt={1}>
                                <Stack direction='row' spacing={1}>
                                    <Chip
                                        variant='contained'
                                        icon={<DescriptionOutlinedIcon fontSize='small' />}
                                        label='Crear descripción de trabajo'
                                        onClick={() => handleQuickReply('jobDescription')}
                                        size='small'
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: palette.primary[50]
                                            }
                                        }}
                                    />
                                    <Chip
                                        variant='contained'
                                        icon={<ArticleOutlinedIcon fontSize='small' />}
                                        label='Crear post LinkedIn'
                                        onClick={() => handleQuickReply('linkedinPost')}
                                        size='small'
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: palette.primary[50]
                                            }
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        )}

                        <Grid item display='flex' justifyContent='center' mt={1}>
                            <Typography
                                variant='caption'
                                textAlign='center'
                                color={palette.primary[300]}
                            >
                                Pueden existir errores. Utiliza las referencias de tiempo y el audio
                                para verificar.
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {!openInput && (
                    <Grid
                        container
                        direction='column'
                        sx={{
                            position: 'fixed',
                            p: '10%',
                            pt: 2,
                            pb: 2,
                            bottom: 0,
                            right: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.4)', // Slightly transparent background
                            backdropFilter: 'blur(10px)', // Blur effect
                            borderTop: `1px solid ${palette.primary[100]}`, // Optional: Add a top border to separate it visually
                            zIndex: zIndex.appbar,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid item>
                            <Chip
                                variant='contained'
                                icon={<SearchRoundedIcon fontSize='small' />}
                                label='Pregunta sobre tu conversación'
                                onClick={() => setOpenInput(true)}
                                size='medium'
                                sx={{
                                    border: `1px solid ${palette.primary[200]}`,
                                    '&:hover': {
                                        backgroundColor: palette.primary[50],
                                        borderColor: palette.primary[300]
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                defaultPlanId={1}
                title='Actualiza a Unlimited para preguntar a la IA'
            />
        </>
    );
}
