import React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';

IconPhrase.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.node,
    tooltipText: PropTypes.string,
    color: PropTypes.string,
    fitContainer: PropTypes.bool
};

export default function IconPhrase(props) {
    const text = props.text;
    return (
        <>
            {props.tooltipText ? (
                <Tooltip title={props.tooltipText}>
                    <Grid container sx={{ direction: 'row', alignItems: 'center' }}>
                        <Grid item sx={{ mr: 1 }}>
                            {props.icon}
                        </Grid>
                        <Grid item>
                            <Typography
                                component='span'
                                variant='body2'
                                color={props.color ? props.color : palette.primary}
                            >
                                {text}
                            </Typography>
                        </Grid>
                    </Grid>
                </Tooltip>
            ) : (
                <Grid
                    container
                    sx={{
                        direction: 'row',
                        alignItems: 'center',
                        flexWrap: 'nowrap',
                        maxWidth: props.fitContainer ? 'fit-content' : 'auto' // Added to remove the extra space to fill the container
                    }}
                >
                    <Grid item sx={{ mr: 1 }}>
                        {props.icon}
                    </Grid>
                    <Grid item>
                        <Typography
                            component='span'
                            variant='body2'
                            color={props.color ? props.color : palette.primary}
                            noWrap={true}
                        >
                            {text}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
