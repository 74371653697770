const utcToLocaleDate = ({ utcDate, timeZone, personalizedOptions }) => {
    const currentDate = new Date();
    const validDateObject = new Date(utcDate);
    const currentYear = currentDate.getFullYear();
    const dateYear = validDateObject.getFullYear();

    const options = personalizedOptions
        ? personalizedOptions
        : {
              weekday: 'long',
              month: 'long',
              day: '2-digit'
          };

    // Add the year to the options if the date is from a previous year
    if (dateYear < currentYear) {
        options.year = 'numeric';
    }

    const localeDate = validDateObject.toLocaleDateString(timeZone, options);

    return localeDate;
};

const utcToLocaleTime = ({ utcDate, timeZone, personalizedOptions }) => {
    const options = personalizedOptions
        ? personalizedOptions
        : {
              weekday: 'long',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
          };

    const validDateObject = new Date(utcDate);
    const localeTime = validDateObject.toLocaleDateString(timeZone, options);
    const justTime = localeTime.split(',');
    return justTime[1];
};

const getElapsedTime = ({ fromDate, toDate }) => {
    const millisecondsPerHour = 60 * 60 * 1000;
    const millisecondsPerDay = 24 * millisecondsPerHour;
    const millisecondsPerWeek = 7 * millisecondsPerDay;
    const millisecondsPerMonth = 30 * millisecondsPerDay; // Approximate

    const elapsedMilliseconds = toDate - fromDate;

    if (elapsedMilliseconds < 60000) {
        return 'menos de un minuto';
    } else if (elapsedMilliseconds < millisecondsPerHour) {
        const minutes = Math.floor(elapsedMilliseconds / (60 * 1000));
        return `${minutes} ${minutes > 1 ? 'minutos' : 'minuto'}`;
    } else if (elapsedMilliseconds < millisecondsPerDay) {
        const hours = Math.floor(elapsedMilliseconds / millisecondsPerHour);
        return `${hours} ${hours > 1 ? 'horas' : 'hora'}`;
    } else if (elapsedMilliseconds < millisecondsPerWeek) {
        const days = Math.floor(elapsedMilliseconds / millisecondsPerDay);
        return `${days} ${days > 1 ? 'días' : 'día'}`;
    } else if (elapsedMilliseconds < millisecondsPerMonth) {
        const weeks = Math.floor(elapsedMilliseconds / millisecondsPerWeek);
        return `${weeks} ${weeks > 1 ? 'semanas' : 'semana'}`;
    } else {
        const months = Math.floor(elapsedMilliseconds / millisecondsPerMonth);
        return `${months} ${months > 1 ? 'meses' : 'mes'}`;
    }
};

/**
 * Converts seconds into minutes
 */
const fromSecondsToMinutes = (timeInSeconds) => {
    return Math.floor(timeInSeconds / 60);
};

/**
 * Converts seconds into an array of hours, minutes and seconds
 */
const fromSecondsToArrayTime = (timeInSeconds) => {
    let seconds = 0;

    const totalMinutes = timeInSeconds >= 60 ? Math.round(timeInSeconds / 60) : 0;
    let minutes =
        totalMinutes >= 60 ? totalMinutes - 60 * Math.trunc(totalMinutes / 60) : totalMinutes;

    if (minutes >= 1) {
        if (minutes * 60 > timeInSeconds) {
            // Ensuring seconds is positive
            seconds = timeInSeconds - (minutes - 1) * 60;
            --minutes;
        } else {
            seconds = Math.round(timeInSeconds - minutes * 60);
        }
    } else {
        seconds = Math.round(timeInSeconds);
    }

    const hour = totalMinutes >= 60 ? Math.trunc(totalMinutes / 60) : 0;
    const time = [Math.trunc(hour), Math.trunc(minutes), Math.trunc(seconds)];

    return time;
};

const fromArrayTimeToString = (arrTime) => {
    const hours = arrTime[0] ? `${arrTime[0]}h y ` : '';
    const minutes = arrTime[1] ? `${arrTime[1]}` : 'menos de un';
    const minutesPart = arrTime[1] > 1 ? minutes + ' minutos' : minutes + ' minuto';

    return hours + minutesPart;
};

/**
 * Turn timeky (ticks) to seconds
 */
const timeKeyToSeconds = (timeKey) => {
    const nanosecToSeconds = Math.pow(10, -9); // 1 ns = 10e-9s
    const seconds = timeKey * 100 * nanosecToSeconds; // 100 ns = 1 timeKey unit

    return seconds;
};

/**
 * Turn timeky (ticks) to an array of time in seconds
 */
const timeKeyToTime = (timeKey) => {
    const timeKeySec = timeKeyToSeconds(timeKey);
    const time = fromSecondsToArrayTime(timeKeySec);

    return time;
};

/**
 * Turn timeky (ticks) to seconds
 */
const secondsToTimeKey = (seconds) => {
    const nanosecToSeconds = Math.pow(10, -9); // 1 ns = 10e-9s
    const timeKey = seconds / (100 * nanosecToSeconds); // 100 ns = 1 timeKey unit

    return timeKey;
};

const getStartMeetingTimeArray = ({ utcDate, timeZone, duration }) => {
    const createdAtMeetingTimeStringArr = utcToLocaleTime({ utcDate, timeZone }).split(':'); // extracted from createdAt time
    const startMeetingTime = [];
    const durationArr = fromSecondsToArrayTime(duration);

    // getting starting time in array [hours, minutes, seconds]
    createdAtMeetingTimeStringArr.forEach((elementTime, index) => {
        const newElementTime = Number(elementTime) - durationArr[index];
        if (newElementTime < 0) {
            --startMeetingTime[index - 1];
            startMeetingTime[index] = 60 + newElementTime;
        } else {
            startMeetingTime[index] = newElementTime;
        }
    });

    return startMeetingTime;
};

/**
 * Returns time base on timekey. If DirectUploadIsActive the time start in 0, if not in the meeting itme scheduled
 */
const getTimeMeetingIntervention = ({
    utcDate,
    timeZone,
    timeKey,
    duration,
    secondsDisabled,
    hoursDisabled
}) => {
    const audioInterventionTimeArr = timeKeyToTime(timeKey); // second in which the intervention is done
    const startMeetingTime = !utcDate
        ? [0, 0, 0]
        : getStartMeetingTimeArray({ utcDate, timeZone, duration });
    const interventionTimeArr = [0, 0, 0];
    const arr = [0, 0, 0];

    // placing intervention from starting time
    audioInterventionTimeArr.forEach((interventionTime, index) => {
        const newElementTime = startMeetingTime[index] + interventionTime;
        if (index > 0 && newElementTime >= 60) {
            const multiplier = Math.trunc(newElementTime / 60);
            arr[index] = Math.trunc(newElementTime / 60);
            ++interventionTimeArr[index - 1];
            interventionTimeArr[index] = newElementTime - 60 * multiplier;
        } else {
            interventionTimeArr[index] = newElementTime;
        }
    });

    if (hoursDisabled) {
        return `${interventionTimeArr[1].toString().padStart(2, '0')}:${interventionTimeArr[2]
            .toString()
            .padStart(2, '0')}`;
    }
    if (secondsDisabled)
        return `${interventionTimeArr[0].toString()}:${interventionTimeArr[1]
            .toString()
            .padStart(2, '0')}`;

    // Full time
    return `${interventionTimeArr[0].toString()}:${interventionTimeArr[1]
        .toString()
        .padStart(2, '0')}:${interventionTimeArr[2].toString().padStart(2, '0')}`;
};

const formatDuration = (s) => {
    const minute = Math.floor(s / 60);
    const secondLeft = Math.round(s - minute * 60);
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
};

const secondsToTimeString = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formatTwoDigits = (value) => {
        return value < 10 ? `0${value}` : value.toString();
    };

    return `${formatTwoDigits(hours)}:${formatTwoDigits(minutes)}:${formatTwoDigits(seconds)}`;
};

module.exports = {
    getElapsedTime,
    utcToLocaleDate,
    utcToLocaleTime,
    timeKeyToTime,
    getTimeMeetingIntervention,
    fromSecondsToArrayTime,
    fromArrayTimeToString,
    getStartMeetingTimeArray,
    formatDuration,
    timeKeyToSeconds,
    secondsToTimeKey,
    fromSecondsToMinutes,
    secondsToTimeString
};
