import React, { useEffect, useRef, useState } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import MicNoneRoundedIcon from '@mui/icons-material/MicNoneRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { Box, Button, Chip, Grid, Link, Stack, Typography } from '@mui/material';
import Bowser from 'bowser';
import { useNavigate } from 'react-router-dom';
import InformationAlert from '@common/components/alerts/InformationAlert';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import RecordingStatusBadge from '@common/components/icons/RecordingStatusBadge';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import { urls } from '@common/helpers/urls';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import { downloadFileFromBlob } from '@common/utils/downloadFileFromBlob';
import { secondsToTimeString } from '@common/utils/time';
import CreateMeetingDialog from '@pages/meetingsRepository/components/createMeetingDialog/CreateMeetingDialog';
import { uploadAudioToGCS } from '@setup/api/gcs';
import { createMeeting, retryMeeting } from '@setup/api/meeting/meeting';

const RecorderPage = () => {
    const navigate = useNavigate();
    const notification = useNotification();

    const [recording, setRecording] = useState(false);
    const [duration, setDuration] = useState(0);
    const [file, setFile] = useState();
    const [timerInterval, setTimerInterval] = useState();
    const [mediaRecorder, setMediaRecorder] = useState();
    const [openMeetingDialog, setOpenMeetingDialog] = useState(false);
    const [paused, setPaused] = useState(false);
    const [recorderError, setRecorderError] = useState(false);
    const recordedChunksRef = useRef([]); // Usamos useRef para manejar los chunks
    const [isMobile, setIsMobile] = useState(false);
    const [openCancelRecordingDialog, setOpenCancelRecordingDialog] = useState(false);
    const [recordedMeeting, setRecordedMeeting] = useState();

    let stream = null;

    const handleNavigateToRepository = () => {
        navigate(navigation.app.routes.repository);
        window.location.reload();
    };

    const handleResetRecorder = () => {
        recordedChunksRef.current = [];
        setDuration(0);
        stream = null;
    };

    const handleRecord = async () => {
        if (recording) {
            mediaRecorder.stop();
            clearInterval(timerInterval);

            const { file: currentFile } = await getFileAndDetails();
            setFile(currentFile);
            handleResetRecorder();

            try {
                const filename = await uploadAudioToGCS({ file: currentFile });
                const { meeting: createdMeeting } = await createMeeting({
                    filename,
                    title: currentFile.name,
                    duration,
                    platform: 'Voicit',
                    standBy: true
                });
                setRecordedMeeting(createdMeeting);

                setOpenMeetingDialog(true);
            } catch (error) {
                console.log(error);
                downloadFileFromBlob({
                    blob: currentFile,
                    filename: currentFile.name,
                    extension: 'mp3'
                });
            }
        } else {
            startRecording();
        }
        setRecording(!recording);
    };

    const handlePausePlay = () => {
        if (mediaRecorder.state === 'recording') {
            mediaRecorder.pause();
            setPaused(true);
            clearInterval(timerInterval);
        } else if (mediaRecorder.state === 'paused') {
            mediaRecorder.resume();
            setPaused(false);
            setTimerInterval(
                setInterval(() => {
                    setDuration((prevDuration) => prevDuration + 1);
                }, 1000)
            );
        }
    };

    const handleCancel = async () => {
        setRecording(!recording);
        await mediaRecorder.stop();
        clearInterval(timerInterval);
        recordedChunksRef.current = []; // Limpiamos los chunks
        setDuration(0);
        stream = null;
        setOpenCancelRecordingDialog(false);
    };

    const handleResume = async () => {
        await startRecording();
        setRecorderError(false);
    };

    const startRecording = async () => {
        try {
            stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    channelCount: 1
                }
            });

            const parser = Bowser.getParser(navigator.userAgent);
            const newMediaRecorder = new MediaRecorder(stream, {
                mimeType:
                    parser.getPlatformType() === 'mobile' && parser.getOS().name !== 'Android'
                        ? 'audio/mp4'
                        : 'audio/webm;codecs=opus' // 'audio/webm;codecs=opus' not supported on safari IOS
            });
            newMediaRecorder.ondataavailable = (e) => {
                const { data } = e;
                if (data) {
                    recordedChunksRef.current.push(data);
                }
            };
            newMediaRecorder.onstart = () => {
                if (!recorderError) {
                    recordedChunksRef.current = [];
                }
                setFile();

                setTimerInterval(
                    setInterval(() => {
                        setDuration((prevDuration) => prevDuration + 1);
                    }, 1000)
                );
            };
            newMediaRecorder.onstop = () => {
                if (stream) {
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }
            };
            newMediaRecorder.onerror = (event) => {
                console.error('Media recorder error: ', event.error);

                setRecorderError(true);
                clearInterval(timerInterval);

                if (mediaRecorder && mediaRecorder.state !== 'inactive') {
                    mediaRecorder.stop();
                    console.log('Stopping');
                }
                notification('audio-recording-error');
            };
            newMediaRecorder.start(1e3); // 1e3 is the interval to check for available data
            setMediaRecorder(newMediaRecorder);
        } catch (error) {
            console.error('Error accessing media devices: ', error);
            notification('audio-recording-error');
            setRecording(false);
        }
    };

    const getFileAndDetails = async () => {
        try {
            return new Promise((resolve) => {
                const today = new Date();
                const time = today.toLocaleDateString();
                const hour = String(today.getHours()).padStart(2, '0');
                const minute = String(today.getMinutes()).padStart(2, '0');

                const audioBlob = getEncodedFile('audio/mp3');
                const fileName = `Grabación en Voicit - ${time} ${hour}:${minute}`;
                const audioFile = new File([audioBlob], fileName, {
                    type: audioBlob.type
                });
                const url = URL.createObjectURL(audioFile);
                const player = new Audio(url);
                player.addEventListener(
                    'durationchange',
                    function () {
                        if (duration != Infinity) {
                            player.remove();
                            resolve({
                                file: audioFile,
                                duration
                            });
                        }
                    },
                    false
                );
                player.load();
                player.currentTime = 24 * 60 * 60;
                player.volume = 0;
            });
        } catch (error) {
            console.error('Error during getting files', error);
            notification('audio-recording-error');
            return Promise.reject(error);
        }
    };

    const getEncodedFile = (encoder) => {
        try {
            return new Blob(recordedChunksRef.current, {
                type: encoder
            });
        } catch (error) {
            console.error('Error during creating Blob:', error);
            notification('audio-recording-error');
            throw error;
        }
    };

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileDevice = /android|iPhone|iPad|iPod|windows phone/i.test(userAgent);
        setIsMobile(isMobileDevice);
    }, []);

    return (
        <Page title='Estudio de grabación'>
            <Box>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '100vh',
                        height: '100%',
                        boxSizing: 'border-box'
                    }}
                    spacing={2}
                >
                    {/* <Grid item sx={{ mb: 5 }}>
                        <img src={logotype} alt='voicit-logotype' />
                    </Grid> */}
                    {recorderError && (
                        <Grid item>
                            <Typography component={'span'} variant='h4'>
                                {'Error. Grabación detenida. Pulsa para continuarla.'}
                            </Typography>
                        </Grid>
                    )}
                    {isMobile && (
                        <Grid item>
                            <InformationAlert
                                message='<b>Usa un ordenador o sube un .MP3 grabado con la app del móvil para evitar errores.</b>'
                                type='warning'
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Stack direction='row' spacing={1}>
                            {recording && !paused && !recorderError && (
                                <IconButtonWithTooltip
                                    sx={{
                                        borderRadius: '24px'
                                    }}
                                    size='large'
                                    onClick={() => setOpenCancelRecordingDialog(true)}
                                    tooltipText={'Cancelar grabación'}
                                    icon={<ClearRoundedIcon />}
                                />
                            )}
                            {!recording && (
                                <Chip
                                    variant='contained'
                                    icon={
                                        <MicNoneRoundedIcon
                                            sx={{ color: 'white !important' }}
                                            fontSize='medium'
                                        />
                                    }
                                    label='Grabar'
                                    onClick={handleRecord}
                                    size='medium'
                                    sx={{
                                        backgroundColor: palette.primary.main,
                                        color: palette.common.white,
                                        '&:hover': {
                                            backgroundColor: palette.primary[700]
                                        }
                                    }}
                                />
                            )}
                            {recording && !paused && (
                                <IconButtonWithTooltip
                                    sx={{
                                        borderRadius: '24px',
                                        border: '1px solid',
                                        color: palette.primary[700]
                                    }}
                                    size='large'
                                    onClick={
                                        paused
                                            ? handlePausePlay
                                            : recorderError
                                            ? handleResume
                                            : handleRecord
                                    }
                                    tooltipText={paused || recorderError ? 'Continuar' : 'Detener'}
                                    icon={
                                        paused || recorderError ? (
                                            <PlayArrowRoundedIcon />
                                        ) : (
                                            <StopRoundedIcon />
                                        )
                                    }
                                />
                            )}
                            {recording && paused && (
                                <Chip
                                    variant='contained'
                                    icon={
                                        <PlayArrowRoundedIcon
                                            sx={{ color: 'white !important' }}
                                            fontSize='medium'
                                        />
                                    }
                                    label='Continuar grabando'
                                    onClick={handlePausePlay}
                                    size='medium'
                                    sx={{
                                        backgroundColor: palette.primary.main,
                                        color: palette.common.white,
                                        '&:hover': {
                                            backgroundColor: palette.primary[700]
                                        }
                                    }}
                                />
                            )}
                            {recording && !paused && !recorderError && (
                                <IconButtonWithTooltip
                                    sx={{
                                        borderRadius: '24px'
                                    }}
                                    size='large'
                                    onClick={handlePausePlay}
                                    tooltipText={'Pausar'}
                                    icon={<PauseRoundedIcon />}
                                />
                            )}
                        </Stack>
                    </Grid>
                    {recording && (
                        <Grid item>
                            <Stack
                                direction='row'
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                            >
                                <RecordingStatusBadge disable={paused} />
                                <Typography
                                    component={'span'}
                                    variant='body1'
                                    sx={{
                                        minWidth: '70px', // 👈 Adjust based on the widest time string
                                        textAlign: 'left', // 👈 Ensures text remains centered inside the fixed width
                                        display: 'inline-block' // 👈 Prevents extra flex behavior
                                    }}
                                >
                                    {secondsToTimeString(duration)}
                                </Typography>
                            </Stack>
                        </Grid>
                    )}
                    {!recording && (
                        <>
                            <Grid
                                item
                                sx={{ mt: 2 }}
                                direction='column'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <Link
                                    component='button'
                                    variant='body2'
                                    underline='always'
                                    color={palette.primary[900]}
                                    onClick={() => window.open(urls.tutorials.onSite, '_blank')}
                                    textAlign='center'
                                >
                                    Ver tutorial para reuniones presenciales
                                </Link>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant='body2'
                                    textAlign='center'
                                    color={palette.primary[500]}
                                >
                                    Para reuniones online, accede a la videollamada y utiliza la
                                    extensión de navegador desde ahí.
                                </Typography>
                            </Grid>
                            <Grid item sx={{ mt: 2 }}>
                                <Button
                                    variant='text'
                                    size='small'
                                    disableElevation={true}
                                    onClick={handleNavigateToRepository}
                                    startIcon={<ArrowBackRoundedIcon />}
                                >
                                    Volver a mis reuniones
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
                <ConfirmationDialog
                    open={openCancelRecordingDialog}
                    title='Cancelar Grabación'
                    message='¿Estás seguro de que quieres cancelar esta grabación? Perderás el audio.'
                    onConfirm={handleCancel}
                    onClose={() => setOpenCancelRecordingDialog(false)}
                />
                <CreateMeetingDialog
                    openFromButton={openMeetingDialog}
                    setOpenButtonDialog={setOpenMeetingDialog}
                    isRecordedFromVoicit={true}
                    handleDeleteMeeting={() => {}}
                    findAndUpdateMeeting={() => {}}
                    introduceNewMeeting={() => {}}
                    recordedFile={file} // TODO: Do I need to pass it?
                    currentMeeting={recordedMeeting}
                    handleRetryMeeting={retryMeeting}
                />
            </Box>
        </Page>
    );
};

export default RecorderPage;
