import axios from 'axios';
import { config } from '../config';

export const endpoints = {
    auth: 'user-api/auth',
    user: 'user-api',
    gcs: 'token-api/gcs',
    workspace: 'workspace-api',
    workspaceAdmin: 'workspace-api/admin',
    meetings: 'meeting-api/meeting',
    sharedMeetings: 'meeting-api/meeting/shared',
    summaries: 'meeting-api/meeting',
    customTags: 'user-api/config/custom-tags',
    microSummaries: 'microsummary-api',
    sharedMicroSummaries: 'microsummary-api/shared',
    reportBlocks: 'report-block-api',
    sharedReportBlocks: 'report-block-api/shared',
    template: 'report-block-api/template',
    templateSections: 'report-block-api/template/section',
    section: 'report-block-api/section',
    meetingInterventions: 'meeting-api/meeting-intervention',
    sharedMeetingInterventions: 'meeting-api/meeting-intervention/shared',
    transcriptions: 'meeting-api/transcription',
    participants: 'meeting-api/participant',
    nlp: 'nlp-api/nlp',
    notes: 'meeting-api/notes',
    meetingProcessing: 'meeting-processing-api'
};

export const authApiCall = async ({
    method,
    endpoint,
    authToken,
    contentType,
    path,
    data,
    options,
    params
}) => {
    return await axios(`${config.gatewayUrl}/${endpoint}${path}`, {
        method,
        data,
        headers: {
            'x-voicit-auth': authToken,
            'Content-type': contentType ? contentType : 'application/json'
        },
        params,
        ...options,
        withCredentials: false
    });
};
