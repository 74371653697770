import React from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';
import { utcToLocaleDate } from '@common/utils/time';

export default function CreationDateField({ currentCreationDate }) {
    const date = utcToLocaleDate({ utcDate: currentCreationDate, timeZone: 'es-ES' });

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}
            >
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1} sx={{ direction: 'row', alignItems: 'center' }}>
                        <Grid item>
                            <CalendarTodayOutlinedIcon
                                fontSize='small'
                                sx={{ color: palette.primary[300] }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                component='span'
                                variant='body2'
                                color={palette.primary[300]}
                            >
                                Fecha de creación
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {date}
                </Grid>
            </Grid>
        </>
    );
}

CreationDateField.propTypes = {
    currentCreationDate: PropTypes.string
};
