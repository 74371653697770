import { useEffect, useState } from 'react';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import PropTypes from 'prop-types';
import CircularIconLoader from './CircularIconLoader';

IconWithCircularLoader.propTypes = {
    icon: PropTypes.node.isRequired,
    size: PropTypes.string,
    loading: PropTypes.bool.isRequired
};

export default function IconWithCircularLoader({ size, icon, loading }) {
    const [showDoneIcon, setShowDoneIcon] = useState(false);
    const [wasDownloading, setWasDownloading] = useState(false);

    useEffect(() => {
        if (wasDownloading && !loading) {
            setShowDoneIcon(true);
            setTimeout(() => {
                setShowDoneIcon(false);
            }, 2000);
        }
        setWasDownloading(loading);
    }, [loading, wasDownloading]);

    return (
        <>
            {showDoneIcon ? (
                <DoneRoundedIcon fontSize={size ? size : 'inherit'} />
            ) : (
                <>{loading ? <CircularIconLoader fontSize={size ? size : 'inherit'} /> : icon}</>
            )}
        </>
    );
}
