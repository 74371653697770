import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

InformationAlert.propTypes = {
    message: PropTypes.string.isRequired,
    onClick: PropTypes.function,
    buttonText: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    secondaryOnClick: PropTypes.function,
    type: PropTypes.oneOf(['info', 'warning', 'highlight']),
    triggerPush: PropTypes.bool
};

export default function InformationAlert({
    message,
    onClick,
    buttonText,
    secondaryButtonText,
    secondaryOnClick,
    type,
    triggerPush
}) {
    let backgroundColor = palette.primary[50];
    let borderColor = palette.primary[300];

    const [pushed, setPushed] = useState(false);

    useEffect(() => {
        if (triggerPush) {
            setPushed(true);
            setTimeout(() => setPushed(false), 200); // Reset after 200ms
        }
    }, [triggerPush]);

    switch (type) {
        case 'info':
            // By Default
            break;
        case 'warning':
            borderColor = palette.warning.dark;
            backgroundColor = palette.warning.light;
            break;
        case 'highlight':
            borderColor = palette.secondary[800];
            backgroundColor = palette.secondary[100];
            break;
    }

    return (
        <Grid item>
            <Grid
                container
                direction={'row'}
                alignItems='center'
                sx={{
                    backgroundColor: backgroundColor,
                    border: `1px solid ${borderColor}`,
                    borderRadius: 1,
                    p: 1,
                    pl: 2,
                    transition: 'transform 0.2s ease-in-out', // Smooth transition
                    transform: pushed ? 'scale(0.95)' : 'scale(1)' // Push effect
                }}
            >
                <Grid item sx={{ flexGrow: 1 }}>
                    <Typography
                        component='span'
                        variant='body2'
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                </Grid>
                {/* Below removed because of not working well */}
                {onClick && (
                    <Grid item>
                        <Button
                            type='submit'
                            variant='contained'
                            onClick={onClick}
                            disableElevation={true}
                            fullWidth={true}
                            color='primary'
                            size='small'
                        >
                            {buttonText}
                        </Button>
                    </Grid>
                )}
                {secondaryOnClick && (
                    <Grid item sx={{ ml: 1 }}>
                        <Button
                            type='submit'
                            variant='text'
                            onClick={secondaryOnClick}
                            disableElevation={true}
                            fullWidth={true}
                            color='primary'
                        >
                            {secondaryButtonText}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
