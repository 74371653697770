import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, FormControl, Grid, Stack, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRoles } from '@common/hooks/useRoles';
import EditableParticipant from './EditableParticipant';
import { handleCreateParticipant } from './utils';

const filter = createFilterOptions();
ParticipantForm.propTypes = {
    participants: PropTypes.array,
    setParticipants: PropTypes.func,
    availableTurns: PropTypes.number,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    editableName: PropTypes.bool,
    numberOfSpeakers: PropTypes.number,
    speakersIntervention: PropTypes.arrayOf(PropTypes.any),
    audioUrl: PropTypes.string,
    handleVoiceChange: PropTypes.func
};

/**
 * Used whenever it is neccessary to add participants locally or async
 *
 */
export default function ParticipantForm({
    participants,
    setParticipants,
    availableTurns,
    error,
    errorMessage,
    editableName,
    numberOfSpeakers,
    speakersIntervention,
    audioUrl,
    handleVoiceChange
}) {
    const { roles, rolesByCategory } = useRoles();
    const givenName = useSelector((state) => state.user.userGivenName);
    const email = useSelector((state) => state.user.userEmail);

    const [name, setName] = useState('');
    const [alertParticipant, setAlertParticipant] = useState(error !== undefined ? error : false);
    const [alertMessage, setAlertMessage] = useState(
        errorMessage !== undefined && errorMessage ? errorMessage : ''
    );
    const [recentUsers] = useState([
        {
            name: givenName,
            email
        }
    ]);

    const handleParticipantInputChange = (event, selectorValue) => {
        if (selectorValue) {
            setName(selectorValue.name);
        } else if (event) {
            setName(event.target.value);
        }
    };

    const handleSaveWithEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleAddParticipant();
            event.target.blur();
        }
    };

    const handleAddParticipant = async () => {
        if (!name) {
            setAlertParticipant(true);
            setAlertMessage(
                '¿Intentas añadir a una persona que no tiene nombre o te has olvidado de ponerlo?'
            );
            return;
        }
        try {
            handleCreateParticipant({
                name,
                turn: null,
                focus: false,
                participants,
                setParticipants,
                setAlertParticipant,
                setAlertMessage,
                setName,
                alertParticipant,
                roleId: null
            });
        } catch (error) {
            setAlertParticipant(true);
            setAlertMessage('Error creando participante, prueba de nuevo.');
        }
    };

    useEffect(() => {
        if (error !== undefined) {
            setAlertParticipant(error);
            if (errorMessage !== undefined) {
                setAlertMessage(errorMessage);
            }
        }
    }, [error, errorMessage]);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}
        >
            {numberOfSpeakers && numberOfSpeakers <= participants?.length ? null : (
                <Grid item sx={{ mr: 0 }} xs={12}>
                    <Stack
                        direction='row'
                        spacing={1}
                        alignItems={alertParticipant ? 'center' : 'flex-end'}
                    >
                        <FormControl variant='standard' sx={{ m: 1, ml: 0, width: '100%' }}>
                            <Autocomplete
                                value={name}
                                selectOnFocus
                                handleHomeEndKeys
                                id='participant'
                                type='text'
                                onChange={(e, selectorValue) =>
                                    // selectorValue is the value of the name suggestion
                                    handleParticipantInputChange(e, selectorValue)
                                }
                                onKeyDown={handleSaveWithEnter}
                                margin='dense'
                                options={recentUsers}
                                freeSolo
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    // TODO: Suggest the creation of a new value
                                    // const { inputValue } = params;
                                    // const isExisting = options.some((option) => inputValue === option.name);
                                    // if (inputValue !== '' && !isExisting) {
                                    //     filtered.push({
                                    //         inputValue,
                                    //         name: `Add "${inputValue}"`
                                    //     });
                                    // }
                                    return filtered;
                                }}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        value={name}
                                        label='Añadir participante'
                                        variant='standard'
                                        error={alertParticipant || alertMessage}
                                        onChange={(event) => handleParticipantInputChange(event)}
                                        helperText={alertParticipant ? alertMessage : ''}
                                        fullWidth={true}
                                    />
                                )}
                            />
                        </FormControl>
                        {name && (
                            <Button endIcon={<AddIcon />} onClick={handleAddParticipant}>
                                Añadir
                            </Button>
                        )}
                    </Stack>
                </Grid>
            )}
            <Grid item xs={12}>
                {participants.length > 0 ? (
                    <>
                        {participants.map((participant, index) => (
                            <div key={index}>
                                <EditableParticipant
                                    participant={participant}
                                    setParticipants={setParticipants}
                                    participants={participants}
                                    availableTurns={availableTurns}
                                    numberOfSpeakers={numberOfSpeakers}
                                    editableName={editableName}
                                    roles={roles}
                                    rolesByCategory={rolesByCategory}
                                    speakersIntervention={speakersIntervention}
                                    audioUrl={audioUrl}
                                    handleVoiceChange={handleVoiceChange}
                                />
                            </div>
                        ))}
                    </>
                ) : null}
            </Grid>
        </Grid>
    );
}
