import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

LoaderTitleWithSubtitle.propTypes = {
    sx: PropTypes.object
};

export default function LoaderTitleWithSubtitle({ sx }) {
    return (
        <Stack spacing={0} sx={{ ...sx }}>
            <Skeleton variant='text' sx={{ fontSize: '1.5rem', ...sx }} width={'100%'} />
            <Skeleton variant='text' sx={{ fontSize: '1rem', ...sx }} width={'70%'} />
        </Stack>
    );
}
