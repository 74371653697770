import React, { useEffect, useRef, useState } from 'react';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import InformationAlert from '@common/components/alerts/InformationAlert';
import DialogButton from '@common/components/dialogs/DialogButton';
import Loader from '@common/components/loaders/Loader';
import ParticipantLoader from '@common/components/loaders/ParticipantLoader';
import ParticipantForm from '@common/components/participants/ParticipantForm';
import { navigation } from '@common/helpers/navigation';
import { urls } from '@common/helpers/urls';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import { getReadSignedAudioURL } from '@setup/api/gcs';
import { submitReportCreation } from '@setup/api/meeting/meeting';
import {
    getLargestInterventionBySpeaker,
    getSpeakingPercentages
} from '@setup/api/meetingInterventions/meetingInterventions';
import { saveAllParticipants } from '@setup/api/participants/participants';

ParticipantsConfig.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object).isRequired,
    meetingId: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    localParticipants: PropTypes.arrayOf(PropTypes.object).isRequired,
    setLocalParticipants: PropTypes.func.isRequired,
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func
};

export default function ParticipantsConfig({
    participants,
    meetingId,
    filename,
    localParticipants,
    setLocalParticipants,
    openDialog,
    setOpenDialog
}) {
    const audioUrl = useRef('');
    const notification = useNotification();
    const navigate = useNavigate();

    const [numberOfSpeakers, setNumberOfSpeakers] = useState(null);
    const [interventions, setInterventions] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [applyInfoScreen, setApplyInfoScreen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [participantsAreIncomplete, setParticipantsAreIncomplete] = useState(false);
    const [finalTurnsMap, setFinalTurnsMap] = useState(null);
    const [participantsChanged, setParticipantsChanged] = useState(false);
    const [pushNotification, setPushNotification] = useState(false);
    const [dialogOpened, setDialogOpened] = useState(false);
    const [showDelayedMessage, setShowDelayedMessage] = useState(false);

    useEffect(() => {
        if (dialogOpened || openDialog) {
            (async () => {
                const largestInterventions = await getLargestInterventionBySpeaker({ meetingId });
                setInterventions(largestInterventions);
                setNumberOfSpeakers(largestInterventions.length);

                // TODO: The adding of % to participants should be done in backend
                if (participants.some((p) => p.interventionPercentage !== null)) {
                    // We use the percentage from participants
                    setFinalTurnsMap(
                        participants.reduce((result, person) => {
                            result[person.turn] = person.interventionPercentage;
                            return result;
                        }, {})
                    );
                } else {
                    const participantTurnsMap = await getSpeakingPercentages({ meetingId });
                    setFinalTurnsMap(participantTurnsMap);
                }

                setLoadingData(false);
            })();
        }
    }, [dialogOpened, openDialog]);

    useEffect(() => {
        if (participants?.length > 0) {
            setLocalParticipants(
                participants.sort((a, b) => (a.turn ?? Infinity) - (b.turn ?? Infinity))
            );
        }
    }, [participants]);

    /**
     * Detects if the participants have changed
     */
    useEffect(() => {
        if (JSON.stringify(participants) !== JSON.stringify(localParticipants)) {
            setParticipantsChanged(true);
        } else {
            setParticipantsChanged(false);
        }
    }, [localParticipants]);

    /**
     * Detects if the participants are incomplete
     */
    useEffect(() => {
        handleParticipantsNotification();
    }, [localParticipants, numberOfSpeakers]);

    /**
     * This effect is used to assign the participant to the intervention
     */
    useEffect(() => {
        if (localParticipants && interventions) {
            const participantMap = new Map();
            localParticipants.forEach((participant) => {
                participantMap.set(participant.turn, participant);
            });
            const newInterventions = interventions.map((intervention) => ({
                ...intervention,
                participant: participantMap.get(intervention.turn) || null // Assign or null if not found
            }));
            setInterventions(newInterventions);
        }
    }, [localParticipants]);

    useEffect(() => {
        (async () => {
            if (filename) {
                audioUrl.current = await getReadSignedAudioURL({ filename });
            }
        })();
    }, []);

    useEffect(() => {
        if (dialogOpened || openDialog) {
            const timer = setTimeout(() => {
                setShowDelayedMessage(true);
            }, 5000);

            return () => clearTimeout(timer); // Cleanup en caso de desmontaje
        } else {
            setOpenDialog(false);
        }
    }, [dialogOpened, openDialog]);

    const handleChangeParticipantTurn = async ({ oldParticipant, newParticipant, turn }) => {
        setLocalParticipants((prevParticipants) => {
            return prevParticipants.map((participant) => {
                if (newParticipant.id >= 0) {
                    if (participant.id === newParticipant.id) {
                        // Assign a new turn to an existing participant
                        return {
                            ...participant,
                            turn,
                            interventionPercentage: finalTurnsMap[turn]
                        };
                    }
                } else {
                    if (participant.name === newParticipant.name) {
                        // Assign a new turn to a brand-new participant
                        return {
                            ...participant,
                            turn,
                            interventionPercentage: finalTurnsMap[turn]
                        };
                    }
                }
                if (oldParticipant && participant.id === oldParticipant.id) {
                    // Remove the turn from the old participant
                    return { ...participant, turn: null, interventionPercentage: null };
                }
                return participant;
            });
        });
    };

    const handleParticipantsNotification = () => {
        // TODO: currently, as numberOfSpearkers last too much to be calculated and is retrieved when opening the dialog, it is not used to set the alert badge
        // TODO: alert badge is just shown when no turns or roles, at least, is the most common thing
        setAlertMessage('');
        setParticipantsAreIncomplete(false);

        if (numberOfSpeakers !== null) {
            if (!localParticipants?.length || localParticipants.length < numberOfSpeakers) {
                console.log('Joining', numberOfSpeakers);
                // Case: there are less participants than speakers
                const missingParticipants = localParticipants?.length
                    ? numberOfSpeakers - localParticipants.length
                    : numberOfSpeakers;
                setParticipantsAreIncomplete(true);
                setAlertMessage(
                    `👥 Falta por añadir <b>${missingParticipants} participante${
                        missingParticipants !== 1 ? 's' : ''
                    }</b> en la conversación para mejorar el informe. `
                );
                return;
            }
            if (localParticipants.length > numberOfSpeakers) {
                console.log('Joining', numberOfSpeakers);

                // Case: there are more participants than speakers
                setParticipantsAreIncomplete(true);
                setAlertMessage(
                    `👥 Hay <b>${localParticipants.length - numberOfSpeakers} participante${
                        localParticipants.length - numberOfSpeakers !== 1 ? 's' : ''
                    } de más</b>. Han hablado ${numberOfSpeakers} en el audio. Contacta con <u>${
                        urls.emails.support
                    }</u> si has tenido algún problema.`
                );
                return;
            }
        }
        if (localParticipants.some((participant) => participant.turn === null)) {
            // Case: there are interventions without participant assigned
            setParticipantsAreIncomplete(true);
            setAlertMessage(
                '👂 Asigna la <b>voz de cada participante</b> para mejorar tu informe.'
            );
            return;
        }
        if (localParticipants.some((participant) => participant.roleId === null)) {
            // Case: there are participants without role
            setParticipantsAreIncomplete(true);
            setAlertMessage('💡Asigna <b>rol a cada participante</b> para mejorar tu informe.');
            return;
        }
    };

    const handleClear = () => {
        setLocalParticipants(participants);
        setParticipantsChanged(false);
        if (openDialog) {
            setOpenDialog(false);
        }
    };

    const handleSaveAndCreate = async () => {
        if (participantsAreIncomplete) {
            setPushNotification(true);
            setTimeout(() => setPushNotification(false), 200);
            return;
        }
        try {
            setApplyInfoScreen(true);
            const startTime = Date.now();

            await saveAllParticipants({ meetingId, participants: localParticipants });
            await submitReportCreation(meetingId);

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(3000 - elapsedTime, 0);

            setTimeout(() => {
                setApplyInfoScreen(false);
                if (openDialog) {
                    setOpenDialog(false);
                }
                navigate(navigation.app.routes.repository);
            }, remainingTime);
        } catch (error) {
            notification('unkown-error');
        }
    };

    return (
        <DialogButton
            id='clickEditParticipants'
            withButton={true}
            iconButton={true}
            iconButtonTooltip={'Editar participantes'}
            buttonIcon={<PeopleOutlineRoundedIcon id='clickEditParticipants' fontSize='small' />}
            dialogTitle={'Editar participantes'}
            successButtonText={'Guardar y reprocesar'}
            onSuccess={handleSaveAndCreate}
            disableSuccessButton={!participantsChanged}
            disableActions={applyInfoScreen}
            maxWidth={'sm'}
            badge={participantsAreIncomplete}
            onClose={handleClear}
            setDialogOpened={setDialogOpened}
            openDialog={openDialog}
        >
            {loadingData ? (
                <>
                    <ParticipantLoader />
                    {showDelayedMessage && (
                        <Typography variant='body2' color={palette.primary[400]}>
                            Voy tardar más de 15 segundos, habéis hablado un montón...
                        </Typography>
                    )}
                </>
            ) : applyInfoScreen ? (
                <Loader
                    minWidth={'20vh'}
                    text={`⚡Tu informe estará listo en unos pocos minutos.`}
                    caption={'Te avisaremos por correo.'}
                />
            ) : (
                <Grid container direction='column'>
                    {alertMessage && (
                        <InformationAlert
                            message={alertMessage}
                            type={'info'}
                            triggerPush={pushNotification}
                        />
                    )}
                    <ParticipantForm
                        participants={localParticipants}
                        editableName={true}
                        numberOfSpeakers={numberOfSpeakers}
                        setParticipants={setLocalParticipants}
                        speakersIntervention={interventions}
                        audioUrl={audioUrl.current}
                        handleVoiceChange={handleChangeParticipantTurn}
                    />
                </Grid>
            )}
        </DialogButton>
    );
}
