import React, { useState } from 'react';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import {
    Box,
    Button,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Typography
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import AvatarWithInfo from '@common/components/avatars/AvatarWithInfo';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import ProgressBar from '@common/components/ProgressBar';
import SubscriptionPlanTag from '@common/components/SubscriptionPlanTag';
import { urls } from '@common/helpers/urls';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';

UserMenuView.propTypes = {
    handleLogOut: PropTypes.func.isRequired,
    handleSettings: PropTypes.func.isRequired,
    handleTemplates: PropTypes.func.isRequired,
    givenName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    monthlyMeetings: PropTypes.number.isRequired,
    avatarImage: PropTypes.string,
    handleIntegrations: PropTypes.func.isRequired
};

export default function UserMenuView({
    handleLogOut,
    handleTemplates,
    handleSettings,
    givenName,
    email,
    avatarImage,
    handleIntegrations
}) {
    const { getPlan } = useUser();
    const userPlan = useSelector((state) => state.user.userPlan);
    const transcriptionMinutes = useSelector((state) => state.stats.transcriptionMinutes);

    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);

    return (
        <>
            <Paper
                sx={{
                    width: 260,
                    maxWidth: '100%',
                    height: getPlan()?.name !== subscriptionPlans.unlimited.name ? 459 : 338, // 36 px element size
                    mt: -1,
                    mb: -1
                }}
                elevation={0}
            >
                <MenuList>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                            ml: 3,
                            mb: 0,
                            mt: 1
                        }}
                    >
                        <AvatarWithInfo
                            avatarImage={avatarImage}
                            avatarName={givenName}
                            subtitle={email}
                        />
                    </Box>
                    <SubscriptionPlanTag sx={{ ml: 3, mb: 2 }} />
                    <Divider />
                    <MenuItem sx={{ mt: 1 }} onClick={handleTemplates}>
                        <ListItemIcon>
                            <DashboardCustomizeOutlinedIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Plantillas</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{ mt: 1 }} onClick={handleIntegrations}>
                        <ListItemIcon>
                            <ExtensionOutlinedIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Integraciones</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{ mt: 1 }} onClick={handleSettings}>
                        <ListItemIcon>
                            <ManageAccountsOutlinedIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Ajustes</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleLogOut}>
                        <ListItemIcon>
                            <LogoutOutlinedIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Salir</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => window.open(urls.helpCenter.main)}>
                        <ListItemIcon>
                            <SupportOutlinedIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Ayuda</ListItemText>
                    </MenuItem>

                    {getPlan()?.name !== subscriptionPlans.unlimited.name ? (
                        <>
                            <Divider />
                            <Box
                                sx={{
                                    backgroundColor: palette.primary.main,
                                    mt: -1,
                                    mb: -3,
                                    p: 2
                                }}
                            >
                                <Typography
                                    component='span'
                                    variant='overline'
                                    color={palette.common.white}
                                >
                                    {getPlan()?.name === subscriptionPlans.premium.name
                                        ? `${
                                              transcriptionMinutes >= 0 &&
                                              transcriptionMinutes <= userPlan?.monthlyUsageMinutes
                                                  ? userPlan?.monthlyUsageMinutes -
                                                    transcriptionMinutes
                                                  : 0
                                          }
                                            minutos restantes`
                                        : 'No tienes minutos'}
                                </Typography>
                                <ProgressBar
                                    maxValue={userPlan?.monthlyUsageMinutes}
                                    currentValue={transcriptionMinutes}
                                    sx={{ mt: 2, mb: 2 }}
                                />
                                <Button
                                    onClick={() => setOpenPremiumDialog(true)}
                                    variant='contained'
                                    color='secondary'
                                    disableElevation={true}
                                    size='small'
                                    fullWidth={true}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: palette.secondary[300]
                                        }
                                    }}
                                >
                                    {getPlan()?.name === subscriptionPlans.premium.name
                                        ? 'Más minutos'
                                        : 'Conseguir minutos'}
                                </Button>
                            </Box>
                        </>
                    ) : null}
                </MenuList>
            </Paper>
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                defaultPlanId={getPlan()?.name === subscriptionPlans.premium.name ? 1 : 0}
                title={'Actualiza tu plan para conseguir más minutos'}
            />
        </>
    );
}
