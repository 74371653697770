import React, { useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';
import Tag from '@common/components/tagsComponents/Tag';
import TagsButtonSelector from '@common/components/tagsComponents/TagsButtonSelector';
import { useGetTagData } from '@common/hooks/useGetTagData';
import useNotification from '@common/hooks/useNotification';
import { updateMeetingTag } from '@setup/api/meeting/meeting';

TagEditable.propTypes = {
    tag: PropTypes.string,
    isShared: PropTypes.bool,
    meetingId: PropTypes.number,
    findAndUpdateMeeting: PropTypes.func,
    setTag: PropTypes.func
};

export default function TagEditable({ isShared, tag, meetingId, findAndUpdateMeeting, setTag }) {
    const [currentTag, setCurrentTag] = useState(tag);

    const { currentTagData } = isShared ? { currentTagData: null } : useGetTagData(currentTag);
    const notification = useNotification();

    const handleOnTagChange = (chip) => {
        setCurrentTag(chip.name);
        if (setTag) {
            // For the case we just need to select a tag, not update it
            setTag(chip);
        } else {
            handleUpdateTag(chip.name);
        }
    };

    const handleDelete = () => {
        if (setTag) {
            // For the case we just need to select a tag, not update it
            setCurrentTag('Sin asignar');
        } else {
            handleUpdateTag('Sin asignar');
        }
    };

    const handleUpdateTag = async (label) => {
        try {
            await updateMeetingTag({ meetingId, tag: label });
            if (findAndUpdateMeeting) {
                findAndUpdateMeeting({ id: meetingId, label });
            }
        } catch (error) {
            notification('meeting-tag-error');
        }
    };

    if (!isShared) {
        return (
            <Grid
                container
                sx={{ direction: 'row', alignItems: 'center', flexDirection: 'row', flexGrow: 1 }}
            >
                <Grid item>
                    <Tag tag={currentTagData} detachTag={handleDelete} />
                </Grid>
                <Grid item sx={{ ml: 2 }}>
                    <TagsButtonSelector
                        iconButton={<ExpandMoreOutlinedIcon />}
                        onTagSelection={handleOnTagChange}
                        tooltipLabel='Cambiar'
                        createTag={true}
                        deleteTag={true}
                    />
                </Grid>
            </Grid>
        );
    }
}
