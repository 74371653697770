import React from 'react';
import AbcIcon from '@mui/icons-material/Abc';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import SegmentIcon from '@mui/icons-material/Segment';
import TitleIcon from '@mui/icons-material/Title';
import { PropTypes } from 'prop-types';
import MenuButton from '@common/components/MenuButton';

const StyleMenu = ({
    dragHandleProps,
    handleTagSelection,
    handleDeleteBlock,
    currentBlock,
    handleAddBlock,
    handleAddBelow
}) => {
    return (
        <div className='dragHandle' {...dragHandleProps}>
            <MenuButton
                iconButton={<DragIndicatorOutlinedIcon fontSize='small' />}
                tooltipText='Arrastrar o click'
                size='small'
                menuList={[
                    {
                        itemFunction: () => handleTagSelection('h3'),
                        itemIcon: <TitleIcon fontSize='small' />,
                        itemText: 'Título'
                    },
                    {
                        itemFunction: () => handleTagSelection('h5'),
                        itemIcon: <AbcIcon fontSize='small' />,
                        itemText: 'Subtítulo'
                    },
                    {
                        itemFunction: () => handleTagSelection('p'),
                        itemIcon: <SegmentIcon fontSize='small' />,
                        itemText: 'Párrafo'
                    },
                    {
                        itemFunction: () =>
                            handleAddBlock({
                                currentBlock,
                                content: currentBlock.htmlContent,
                                tag: currentBlock.htmlTag
                            }),
                        itemIcon: <ContentCopyIcon fontSize='small' />,
                        itemText: 'Duplicar'
                    },
                    {
                        itemFunction: handleAddBelow,
                        itemIcon: <AddIcon fontSize='small' />,
                        itemText: 'Añadir debajo'
                    },
                    {
                        itemFunction: () =>
                            handleDeleteBlock({
                                id: currentBlock.id
                            }),
                        itemIcon: <DeleteOutlineIcon fontSize='small' />,
                        itemText: 'Borrar'
                    }
                ]}
            />
        </div>
    );
};

StyleMenu.propTypes = {
    dragHandleProps: PropTypes.any.isRequired,
    handleDeleteBlock: PropTypes.func.isRequired,
    handleTagSelection: PropTypes.func.isRequired,
    currentBlock: PropTypes.object.isRequired,
    handleAddBlock: PropTypes.func.isRequired,
    handleAddBelow: PropTypes.func.isRequired
};

export default StyleMenu;
