import CatFlag from '@assets/images/cat.jpg';
import GermanFlag from '@assets/images/de.png';
import EnglishFlag from '@assets/images/en.webp';
import SpanishFlag from '@assets/images/es.png';
import FrenchFlag from '@assets/images/fr.png';
import ItalianFlag from '@assets/images/it.png';
import PTFlag from '@assets/images/pt.png';

export const ELanguage = Object.freeze({
    ESP: 'es-ES', // Spanish (Spain)
    ENG: 'en-GB', // English (Great Britain)
    CAT: 'ca-ES', // Catalan (Spain)
    FRA: 'fr-FR', // French (France)
    DEU: 'de-DE', // German (Germany)
    ITA: 'it-IT', // Italian (Italy)
    POR: 'pt-PT', // Portuguese (Portugal)
    JAP: 'ja-JP' // Japanese (Japan)
});

export const getLanguageFlag = (languageCode) => {
    switch (languageCode) {
        case ELanguage.ESP:
            return SpanishFlag;
        case ELanguage.ENG:
            return EnglishFlag;
        case ELanguage.CAT:
            return CatFlag;
        case ELanguage.FRA:
            return FrenchFlag;
        case ELanguage.DEU:
            return GermanFlag;
        case ELanguage.ITA:
            return ItalianFlag;
        case ELanguage.POR:
            return PTFlag;
    }
};
