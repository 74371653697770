import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Page from '@common/components/Page';
import palette from '@common/theme/palette/palette';

const NotAuthorizedPage = () => {
    const navigate = useNavigate();
    return (
        <Page title='No tienes acceso a este recurso'>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    display='flex'
                    alignItems='center'
                >
                    <Grid item>
                        <Typography component={'span'} variant='subtitle1' color='primary'>
                            No tienes acceso a este recurso
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component={'span'} variant='body1' color={palette.primary[500]}>
                            Pídele a quien te compartió este enlace que genere un link de compartir.
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mt: 5 }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            size='large'
                            disableElevation={true}
                            onClick={() => navigate(`/`)}
                        >
                            Volver
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
};

export default NotAuthorizedPage;
