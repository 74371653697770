import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import useNotification from '@common/hooks/useNotification';
import { timeKeyToSeconds } from '@common/utils/time';
import {
    createReportBlock,
    deleteReportBlock,
    updateReportBlockContent,
    updateReportBlocksPosition,
    updateReportBlockTag
} from '@setup/api/reportBlocks/reportBlocks';
import ReportView from './ReportView';

export default function Report({
    meetingId,
    isShared,
    blocks,
    getReportBlocksHandler,
    setBlocks,
    setCurrentAudioTime
}) {
    const notification = useNotification();
    const [currentBlockId, setCurrentBlockId] = useState(null);
    const [deletedBlock, setDeletedBlock] = useState(); // TODO: actually we also should save all actions

    const updateBlockHandler = async ({ currentBlock, htmlChanged, tagChanged }) => {
        try {
            const index = blocks.map((b) => b.id).indexOf(currentBlock.id);
            const updatedBlocks = [...blocks];
            updatedBlocks[index] = {
                ...updatedBlocks[index],
                htmlTag: currentBlock.htmlTag,
                htmlContent: currentBlock.htmlContent
            };
            if (htmlChanged) {
                await updateReportBlockContent({
                    id: currentBlock.id,
                    newHtmlContent: currentBlock.htmlContent
                });
                if (tagChanged) {
                    await updateReportBlockTag({
                        id: currentBlock.id,
                        newHtmlTag: currentBlock.htmlTag
                    });
                }
            } else if (tagChanged) {
                await updateReportBlockTag({
                    id: currentBlock.id,
                    newHtmlTag: currentBlock.htmlTag
                });
            }

            setBlocks(updatedBlocks);
        } catch (e) {
            notification('updating-blocks-error');
        }
    };

    const addBlockHandler = async ({ currentBlock, content, tag }) => {
        // ! caret isn´t set at start
        // ! content is not remove from the last block if needed - we should get the caret position
        try {
            // save id for
            setCurrentBlockId(currentBlock.id);

            // Below is for slicing the content when doing enter in the middle of the text
            // if (content) {
            //     // I need caretPosition counting the html content to know where to slice
            //     await updateBlockHandler({
            //         currentBlock: {
            //             ...currentBlock,
            //             htmlContent: currentBlock.htmlContent.replace(content, '')
            //         },
            //         htmlChanged: true
            //     });
            //     // I need to also update this block
            // }
            // look for the index of the block
            const index = blocks.map((b) => b.id).indexOf(currentBlock.id);
            // save current blocks into a new array to be modified
            const updatedBlocks = [...blocks];
            // call to create the block with the content
            const { reportBlock: newBlock } = await createReportBlock({
                meetingId,
                htmlContent: content ? content : '',
                htmlTag: tag,
                position: index,
                style: ''
            });
            // adding the block to the new array, using split but not removing any blocks just inserting
            updatedBlocks.splice(index + 1, 0, newBlock);
            // we introduce the last block into its position again
            updatedBlocks[index] = {
                ...updatedBlocks[index],
                htmlTag: currentBlock.htmlTag,
                htmlContent: currentBlock.htmlContent
            };

            // ! If I delete below sometimes I don´t see the UI refreshing
            setBlocks(updatedBlocks);
        } catch (error) {
            notification('creating-blocks-error');
        }
    };

    const deleteBlockHandler = async (currentBlock) => {
        // ! If block has content we don´t remove the block and neither introduce in the last
        try {
            if (blocks.length > 1) {
                setCurrentBlockId(currentBlock.id);
                const index = blocks.map((b) => b.id).indexOf(currentBlock.id);
                const updatedBlocks = [...blocks];
                updatedBlocks.splice(index, 1);
                setDeletedBlock(blocks[index]);

                await deleteReportBlock({
                    id: currentBlock.id,
                    meetingId,
                    position: index + 1,
                    lastBlockPosition: blocks.length
                });
                setBlocks(updatedBlocks);
                return updatedBlocks;
            }
        } catch (error) {
            notification('deleting-blocks-error');
        }
    };

    const undoBlockDeletion = async () => {
        if (deletedBlock) {
            await addBlockHandler({
                currentBlock: blocks[deletedBlock.position - 1]
                    ? blocks[deletedBlock.position - 1]
                    : 0,
                content: deletedBlock.htmlContent,
                tag: deletedBlock.htmlTag
            });
            setDeletedBlock();
        }
    };

    const intoduceBlockIntoAnother = async (currentBlock) => {
        try {
            // getting index of previous block
            const index = blocks.map((b) => b.id).indexOf(currentBlock.id) - 1;
            const previousBlocks = await deleteBlockHandler(currentBlock);
            const updatedBlocks = [...previousBlocks];
            updatedBlocks[index] = {
                ...updatedBlocks[index],
                htmlContent: updatedBlocks[index].htmlContent + currentBlock.htmlContent
            };
            await updateReportBlockContent({
                id: updatedBlocks[index].id,
                newHtmlContent: updatedBlocks[index].htmlContent
            });
            setBlocks(updatedBlocks);
        } catch (e) {
            notification('updating-blocks-error');
        }
    };

    const onDragEndHandler = async (result) => {
        try {
            const { destination, source } = result;
            // If we don't have a destination (due to dropping outside the droppable)
            // or the destination hasn't changed, we change nothing
            if (!destination || destination.index === source.index) {
                return;
            }

            const updatedBlocks = [...blocks];
            const removedBlocks = updatedBlocks.splice(source.index - 1, 1);
            updatedBlocks.splice(destination.index - 1, 0, removedBlocks[0]);
            updatedBlocks.map((ub, index) => {
                ub.position = index;
                return ub;
            });
            await updateReportBlocksPosition(updatedBlocks, meetingId);
            setBlocks(updatedBlocks);
        } catch (e) {
            notification('dragging-blocks-error');
        }
    };

    useEffect(() => {
        if (blocks) {
            const links = document.querySelectorAll('.time-ref');

            const handleClick = (event) => {
                event.preventDefault(); // Prevent the default action
                event.stopPropagation(); // Stop the event from propagating to the parent

                // Temporarily disable contenteditable
                const parent = event.target.closest('[contenteditable]');
                if (parent) {
                    parent.setAttribute('contenteditable', 'false');
                }

                // Get the parameter value from the data attribute
                let paramValue = event.target.getAttribute('data-time-key');
                setCurrentAudioTime(timeKeyToSeconds(Number(paramValue)));

                // Re-enable contenteditable
                if (parent) {
                    setTimeout(() => {
                        parent.setAttribute('contenteditable', 'true');
                    }, 0);
                }
            };

            links.forEach((link) => {
                link.addEventListener('click', handleClick);
            });

            // Cleanup event listeners on component unmount
            return () => {
                links.forEach((link) => {
                    link.removeEventListener('click', handleClick);
                });
            };
        }
    }, [blocks]);

    if (blocks) {
        return (
            <ReportView
                meetingId={meetingId}
                blocks={blocks}
                addBlockHandler={addBlockHandler}
                deleteBlockHandler={deleteBlockHandler}
                updateBlockHandler={updateBlockHandler}
                onDragEndHandler={onDragEndHandler}
                getReportBlocksHandler={getReportBlocksHandler}
                currentBlockId={currentBlockId}
                isShared={isShared}
                intoduceBlockIntoAnother={intoduceBlockIntoAnother}
                undoBlockDeletion={undoBlockDeletion}
            />
        );
    }
}

Report.propTypes = {
    meetingId: PropTypes.number.isRequired,
    isShared: PropTypes.bool.isRequired,
    blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
    getReportBlocksHandler: PropTypes.func.isRequired,
    setBlocks: PropTypes.func.isRequired,
    setCurrentAudioTime: PropTypes.func.isRequired
};
