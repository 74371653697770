import { useState } from 'react';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { Button, Grid, Stack, Typography } from '@mui/material';
import discordLogo from '@assets/logos/Discord.png';
import driveLogo from '@assets/logos/Drive.png';
import hubspotLogo from '@assets/logos/Hubspot.png';
import notionLogo from '@assets/logos/Notion.png';
import slackLogo from '@assets/logos/Slack.png';
import zapierLogo from '@assets/logos/Zapier.png';
import InformationAlert from '@common/components/alerts/InformationAlert';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import { urls } from '@common/helpers/urls';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import IntegrationItem from './IntegrationItem';

export default function IntegrationsTab() {
    const { getPlan } = useUser();

    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);

    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    justifyContent: 'left',
                    mb: 5
                }}
            >
                <Grid item>
                    <Grid container direction={'row'} alignItems='center'>
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Stack>
                                <Typography component={'span'} variant='subtitle1'>
                                    Integraciones
                                </Typography>
                                <Typography
                                    component={'span'}
                                    variant='body2'
                                    color={palette.primary[300]}
                                    sx={{ mb: 2 }}
                                >
                                    Conecta Voicit con tus aplicaciones favoritas y optimiza tu
                                    flujo de trabajo
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='text'
                                startIcon={<CampaignOutlinedIcon fontSize='small' />}
                                onClick={() => window.open(urls.forms.integrations)}
                            >
                                ¿No encuentras tu aplicación?
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid id='zapier-hubspot' item sx={{ mb: 1 }}>
                    <zapier-zap-templates
                        theme='auto'
                        apps='voicit'
                        create-without-template='hide'
                        limit={5}
                        link-target='new-tab'
                        presentation='card'
                        use-this-zap='show'
                    />
                </Grid> */}
                <Grid item>
                    <InformationAlert
                        message='👋 ¿Necesitas que te echemos una mano para integrar tu cuenta con
                    otra aplicación?'
                        onClick={() => window.open(urls.calendar.supportCalendar)}
                        buttonText='Solicitar ayuda'
                    />
                    <Grid
                        container
                        direction={'row'}
                        alignItems='center'
                        justifyContent='space-between'
                        spacing={2}
                        sx={{ mt: 1 }}
                    >
                        <Grid item>
                            <IntegrationItem
                                title='Hubspot'
                                description='Crea o actualiza contactos de hubspot después de cada reunión con la información de esta a través de Zapier.'
                                logo={hubspotLogo}
                                tag='Zapier'
                                customOnClick={
                                    getPlan().name !== subscriptionPlans.free.name
                                        ? () => window.open(urls.integrations.zapier.hubspot)
                                        : () => setOpenPremiumDialog(true)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <IntegrationItem
                                title='Notion'
                                description='Crea páginas con la información de tu reuníon en tu repositorio de Notion después de cada reunión con Voicit a través de Zapier.'
                                logo={notionLogo}
                                tag='Zapier'
                                customOnClick={
                                    getPlan().name !== subscriptionPlans.free.name
                                        ? () => window.open(urls.integrations.zapier.notion)
                                        : () => setOpenPremiumDialog(true)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <IntegrationItem
                                title='Slack'
                                description='Obtén notificaciones en tu canal favorito de Slack después de cada nueva reunión con Voicit a través de Zapier.'
                                logo={slackLogo}
                                tag='Zapier'
                                customOnClick={
                                    getPlan().name !== subscriptionPlans.free.name
                                        ? () => window.open(urls.integrations.zapier.slack)
                                        : () => setOpenPremiumDialog(true)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <IntegrationItem
                                title='Drive'
                                description='Guarda nuevas reuniones de Voicit en Google Drive como documentos de texto a través de Zapier.'
                                logo={driveLogo}
                                tag='Zapier'
                                customOnClick={
                                    getPlan().name !== subscriptionPlans.free.name
                                        ? () => window.open(urls.integrations.zapier.drive)
                                        : () => setOpenPremiumDialog(true)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <IntegrationItem
                                title='Discord'
                                description='Obtén notificaciones en tu canal favorito de Discord después de cada nueva reunión con Voicit a través de Zapier.'
                                logo={discordLogo}
                                tag='Zapier'
                                customOnClick={
                                    getPlan().name !== subscriptionPlans.free.name
                                        ? () => window.open(urls.integrations.zapier.discord)
                                        : () => setOpenPremiumDialog(true)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <IntegrationItem
                                title='Zapier'
                                description='Conecta Voicit con la aplicación que quieras (+5000 apps) y libera su
                                        potencial con automatizaciones fluidas y personalizadas.'
                                logo={zapierLogo}
                                customOnClick={
                                    getPlan().name !== subscriptionPlans.free.name
                                        ? () => window.open(urls.integrations.zapier.voicit)
                                        : () => setOpenPremiumDialog(true)
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <PricingDialog openDialog={openPremiumDialog} setOpenDialog={setOpenPremiumDialog} />
        </>
    );
}
