import { Box, CircularProgress, circularProgressClasses } from '@mui/material';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

const SIZES = {
    extrasmall: {
        size: 16,
        thickness: 6
    },
    small: {
        size: 25,
        thickness: 2
    },
    medium: {
        size: 40,
        thickness: 4
    },
    large: {
        size: 60,
        thickness: 6
    }
};

CircularLoader.propTypes = {
    size: PropTypes.string.isRequired,
    infinite: PropTypes.bool
};

export default function CircularLoader({ size = 'medium', infinite = true, ...props }) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant='determinate'
                sx={{
                    color: palette.primary[100]
                }}
                size={SIZES[size].size || SIZES.medium.size}
                thickness={SIZES[size].thickness || SIZES.medium.thickness}
                {...props}
                value={100}
            />
            <CircularProgress
                variant={infinite ? 'indeterminate' : 'determinate'}
                disableShrink
                sx={{
                    color: palette.primary[800],
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round'
                    }
                }}
                size={SIZES[size].size || SIZES.medium.size}
                thickness={SIZES[size].thickness || SIZES.medium.thickness}
                {...props}
            />
        </Box>
    );
}
