import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import AudioPlayer from '@common/components/audioPlayer/AudioPlayer';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import Loader from '@common/components/loaders/Loader';
import ParticipantForm from '@common/components/participants/ParticipantForm';
import TagEditable from '@common/components/tagsComponents/TagEditable';
import TemplateSelector from '@common/components/TemplateSelector';
import palette from '@common/theme/palette/palette';
import { DialogTransition } from './utils';

export default function CreateMeetingDialogView({
    handleOnTitleChange,
    setTemplate,
    setParticipants,
    setTag,
    alertTitle,
    alertMessage,
    alertParticipant,
    title,
    loading,
    open,
    filename,
    file,
    participants,
    tag,
    template,
    audioDuration,
    audioUrl,
    handleCreateMeeting,
    closeConfirmationOpen,
    setCloseConfirmationOpen,
    handleClose,
    handleDeleteFile,
    handleDownload
}) {
    const [nextStep, setNextStep] = useState(false);

    const durationForecast = () => {
        const audioDurationMinutes = audioDuration / 60;
        const forecast = audioDurationMinutes / 2;
        return Math.ceil(forecast < 2 ? 2 : forecast);
    };

    return (
        <div>
            {closeConfirmationOpen && (
                <ConfirmationDialog
                    open={closeConfirmationOpen}
                    title={`¿Estás seguro de que deseas cancelar?`}
                    message={`"${title}" no empezará a procesarse hasta que completes los datos.`}
                    onClose={() => {
                        setCloseConfirmationOpen(false);
                    }}
                    onConfirm={handleClose}
                />
            )}

            <Dialog
                open={open}
                TransitionComponent={DialogTransition}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>
                    {title && nextStep ? title : 'Nueva reunión'}
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                {!loading && (
                    <DialogContent>
                        {!nextStep ? (
                            <>
                                <TextField
                                    autoFocus={true}
                                    error={alertTitle}
                                    helperText={alertTitle ? alertMessage : ''}
                                    margin='dense'
                                    id='title'
                                    label='Título de la reunión'
                                    type='text'
                                    fullWidth
                                    variant='standard'
                                    value={title}
                                    onChange={handleOnTitleChange}
                                    sx={{ mb: 1 }}
                                />
                                <Grid container direction='column' spacing={2} sx={{ mt: 0 }}>
                                    <Grid item>
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    color={palette.primary[300]}
                                                >
                                                    Plantilla
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    color={palette.primary.main}
                                                >
                                                    Elige la plantilla para generar tu informe
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <TemplateSelector
                                            template={template}
                                            setTemplate={setTemplate}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    color={palette.primary[300]}
                                                >
                                                    Etiqueta
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    color={palette.primary.main}
                                                >
                                                    Encuenta tu reunión fácilmente con etiquetas
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <TagEditable tag={tag?.name} setTag={setTag} />
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Grid container direction='column'>
                                <Typography
                                    sx={{ mt: 2 }}
                                    component='span'
                                    variant='body2'
                                    color={palette.primary[300]}
                                >
                                    Participantes
                                </Typography>
                                <Typography
                                    component='span'
                                    variant='body2'
                                    color={palette.primary.main}
                                >
                                    Mejora tu resumen escuchando el audio para asignar el turno de
                                    intervención de cada participante y elige su rol para obtener
                                    información más detallada
                                </Typography>
                                <AudioPlayer
                                    filename={filename}
                                    file={file}
                                    audioUrl={audioUrl}
                                    audioDuration={audioDuration} // It is not needed actually
                                    handleDeleteFile={handleDeleteFile}
                                    handleDownload={handleDownload}
                                    size='small'
                                    enablePlaybackRate={true}
                                    sx={{ mt: 2 }}
                                />

                                <ParticipantForm
                                    participants={participants}
                                    setParticipants={setParticipants}
                                    error={alertParticipant}
                                    errorMessage={alertMessage}
                                    editableName={true}
                                    availableTurns={participants.length}
                                />
                            </Grid>
                        )}
                    </DialogContent>
                )}
                {loading && (
                    <DialogContent>
                        <Loader
                            minWidth={'20vh'}
                            text={`⚡Puedes cerrar Voicit. En menos de ${durationForecast()} min te avisaremos por correo.`}
                            caption={'Tiempo estimado, puede extenderse en ocasiones.'}
                        />
                    </DialogContent>
                )}
                {!loading && (
                    <DialogActions>
                        {!nextStep ? (
                            <Button
                                variant='contained'
                                disableElevation={true}
                                onClick={() => setNextStep(true)}
                            >
                                Siguiente
                            </Button>
                        ) : (
                            <>
                                <Button color='primary' onClick={() => setNextStep(false)}>
                                    Atrás
                                </Button>
                                <Button
                                    variant='contained'
                                    disableElevation={true}
                                    onClick={handleCreateMeeting}
                                >
                                    {participants.length > 0
                                        ? 'Crear'
                                        : 'Crear sin añadir participantes'}
                                </Button>
                            </>
                        )}
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}

CreateMeetingDialogView.propTypes = {
    handleCreateMeeting: PropTypes.func.isRequired,
    handleOnTitleChange: PropTypes.func,
    title: PropTypes.string,
    loading: PropTypes.bool,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    alertTitle: PropTypes.bool,
    alertMessage: PropTypes.string,
    alertParticipant: PropTypes.bool,
    filename: PropTypes.string,
    file: PropTypes.any,
    handleOnTurnChange: PropTypes.func,
    turn: PropTypes.any,
    participants: PropTypes.arrayOf(PropTypes.object),
    turnsList: PropTypes.arrayOf(PropTypes.string),
    setParticipantsNumber: PropTypes.func,
    selectedTurns: PropTypes.arrayOf(PropTypes.number),
    participantsNumber: PropTypes.string,
    alertParticipants: PropTypes.bool,
    setTag: PropTypes.func,
    tag: PropTypes.any,
    template: PropTypes.any,
    setTemplate: PropTypes.func,
    setParticipants: PropTypes.func,
    audioDuration: PropTypes.number,
    audioUrl: PropTypes.string,
    closeConfirmationOpen: PropTypes.bool.isRequired,
    setCloseConfirmationOpen: PropTypes.func.isRequired,
    isDirectUpload: PropTypes.bool.isRequired,
    handleDeleteFile: PropTypes.func,
    handleDownload: PropTypes.func
};
