import { forwardRef } from 'react';
import { Slide } from '@mui/material';

export const DialogTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const showDurationAlert = ({
    duration,
    planDurationLimit,
    setAlertMessage,
    setAlertFile
}) => {
    if (duration > planDurationLimit * 60) {
        setAlertFile(true);
        setAlertMessage(`El audio no puede ser superior a ${planDurationLimit} minutos`);
        return true;
    } else {
        return false;
    }
};

export const showFormatAlert = ({ file, setAlertMessage, setAlertFile }) => {
    if (['audio/mpeg', 'audio/wav', 'video/webm', 'audio/mp3'].indexOf(file.type) < 0) {
        // ! 'audio/x-m4a' format waiting implementation
        setAlertFile(true);
        setAlertMessage('Los formatos de audio válidos son .mp3, .wav o .webm');
        return true;
    } else {
        return false;
    }
};

export const showFileAlert = ({ file, setAlertMessage, setAlertFile }) => {
    if (!file) {
        setAlertFile(true);
        setAlertMessage('Debes subir un archivo de audio válido.');
        return true;
    } else {
        return false;
    }
};

export const showTitleAlert = ({ title, setAlertMessage, setAlertTitle }) => {
    if (!title) {
        setAlertTitle(true);
        setAlertMessage('Introduce un título, por favor.');
        return true;
    } else {
        return false;
    }
};

/**
 * Used to avoid upload a mix of participants with and without turns
 */
export const showParticipantsTurnAlert = ({
    currentParticipants,
    setAlertMessage,
    setAlertParticipant
}) => {
    let turnKnown = false;
    let turnUnknown = false;
    if (currentParticipants.length > 0) {
        // Check if turns are mixed
        currentParticipants.forEach((s) => {
            if (s.turn >= 0) {
                turnKnown = true;
            } else {
                turnUnknown = true;
            }
        });
        if (turnKnown && turnUnknown) {
            setAlertParticipant(true);
            setAlertMessage(
                'Asigna turno a todos los participantes, escucha el audio si lo necesitas'
            );
            return true;
        }
        return false;
    } else {
        return false;
    }
};

/**
 * Used to avoid upload a mix of participants with and without roles and without turns but roles
 */
export const showParticipantsRoleAlert = ({
    currentParticipants,
    setAlertMessage,
    setAlertParticipant
}) => {
    let roleKnown = false;
    let roleUnknown = false;
    let turnUnknown = false;

    if (currentParticipants.length > 0) {
        // Check if roles are mixed
        currentParticipants.forEach((s) => {
            if (s.roleId) {
                roleKnown = true;
            } else {
                roleUnknown = true;
            }
            if (s.turn < 0) turnUnknown = true;
        });

        if (roleKnown && roleUnknown) {
            setAlertParticipant(true);
            setAlertMessage('Asigna rol a todas las personas');
            return true;
        } else if (roleKnown && turnUnknown) {
            setAlertParticipant(true);
            setAlertMessage('Asigna turno a los participantes, escucha el audio si lo necesitas');
            return true;
        }
        return false;
    } else {
        return false;
    }
};

/**
 * Used to avoid hiring users wihtout detailing participants
 */
export const showParticipantsAlert = ({
    currentParticipants,
    setAlertMessage,
    setAlertParticipant
}) => {
    console.log('ParticipanteS: ', currentParticipants);
    let roleUnKnown = false;
    let turnUnknown = false;
    if (currentParticipants.length >= 2) {
        // Check if turns are mixed
        currentParticipants.forEach((s) => {
            if (s.turn < 0) turnUnknown = true;
            if (!s.roleId) roleUnKnown = true;
        });
        if (turnUnknown || roleUnKnown) {
            setAlertParticipant(true);
            setAlertMessage(
                'Asigna turno y rol a todos los participantes, escucha el audio si lo necesitas'
            );
            return true;
        }
        return false;
    } else {
        setAlertParticipant(true);
        setAlertMessage('Añade los participantes de la entrevista.');
        return true;
    }
};
