import { authApiCall, endpoints } from '..';

export async function getAllMeetingInterventions({ meetingId }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/${meetingId}`
    });

    return {
        interventions: res.data,
        interventionsStatus: res.status
    };
}

export async function getLargestInterventionBySpeaker({ meetingId }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/speakers/largest-intervention/${meetingId}`
    });

    return res.data.interventions;
}

export async function getSpeakingPercentages({ meetingId }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/speakers/speaking-percentages/${meetingId}`
    });

    return res.data;
}

export async function getPaginatedMeetingInterventions({ meetingId, page, pageSize }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/paginated/${meetingId}/${page}/${pageSize}`
    });

    return {
        interventions: res.data.data,
        count: res.data.count
    };
}

export async function updateMeetingInterventionLines({
    meetingId,
    newLines,
    meetingInterventionId
}) {
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.meetingInterventions,
        path: '/update/lines/one',
        data: {
            id: meetingInterventionId,
            meetingId,
            newLines
        }
    });
}

export async function deleteMeetingIntervention({ meetingId, meetingInterventionId }) {
    await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.meetingInterventions,
        path: '/delete/one',
        data: {
            meetingId,
            id: meetingInterventionId
        }
    });
}
