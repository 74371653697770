import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Chip, Grid, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import VoicitAvatar from '@common/components/avatars/VoicitAvatar';
import CopyIconButton from '@common/components/buttons/CopyIconButton';
import IconWithCircularLoader from '@common/components/loaders/IconWithCircularLoading';
import Paragraph from '@common/components/Paragraph';
import palette from '@common/theme/palette/palette';
import './styles.css';

TypewriterEffect.propTypes = {
    text: PropTypes.string.isRequired,
    speed: PropTypes.number
};

function TypewriterEffect({ text, speed = 50 }) {
    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + text.charAt(index));
                setIndex(index + 1);
            }, speed);

            return () => clearTimeout(timeout);
        }
    }, [index, text, speed]);

    return <Paragraph text={displayedText} color={palette.primary[900]} />;
}

ChatMessage.propTypes = {
    role: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    messagesCount: PropTypes.number.isRequired,
    handleAddResponseToReport: PropTypes.func.isRequired,
    loadingAddToReport: PropTypes.bool.isRequired
};

export default function ChatMessage({
    role,
    content,
    position,
    isLoading,
    messagesCount,
    handleAddResponseToReport,
    loadingAddToReport
}) {
    const [showActions, setShowActions] = useState(false);

    return (
        <Grid
            id={position}
            container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 1
            }}
            wrap='nowrap'
            spacing={0}
            onMouseEnter={() => setShowActions(true)}
            onMouseLeave={() => setShowActions(false)}
        >
            <Grid item>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        mb: 1
                    }}
                    spacing={2}
                >
                    {role === 'assistant' && (
                        <Grid item>
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    overflow: 'visible'
                                }}
                            >
                                <Grid item pl={1}>
                                    <VoicitAvatar
                                        size={28}
                                        sx={{
                                            animationName: isLoading ? 'circle-pulse' : 'none',
                                            animationDuration: '1s',
                                            animationIterationCount: 'infinite',
                                            // backgroundColor: palette.secondary[400],
                                            border: isLoading
                                                ? 'none'
                                                : `1px solid ${palette.primary[50]}`
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    ml: 0,
                    mr: 0,
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Grid
                    item
                    justifyContent={'left'}
                    xs={12}
                    zeroMinWidth
                    sx={{
                        backgroundColor: role === 'user' ? palette.primary[50] : 'transparent',
                        padding: role === 'user' ? 2 : 0,
                        borderRadius: 2,
                        mb: role === 'user' ? 2 : 2.5,
                        pb: 0,
                        ml: role === 'user' ? 5 : 0
                    }}
                >
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}
                    >
                        <Grid item xs={12} pl={role === 'assistant' ? 1 : 0}>
                            {role === 'assistant' && content && messagesCount - 1 === position ? (
                                <TypewriterEffect text={content} speed={5} />
                            ) : (
                                <>
                                    {isLoading ? (
                                        <Typography component='div' key='s01-03' variant='body1'>
                                            <Skeleton animation='pulse' width='45%' />
                                        </Typography>
                                    ) : (
                                        <Paragraph text={content} color={palette.primary[900]} />
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                {showActions && role === 'assistant' && !isLoading && (
                    <Stack
                        direction='row'
                        spacing={1}
                        sx={{ ml: 1, mt: -4.5 }}
                        alignItems={'center'}
                    >
                        <CopyIconButton content={content} size='small' tooltipPlacement={'right'} />
                        <Chip
                            variant='contained'
                            icon={
                                <IconWithCircularLoader
                                    loading={loadingAddToReport}
                                    icon={<AddRoundedIcon fontSize='small' />}
                                    size={'small'}
                                />
                            }
                            label='Añadir a informe'
                            onClick={() => handleAddResponseToReport(position)}
                            size='small'
                            sx={{
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: palette.primary[50]
                                }
                            }}
                        />
                    </Stack>
                )}
            </Grid>
        </Grid>
    );
}
