import { navigation } from '@common/helpers/navigation';
import { removeUser } from '@setup/redux/reducers/userSlice';
import { setAuthToken } from '../../setup/redux/reducers/authSlice';

export const handleLogOut = async (navigate, dispatch) => {
    try {
        dispatch(removeUser());
        dispatch(setAuthToken(null));
        navigate(navigation.app.routes.login);
    } catch (error) {
        console.error(error);
    }
};

export const shouldNotAddToken = (req) => {
    const { url, method } = req;
    const isNonAuthRoute = method === 'post' && url.indexOf('/auth/') > -1;
    const isSharedRoute = url.indexOf('/shared/') > -1;

    return isNonAuthRoute || isSharedRoute;
};
