import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

export default function ParticipantsField({ participants }) {
    return (
        <>
            {participants?.length ? (
                <>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            mb: 1
                        }}
                    >
                        <Grid item xs={12} sm={4}>
                            <Grid
                                container
                                spacing={1}
                                sx={{ direction: 'row', alignItems: 'center' }}
                            >
                                <Grid item>
                                    <PeopleAltOutlinedIcon
                                        fontSize='small'
                                        sx={{ color: palette.primary[300] }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        color={palette.primary[300]}
                                    >
                                        Asistentes
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Grid
                                container
                                spacing={0}
                                sx={{ direction: 'row', alignItems: 'left' }}
                            >
                                {participants.map((participant) => (
                                    <Chip
                                        key={participant.name}
                                        size='small'
                                        label={participant.name}
                                        sx={{
                                            mr: 1,
                                            mt: 1,
                                            color: palette.primary.main,
                                            backgroundColor: palette.primary[50]
                                        }}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <></>
            )}
        </>
    );
}

ParticipantsField.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.string)
};
