import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useNotification from '@common/hooks/useNotification';
import copyToClipboard from '@common/utils/copyToClipboard';
import { getReadSignedAudioURL } from '@setup/api/gcs';
import { copyReportBlocksContent } from '@setup/api/reportBlocks/reportBlocks';
import { getReportTemplateById } from '@setup/api/reportTemplates/reportTemplates';
import MeetingSideMenuView from './MeetingSideMenuView';

MeetingSideMenu.propTypes = {
    tag: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    isShared: PropTypes.bool,
    meetingId: PropTypes.number,
    authToken: PropTypes.string,
    platform: PropTypes.string,
    handleUpdateBlocksWords: PropTypes.func.isRequired,
    setCurrentAudioTime: PropTypes.func.isRequired,
    currentAudioTime: PropTypes.number.isRequired,
    templateId: PropTypes.number,
    language: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default function MeetingSideMenu({
    tag,
    date,
    time,
    title,
    duration,
    templateId,
    isShared,
    meetingId,
    filename,
    platform,
    setCurrentAudioTime,
    currentAudioTime,
    language,
    participants
}) {
    const notification = useNotification();
    const audioUrl = useRef('');

    const [template, setTemplate] = useState(null);

    const handleCopyReport = async () => {
        try {
            const reportContent = await copyReportBlocksContent(meetingId);
            await copyToClipboard(reportContent);
        } catch (error) {
            notification('error-copying-blocks-content');
        }
    };

    useEffect(() => {
        (async () => {
            if (!isShared && filename) {
                audioUrl.current = await getReadSignedAudioURL({ filename });
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (templateId && templateId !== null) {
                const currentTemplate = await getReportTemplateById({
                    reportTemplateId: templateId
                });
                setTemplate(currentTemplate.data);
            }
        })();
    }, []);

    return (
        <MeetingSideMenuView
            participants={participants}
            title={title}
            tag={tag}
            date={date}
            time={time}
            duration={duration}
            isShared={isShared}
            meetingId={meetingId}
            handleCopyReport={handleCopyReport}
            filename={filename}
            platform={platform}
            audioUrl={audioUrl.current}
            setCurrentAudioTime={setCurrentAudioTime}
            currentAudioTime={currentAudioTime}
            template={template}
            language={language}
        />
    );
}
