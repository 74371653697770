import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { PropTypes } from 'prop-types';
import { getParticipantsByMeetingId } from '@setup/api/participants/participants';
import CreationDateField from './CreationDateField';
import DurationField from './DurationField';
import ParticipantsField from './ParticipantsField';
import TagField from './TagField';

export default function Info({ currentMeeting, findAndUpdateMeeting }) {
    const [participants, setParticipants] = useState();

    useEffect(() => {
        (async () => {
            const currentParticipants = await getParticipantsByMeetingId(currentMeeting.id);
            setParticipants(currentParticipants);
        })();
    }, []);

    return (
        <>
            <Box sx={{ height: 'auto' }}>
                <TagField
                    currentTag={currentMeeting.tag}
                    meetingId={currentMeeting.id}
                    findAndUpdateMeeting={findAndUpdateMeeting}
                />
                <CreationDateField currentCreationDate={currentMeeting.createdAt} />
                <ParticipantsField participants={participants} />
                <DurationField currentDuration={currentMeeting.duration} />
            </Box>
        </>
    );
}

Info.propTypes = {
    currentMeeting: PropTypes.object,
    findAndUpdateMeeting: PropTypes.func
};
