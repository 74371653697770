import React, { useEffect, useRef, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Forward5Icon from '@mui/icons-material/Forward5';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, Chip, Grid, Slider, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import shadows from '@common/theme/shadows';
import { getAudioDuration } from '@common/utils/audio';
import { formatDuration } from '@common/utils/time';
import AudioPlayerEmptyState from './AudioPlayerEmptyState';
import InformationAlert from '../alerts/InformationAlert';

AudioPlayer.propTypes = {
    filename: PropTypes.string.isRequired,
    handleDeleteFile: PropTypes.func,
    file: PropTypes.any,
    audioUrl: PropTypes.string,
    audioDuration: PropTypes.number,
    subtitle: PropTypes.string,
    size: PropTypes.string,
    handleDownload: PropTypes.func,
    enablePlaybackRate: PropTypes.bool,
    enableFastForward: PropTypes.bool,
    setCurrentAudioTime: PropTypes.func,
    currentAudioTime: PropTypes.number,
    sx: PropTypes.object
};

export default function AudioPlayer({
    filename,
    handleDeleteFile,
    file,
    audioUrl,
    audioDuration,
    handleDownload,
    subtitle,
    enablePlaybackRate,
    enableFastForward,
    size,
    setCurrentAudioTime,
    currentAudioTime, // In seconds
    sx
}) {
    const { getPlan } = useUser();

    // Using useRef to not remove audio data with each render
    const audioRef = useRef(null);

    const [playing, setPlaying] = useState(false);
    const [timeline, setTimeline] = useState(0);
    const [intervalId, setIntervalId] = useState();
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [audioError, setAudioError] = useState(false);

    // Setup and cleanup of the audio element
    useEffect(() => {
        if (file || audioUrl) {
            const newAudio = new Audio(audioUrl ? audioUrl : URL.createObjectURL(file));

            if (audioUrl) {
                // On files, it throws an error without need
                newAudio.onerror = () => {
                    console.error('Error al cargar el audio. Verifica la URL.');
                    setAudioError('Error al cargar el audio. Refresca la página.'); // Activa el estado de error
                };
            }

            audioRef.current = newAudio;
        }
    }, [file, audioUrl]);

    // Cleanup on unmount
    useEffect(() => {
        // Stop the audio if the component is unmounted
        return () => {
            if (audioRef?.current) {
                handlePause();
                audioRef.current.src = '';
                audioRef.current = null;
            }
        };
    }, []);

    // Sync currentAudioTime with timeline
    useEffect(() => {
        if (setCurrentAudioTime && currentAudioTime) {
            if (currentAudioTime !== timeline && audioRef?.current) {
                // That means user has decide to play a certain intervention
                handleSlideChange(currentAudioTime);
                handlePlay();
            }
        }
    }, [currentAudioTime]);

    const handleChangeAudioTime = (time) => {
        setTimeline(time);
        if (setCurrentAudioTime) {
            // Audio time automically updating
            setCurrentAudioTime(time);
        }
    };

    const handlePlay = () => {
        if (intervalId) clearInterval(intervalId);

        audioRef.current.play();
        setPlaying(true);

        const newInterval = setInterval(() => {
            handleChangeAudioTime(audioRef?.current?.currentTime);
        }, 500);
        setIntervalId(newInterval);
    };

    const handlePause = () => {
        audioRef.current.pause();
        setPlaying(false);
        clearInterval(intervalId);
        setIntervalId(null);
    };

    const handleSlideChange = (newTime) => {
        if (!audioRef.current) return;
        audioRef.current.currentTime = newTime;
        handleChangeAudioTime(newTime);
    };

    const handlePlaybackRateChange = () => {
        if (audioRef?.current) {
            const newRate =
                audioRef.current.playbackRate === 1
                    ? 1.5
                    : audioRef.current.playbackRate === 1.5
                    ? 2
                    : 1;
            audioRef.current.playbackRate = newRate;
        }
        setPlaybackRate(audioRef.current.playbackRate);
    };

    const handleFastForward = () => {
        const newTime = audioRef.current.currentTime + 5;
        audioRef.current.currentTime = newTime;
        handleChangeAudioTime(newTime);
    };

    return (
        <>
            {file || audioUrl ? (
                <Box
                    sx={{
                        ...sx,
                        flexGrow: 0,
                        borderRadius: '8px',
                        backgroundColor: palette.common.white,
                        boxShadow: shadows.elevated,
                        pt: 2,
                        pb: 2,
                        pr: 1,
                        pl: 1
                    }}
                >
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                        width='auto'
                    >
                        {/* Start actions */}
                        <Grid item mr={1}>
                            {playing && timeline < audioDuration ? (
                                <>
                                    <IconButtonWithTooltip
                                        sx={{
                                            borderRadius: '4px',
                                            border: '0px solid'
                                        }}
                                        onClick={handlePause}
                                        tooltipText='Parar'
                                        icon={<PauseRoundedIcon />}
                                    />
                                    {enableFastForward ? (
                                        <IconButtonWithTooltip
                                            sx={{
                                                borderRadius: '4px',
                                                border: '0px solid'
                                            }}
                                            onClick={handleFastForward}
                                            tooltipText='Avanzar 5s'
                                            icon={<Forward5Icon />}
                                        />
                                    ) : null}
                                    {enablePlaybackRate ? (
                                        <Chip
                                            sx={{ ml: 1 }}
                                            label={`x${playbackRate}`}
                                            onClick={handlePlaybackRateChange}
                                            size='small'
                                        />
                                    ) : null}
                                </>
                            ) : (
                                <IconButtonWithTooltip
                                    sx={{
                                        borderRadius: '4px',
                                        border: '0px solid'
                                    }}
                                    onClick={handlePlay}
                                    tooltipText='Reproducir'
                                    icon={<PlayArrowRoundedIcon />}
                                    disabled={audioError}
                                />
                            )}
                        </Grid>

                        {/* Information */}
                        {size !== 'small' && filename ? (
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    ml: playing && (enableFastForward || enablePlaybackRate) ? 1 : 0
                                }}
                                flexGrow={1}
                            >
                                <Typography
                                    component='span'
                                    variant='body2'
                                    color='black'
                                    sx={{ wordBreak: 'break-word' }}
                                >
                                    {/* Over 26 characters, the name broke the UI */}
                                    {filename.length >= 26
                                        ? filename.slice(0, 26) + '...'
                                        : filename}
                                </Typography>
                                {subtitle ? (
                                    <Typography
                                        component='span'
                                        variant='caption'
                                        color={palette.primary[500]}
                                        sx={{ wordBreak: 'break-word' }}
                                    >
                                        {subtitle}
                                    </Typography>
                                ) : null}
                            </Grid>
                        ) : null}

                        {/* Timeline for small audio player */}
                        {size === 'small' ? (
                            <>
                                <Grid item>
                                    <Typography
                                        component='span'
                                        variant='caption'
                                        color={palette.primary[500]}
                                        sx={{ wordBreak: 'break-word', mr: 1 }}
                                    >
                                        {formatDuration(timeline)}
                                    </Typography>
                                </Grid>
                                <Grid item flexGrow={1}>
                                    <Grid
                                        container
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            ml: 0
                                        }}
                                        spacing={0}
                                    >
                                        <Grid item mr={1} flexGrow={1}>
                                            <Box pr={1} pl={1}>
                                                <Slider
                                                    aria-label='time-indicator'
                                                    size='small'
                                                    value={timeline}
                                                    min={0}
                                                    step={0.1}
                                                    max={
                                                        audioDuration
                                                            ? audioDuration
                                                            : getAudioDuration(file)
                                                    }
                                                    onChange={(_, value) =>
                                                        handleSlideChange(value)
                                                    }
                                                    sx={{
                                                        mb: -1,
                                                        color: 'primary',
                                                        height: 4,
                                                        '& .MuiSlider-thumb': {
                                                            width: 4,
                                                            height: 4,
                                                            transition:
                                                                '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                                            '&:before': {
                                                                boxShadow:
                                                                    '0 2px 12px 0 rgba(0,0,0,0.4)'
                                                            },
                                                            '&:hover, &.Mui-focusVisible': {
                                                                width: 8,
                                                                height: 8,
                                                                boxShadow:
                                                                    '0 0px 0px 8px rgba(0,0,0,0.2)'
                                                            }
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            opacity: 0.28
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component='span'
                                        variant='caption'
                                        color={palette.primary[500]}
                                        sx={{ wordBreak: 'break-word' }}
                                    >
                                        {formatDuration(
                                            audioDuration ? audioDuration : getAudioDuration(file)
                                        )}
                                    </Typography>
                                </Grid>
                            </>
                        ) : null}

                        {/* End actions */}
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'right',
                                mr: 1,
                                ml: 1
                            }}
                        >
                            <Stack direction='row'>
                                {/* TODO: Here we can introduce more buttons inline */}
                                {handleDownload ? (
                                    <IconButtonWithTooltip
                                        sx={{
                                            borderRadius: '4px',
                                            border: '0px solid'
                                        }}
                                        onClick={
                                            getPlan().name !== subscriptionPlans.free.name
                                                ? handleDownload
                                                : () => setOpenPremiumDialog(true)
                                        }
                                        tooltipText='Descargar audio'
                                        icon={<FileDownloadOutlinedIcon />}
                                    />
                                ) : null}
                                {handleDeleteFile ? (
                                    <IconButtonWithTooltip
                                        sx={{
                                            borderRadius: '4px',
                                            border: '0px solid'
                                        }}
                                        onClick={handleDeleteFile}
                                        tooltipText='Eliminar audio'
                                        icon={<CloseOutlinedIcon />}
                                    />
                                ) : null}
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* Timeline for standard size audio player */}
                    {size !== 'small' && (
                        <>
                            <Box pr={1} pl={1}>
                                <Slider
                                    aria-label='time-indicator'
                                    size='small'
                                    value={timeline}
                                    min={0}
                                    step={0.1}
                                    max={audioDuration ? audioDuration : getAudioDuration(file)}
                                    onChange={(_, value) => handleSlideChange(value)}
                                    sx={{
                                        mb: -1,
                                        mt: 1,
                                        mr: 2,
                                        color: 'primary',
                                        height: 4,
                                        '& .MuiSlider-thumb': {
                                            width: 4,
                                            height: 4,
                                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                            '&:before': {
                                                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)'
                                            },
                                            '&:hover, &.Mui-focusVisible': {
                                                width: 8,
                                                height: 8,
                                                boxShadow: '0 0px 0px 8px rgba(0,0,0,0.2)'
                                            }
                                        },
                                        '& .MuiSlider-rail': {
                                            opacity: 0.28
                                        }
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    pr: 1,
                                    pl: 1
                                }}
                            >
                                <Typography
                                    component='span'
                                    variant='caption'
                                    color={palette.primary[500]}
                                    sx={{ wordBreak: 'break-word' }}
                                >
                                    {formatDuration(timeline)}
                                </Typography>
                                <Typography
                                    component='span'
                                    variant='caption'
                                    color={palette.primary[500]}
                                    sx={{ wordBreak: 'break-word' }}
                                >
                                    {formatDuration(
                                        audioDuration ? audioDuration : getAudioDuration(file)
                                    )}
                                </Typography>
                            </Box>
                        </>
                    )}

                    {audioError && <InformationAlert message={audioError} type='warning' />}
                </Box>
            ) : (
                <AudioPlayerEmptyState size={size} />
            )}
            <PricingDialog openDialog={openPremiumDialog} setOpenDialog={setOpenPremiumDialog} />
        </>
    );
}
