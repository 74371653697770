import { useRef, useState } from 'react';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Box, Button, Chip, Grid, Stack, Tooltip } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import AudioPlayer from '@common/components/audioPlayer/AudioPlayer';
import CopyIconButton from '@common/components/buttons/CopyIconButton';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import ShareMeetingDialog from '@common/components/dialogs/ShareMeetingDialog';
import IconPhrase from '@common/components/icons/IconPhrase';
import CircularLoader from '@common/components/loaders/CircularLoader';
import IconWithCircularLoader from '@common/components/loaders/IconWithCircularLoading';
import MenuButton from '@common/components/MenuButton';
import TagEditable from '@common/components/tagsComponents/TagEditable';
import { navigation } from '@common/helpers/navigation';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { downloadAudio } from '@common/utils/audio';
import { downloadFileFromBlob } from '@common/utils/downloadFileFromBlob';
import {
    deleteMeetingById,
    submitReportCreation,
    updateMeetingTemplate
} from '@setup/api/meeting/meeting';
import { getAllMeetingInterventions } from '@setup/api/meetingInterventions/meetingInterventions.js';
import { getReportBlocks } from '@setup/api/reportBlocks/reportBlocks.js';
import ParticipantsConfig from './ParticipantConfig';
import TemplatesDialog from './TemplatesDialog';
import ReportPDF from '../Report/ReportPDF';
import TranscriptionPDF from '../Transcription/TranscriptionPDF';

MeetingSideMenuView.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    isShared: PropTypes.bool,
    meetingId: PropTypes.number,
    handleCopyReport: PropTypes.func,
    filename: PropTypes.string.isRequired,
    platform: PropTypes.string,
    audioUrl: PropTypes.string,
    setCurrentAudioTime: PropTypes.func.isRequired,
    currentAudioTime: PropTypes.number.isRequired,
    template: PropTypes.any,
    language: PropTypes.string.isRequired
};

export default function MeetingSideMenuView({
    participants,
    title,
    tag,
    date,
    time,
    template,
    isShared,
    meetingId,
    handleCopyReport,
    filename,
    platform,
    audioUrl,
    duration,
    setCurrentAudioTime,
    currentAudioTime,
    language
}) {
    const navigate = useNavigate();
    const notification = useNotification();
    const { getPlan } = useUser();

    const inputLogoRef = useRef(null);

    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDownloadReportDialog, setOpenDownloadReportDialog] = useState(false);
    const [downloadingTranscription, setDownloadingTranscription] = useState(false);
    const [downloadingAudio, setDownloadingAudio] = useState(false);
    const [openTemplatesDialog, setOpenTemplatesDialog] = useState(false);
    const [localParticipants, setLocalParticipants] = useState([]);
    const [openParticipantsDialog, setOpenParticipantsDialog] = useState(false);

    const handleDownloadAudio = async () => {
        if (getPlan().name === subscriptionPlans.free.name) {
            setOpenPremiumDialog(true);
        } else {
            setDownloadingAudio(true);
            await downloadAudio({
                meetingTitle: title,
                audioFilename: filename
            });
            setDownloadingAudio(false);
        }
    };

    const deleteMeetingHandler = async () => {
        try {
            await deleteMeetingById({ meetingId });
            navigate(`${navigation.app.routes.repository}`);
            notification('deletion-success');
        } catch (err) {
            notification('deletion-failure');
        }
        setOpenDeleteDialog(false);
    };

    const onClickDownloadTranscription = async () => {
        try {
            setDownloadingTranscription(true);
            const { interventions } = await getAllMeetingInterventions({
                meetingId
            });
            const blob = await pdf(
                <TranscriptionPDF
                    interventions={interventions}
                    meetingTitle={title}
                    creationDate={`${date}, ${time}`}
                    participantNames={participants.map((participant) => participant.name)}
                    duration={duration}
                    language={language}
                />
            ).toBlob();

            await downloadFileFromBlob({ blob: blob, filename: title, extension: 'pdf' });
            setDownloadingTranscription(false);
        } catch (error) {
            setDownloadingTranscription(false);
            notification('pdf-failure-donwloaded');
        }
    };

    const onClickDownloadReport = async (logotype) => {
        try {
            const { reportBlocks } = await getReportBlocks({ meetingId });

            const blob = await pdf(
                <ReportPDF
                    blocks={reportBlocks}
                    meetingTitle={title}
                    creationDate={`${date} a las ${time}`}
                    participants={participants.map((participant) => participant.name)}
                    duration={duration}
                    logotype={logotype}
                />
            ).toBlob();

            await downloadFileFromBlob({ blob: blob, filename: title, extension: 'pdf' });
        } catch (error) {
            notification('pdf-failure-donwloaded');
        }
    };

    const handleChangeLogoUpload = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            onClickDownloadReport(e.target.files[0]);
            setOpenDownloadReportDialog(false);
        }
    };

    const handleApplyTemplate = async (templateId) => {
        if (getPlan().name === subscriptionPlans.unlimited.name) {
            try {
                await updateMeetingTemplate({ meetingId, templateId });
                await submitReportCreation(meetingId);
                return true;
            } catch (error) {
                notification('unkown-error');
            }
        } else {
            setOpenPremiumDialog(true);
        }
        return false;
    };

    return (
        <Grid item sx={{ ml: 2 }}>
            {!isShared && (
                <Grid item sx={{ mb: 0 }}>
                    <Stack direction='row' spacing={1} sx={{ mt: 0 }}>
                        <Grid item>
                            <CopyIconButton
                                id='clickCopyReportContent'
                                size='small'
                                onClick={
                                    getPlan().name !== subscriptionPlans.free.name
                                        ? handleCopyReport
                                        : () => setOpenPremiumDialog(true)
                                }
                                tooltipTitle={'Copiar informe'}
                            />
                        </Grid>
                        <ShareMeetingDialog
                            withButton={true}
                            meetingId={meetingId}
                            meetingTitle={title}
                            buttonVariant={'text'}
                        />
                        <ParticipantsConfig
                            participants={participants ?? []}
                            meetingId={meetingId}
                            filename={filename}
                            localParticipants={localParticipants}
                            setLocalParticipants={setLocalParticipants}
                            setOpenDialog={setOpenParticipantsDialog}
                            openDialog={openParticipantsDialog}
                        />
                        <Grid item>
                            <MenuButton
                                iconButton={<MoreHorizOutlinedIcon fontSize='small' />}
                                tooltipText='Opciones'
                                menuList={[
                                    {
                                        itemFunction: () => {
                                            if (getPlan().name === subscriptionPlans.free.name) {
                                                setOpenPremiumDialog(true);
                                                return;
                                            }
                                            setOpenDownloadReportDialog(true);
                                        },
                                        itemIcon: <SummarizeOutlinedIcon fontSize='small' />,
                                        itemText: 'Descargar informe'
                                    },
                                    {
                                        itemFunction: () => onClickDownloadTranscription(),
                                        itemIcon: (
                                            <IconWithCircularLoader
                                                loading={downloadingTranscription}
                                                icon={<DescriptionOutlinedIcon fontSize='small' />}
                                                size={'small'}
                                            />
                                        ),
                                        itemText: 'Descargar transcripción'
                                    },
                                    {
                                        itemFunction: () => handleDownloadAudio(),
                                        itemIcon: (
                                            <IconWithCircularLoader
                                                loading={downloadingAudio}
                                                icon={<AudioFileOutlinedIcon fontSize='small' />}
                                                size={'small'}
                                            />
                                        ),
                                        itemText: 'Descargar audio'
                                    },
                                    {
                                        divider: true
                                    },
                                    {
                                        itemFunction: () => setOpenDeleteDialog(true),
                                        itemIcon: <DeleteOutlineOutlinedIcon fontSize='small' />,
                                        itemText: 'Eliminar reunión'
                                    }
                                ]}
                            />
                        </Grid>
                    </Stack>
                </Grid>
            )}

            <Grid item>
                <TagEditable isShared={isShared} tag={tag} meetingId={meetingId} />
            </Grid>

            {!isShared && (
                <Stack direction='column' spacing={0.5} mt={1}>
                    <Button
                        size='small'
                        color='primary'
                        variant='text'
                        onClick={() => setOpenTemplatesDialog(true)}
                        startIcon={<TableRowsOutlinedIcon color={palette.primary[800]} />}
                        sx={{ maxWidth: 'fit-content' }}
                    >
                        Cambiar plantilla
                    </Button>
                </Stack>
            )}

            <Grid
                item
                sx={{
                    mt: 2,
                    mb: 2
                }}
            >
                {!isShared && (
                    <AudioPlayer
                        filename={title}
                        subtitle={
                            platform
                                ? platform === 'directUpload'
                                    ? 'Subida directa'
                                    : platform
                                : null
                        }
                        audioUrl={audioUrl}
                        audioDuration={duration}
                        enablePlaybackRate={true}
                        setCurrentAudioTime={setCurrentAudioTime}
                        currentAudioTime={currentAudioTime}
                        size='small'
                    />
                )}
            </Grid>
            <Grid
                item
                sx={{
                    borderRadius: '0.5rem 0.5rem 0rem 0rem'
                }}
            >
                <IconPhrase
                    text={`${date} a las ${time}`}
                    icon={
                        <CalendarTodayOutlinedIcon
                            fontSize='small'
                            sx={{ color: palette.primary[300] }}
                        />
                    }
                    color={palette.primary[500]}
                />
            </Grid>
            <Grid
                item
                sx={{
                    mt: 1
                }}
            >
                <IconPhrase
                    text={
                        platform
                            ? platform === 'directUpload'
                                ? 'Audio subido a Voicit'
                                : platform === 'Voicit'
                                ? 'Grabado en Voicit'
                                : platform
                            : null
                    }
                    icon={
                        platform === 'directUpload' ? (
                            <UploadFileOutlinedIcon
                                fontSize='small'
                                sx={{ color: palette.primary[300] }}
                            />
                        ) : platform === 'Voicit' ? (
                            <MicNoneOutlinedIcon
                                fontSize='small'
                                sx={{ color: palette.primary[300] }}
                            />
                        ) : (
                            <VideocamOutlinedIcon
                                fontSize='small'
                                sx={{ color: palette.primary[300] }}
                            />
                        )
                    }
                    color={palette.primary[500]}
                />
            </Grid>
            <Grid item sx={{ mt: 1 }}>
                {localParticipants?.map((participant) => (
                    <Tooltip
                        key={participant.name}
                        title={`Participó un ${Math.round(participant.interventionPercentage)}%`}
                    >
                        <Chip
                            sx={{ flexGrow: 1, mr: 1, mb: 0.5 }}
                            label={participant.name}
                            onClick={() => setOpenParticipantsDialog(true)}
                            color={'chip_grey'}
                            size='small'
                            icon={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularLoader
                                        value={Number(participant.interventionPercentage)}
                                        size={'extrasmall'}
                                        infinite={false}
                                    />
                                </Box>
                            }
                        />
                    </Tooltip>
                ))}
            </Grid>

            <TemplatesDialog
                openDialog={openTemplatesDialog}
                setOpenDialog={setOpenTemplatesDialog}
                appliedTemplate={template}
                handleApplyTemplate={handleApplyTemplate}
            />
            <ConfirmationDialog
                open={openDeleteDialog}
                title='Eliminar reunión'
                message='Estas a punto de eliminar esta reunión, si la eliminas perderás toda su información.'
                onConfirm={deleteMeetingHandler}
                onClose={() => setOpenDeleteDialog(false)}
            />
            {/* Input for logo upload */}
            <input
                ref={inputLogoRef}
                type='file'
                id='input-file-upload'
                multiple={true}
                onChange={handleChangeLogoUpload}
            />
            <ConfirmationDialog
                open={openDownloadReportDialog}
                title='Descargar informe'
                message='Descarga tu informe en PDF, añade tu logo para personalizar el documento.'
                onConfirm={() => inputLogoRef.current.click()}
                onClose={() => {
                    onClickDownloadReport();
                    setOpenDownloadReportDialog(false);
                }}
                confirmButtonText='Descargar'
                closeButtonText='Elegir logo y descargar'
            />
            <PricingDialog openDialog={openPremiumDialog} setOpenDialog={setOpenPremiumDialog} />
        </Grid>
    );
}
