import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import { PropTypes } from 'prop-types';

CheckboxField.propTypes = {
    alertMessage: PropTypes.string,
    checked: PropTypes.bool,
    setChecked: PropTypes.func,
    linkUrl: PropTypes.string
};

export default function CheckboxField({ alertMessage, checked, setChecked, linkUrl }) {
    return (
        <FormControl
            required
            error={alertMessage ? true : false}
            component='fieldset'
            sx={{ mb: 2, mt: 1 }}
            variant='standard'
        >
            <FormControlLabel
                control={<Checkbox checked={checked} />}
                onChange={() => setChecked(!checked)}
                label={
                    <div>
                        <span>Acepto los </span>
                        <Link href={linkUrl} target='_blank'>
                            términos y condiciones del servicio
                        </Link>
                    </div>
                }
            />
            {alertMessage ? <FormHelperText>{alertMessage}</FormHelperText> : null}
        </FormControl>
    );
}
