import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

LoaderIcon.propTypes = {
    size: PropTypes.number | PropTypes.string
};

export default function LoaderIcon({ size }) {
    return <Skeleton variant='rounded' width={size ?? '24px'} height={size ?? '24px'} />;
}
