import {
    BLUE,
    EMERALD,
    GREEN,
    GREY,
    LAUREL,
    OCEAN,
    OLIVE,
    ORGANGE,
    PEACH,
    PINK,
    PRIMARY,
    PURPLE,
    RED,
    WOOD,
    YELLOW
} from '@common/theme/palette/fullPalette';
import { normalizeString } from './strings';
const paletteArray = [
    { letter: 'a', full: BLUE, variant: BLUE[50], chip: 'chip_blue' },
    { letter: 'b', full: EMERALD, variant: EMERALD[50], chip: 'chip_emerald' },
    { letter: 'c', full: GREEN, variant: GREEN[50], chip: 'chip_green' },
    { letter: 'd', full: WOOD, variant: WOOD[200], chip: 'chip_wood' },
    { letter: 'e', full: LAUREL, variant: LAUREL[50], chip: 'chip_laurel' },
    { letter: 'f', full: OCEAN, variant: OCEAN[50], chip: 'chip_ocean' },
    { letter: 'g', full: OLIVE, variant: OLIVE[50], chip: 'chip_olive' },
    { letter: 'h', full: ORGANGE, variant: ORGANGE[50], chip: 'chip_orange' },
    { letter: 'i', full: PEACH, variant: PEACH[50], chip: 'chip_peach' },
    { letter: 'j', full: PINK, variant: PINK[50], chip: 'chip_pink' },
    { letter: 'k', full: PURPLE, variant: PURPLE[50], chip: 'chip_purple' },
    { letter: 'l', full: RED, variant: RED[50], chip: 'chip_red' },
    { letter: 'm', full: WOOD, variant: WOOD[50], chip: 'chip_wood' },
    { letter: 'n', full: YELLOW, variant: YELLOW[50], chip: 'chip_yellow' },
    { letter: 'ñ', full: GREY, variant: GREY[100], chip: 'chip_grey' },
    { letter: 'o', full: BLUE, variant: BLUE[200], chip: 'chip_blue' },
    { letter: 'p', full: EMERALD, variant: EMERALD[200], chip: 'chip_emerald' },
    { letter: 'q', full: GREEN, variant: GREEN[200], chip: 'chip_green' },
    { letter: 'r', full: OCEAN, variant: OCEAN[200], chip: 'chip_ocean' },
    { letter: 's', full: LAUREL, variant: LAUREL[200], chip: 'chip_laurel' },
    { letter: 't', full: YELLOW, variant: YELLOW[200], chip: 'chip_yellow' },
    { letter: 'u', full: OLIVE, variant: OLIVE[200], chip: 'chip_olive' },
    { letter: 'v', full: ORGANGE, variant: ORGANGE[200], chip: 'chip_orange' },
    { letter: 'w', full: PEACH, variant: PEACH[200], chip: 'chip_peach' },
    { letter: 'x', full: PINK, variant: PINK[200], chip: 'chip_pink' },
    { letter: 'y', full: PURPLE, variant: PURPLE[200], chip: 'chip_purple' },
    { letter: 'z', full: RED, variant: RED[200], chip: 'chip_red' },
    { letter: '', full: PRIMARY, variant: PRIMARY[100], chip: 'chip_grey' }
]; // 27 colors

export const getColorFromLetter = (text) => {
    let firstLetter = text ? normalizeString(text.charAt(0)) : '';
    let colorReference = paletteArray.find((color) => color.letter == firstLetter);
    return colorReference ? colorReference : paletteArray.find((color) => color.letter == '');
};
