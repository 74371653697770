import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ClickAwayListener, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Avatar from '@common/components/avatars/Avatar';
import RoleSelector from '@common/components/participants/RoleSelector';
import TurnSelector from '@common/components/participants/TurnSelector';
import palette from '@common/theme/palette/palette';
import { getParticipantTurnByName, getRoleIdByParticipant } from './utils';
import VoiceSelector from './VoiceSelector';
import IconButtonWithTooltip from '../buttons/IconButtonWithTooltip';
import CircularLoader from '../loaders/CircularLoader';

EditableParticipant.propTypes = {
    participant: PropTypes.object,
    setParticipants: PropTypes.func.isRequired,
    participants: PropTypes.any,
    availableTurns: PropTypes.number,
    editableName: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.any),
    rolesByCategory: PropTypes.arrayOf(PropTypes.any),
    numberOfSpeakers: PropTypes.number,
    speakersIntervention: PropTypes.arrayOf(PropTypes.any),
    audioUrl: PropTypes.string,
    handleVoiceChange: PropTypes.func
};

export default function EditableParticipant({
    participant,
    participants,
    setParticipants,
    availableTurns,
    editableName,
    roles,
    rolesByCategory,
    numberOfSpeakers,
    speakersIntervention,
    audioUrl,
    handleVoiceChange
}) {
    const user = useSelector((state) => state.auth.authUser);
    const givenName = user && user.givenName;
    const avatarUrl = user && user.avatarUrl;

    const [turn, setTurn] = useState(
        participants && participants !== null ? getParticipantTurnByName(participants, name) : null
    );
    const [roleId, setRoleId] = useState(
        participants && participants !== null ? getRoleIdByParticipant(participants, name) : null
    );
    const [currentName, setCurrentName] = useState(participant.name);
    const [editableNameContent, setEditableNameContent] = useState(participant.name);
    const [nameClick, setNameClick] = useState(false);

    const handleRoleChange = async (newRole) => {
        // TODO: we cannot allow the creation of the meeting if role is selected but no the turns
        setRoleId(!newRole ? null : newRole.id);
        const participantIndex = participants
            ? participants.findIndex((p) => p.name === currentName)
            : false;
        const updatedParticipants = [...participants];
        updatedParticipants[participantIndex] = {
            ...updatedParticipants[participantIndex],
            roleId: !newRole ? null : newRole.id,
            focus: !newRole ? false : newRole.isDefaultFocused
        };
        setParticipants(updatedParticipants);
    };

    const handleNameChange = ({ newName, oldName }) => {
        const participantIndex = participants
            ? participants.findIndex((p) => p.name === oldName)
            : false;
        const updatedParticipants = [...participants];
        updatedParticipants[participantIndex] = {
            ...updatedParticipants[participantIndex],
            name: newName
        };
        setCurrentName(newName);
        setParticipants(updatedParticipants);
    };

    const handleNameClickAway = () => {
        if (
            nameClick &&
            !participants.some((s) => Object.values(s).includes(editableNameContent))
        ) {
            handleNameChange({
                newName: editableNameContent,
                oldName: currentName
            });
            setNameClick(false);
        }
    };

    const handleSaveNameWithEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleNameChange({
                newName: editableNameContent,
                oldName: currentName
            });
            event.target.blur();
            setNameClick(false);
        }
    };

    const handleTurnChange = async ({ newTurn }) => {
        setTurn(newTurn);
        const participantIndex = participants
            ? participants.findIndex((p) => p.name === currentName)
            : false;
        const updatedSpeakers = participants;
        updatedSpeakers[participantIndex].turn = newTurn;
        setParticipants(updatedSpeakers);
    };

    const handleDeleteParticipant = async (participantName) => {
        const updatedParticipants = [...participants];
        if (!numberOfSpeakers) {
            // Looking for the participant with the last turn to update it. This turns doesn´t exist anymore. Not apply if existing speakers.
            const participantIndex = participants.findIndex(
                (participant) => participant.turn === participants.length - 1
            );
            if (participantIndex !== -1) {
                updatedParticipants[participantIndex].turn = null;
            }
        }
        setParticipants(updatedParticipants.filter((p) => p.name !== participantName));
    };

    useEffect(() => {
        setTurn(
            participants && participants !== null
                ? getParticipantTurnByName(participants, participant.name)
                : null
        );
        setRoleId(
            participants && participants !== null
                ? getRoleIdByParticipant(participants, participant.name)
                : null
        );
        setCurrentName(participant.name);
        setEditableNameContent(participant.name);
    }, [participants]);

    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                width: '100%',
                mt: 2
            }}
        >
            <Grid item flexGrow={1}>
                <Grid
                    container
                    spacing={0}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        ml: 0
                    }}
                >
                    <Grid item>
                        <Avatar
                            size={'medium'}
                            avatarName={currentName}
                            avatarImage={currentName === givenName ? avatarUrl : null}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                            ml: 1
                        }}
                    >
                        {editableName ? (
                            <ClickAwayListener onClickAway={handleNameClickAway}>
                                <Tooltip title={'Doble clic para editar'}>
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        color='black'
                                        contentEditable={nameClick}
                                        onClick={() => setNameClick(true)}
                                        onInput={(e) => setEditableNameContent(e.target.innerText)}
                                        onKeyDown={handleSaveNameWithEnter}
                                        sx={{
                                            ':hover': {
                                                cursor: 'pointer',
                                                color: palette.primary[500]
                                            },
                                            ':focus': {
                                                outline: 'none',
                                                color: palette.primary[500],
                                                pl: 1
                                            },
                                            width: 170
                                        }}
                                    >
                                        {participant.name}
                                    </Typography>
                                </Tooltip>
                            </ClickAwayListener>
                        ) : (
                            <Typography component='span' variant='body2' color='black'>
                                {participant.name}
                            </Typography>
                        )}

                        {participant.interventionPercentage &&
                            Math.round(participant.interventionPercentage) !== 100 && (
                                <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                    <CircularLoader
                                        value={participant.interventionPercentage}
                                        size={'extrasmall'}
                                        infinite={false}
                                    />
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        color={palette.primary[600]}
                                    >
                                        Participó un{' '}
                                        {Math.round(participant.interventionPercentage)}%
                                    </Typography>
                                </Stack>
                            )}

                        {/* <Typography
                            component='span'
                            variant='caption'
                            color={palette.secondary.light}
                        >
                            {turn >= 0
                                ? `${selectorTurns[turn]} en intervenir`
                                : 'Turno sin asignar'}
                        </Typography> */}
                    </Grid>
                </Grid>
            </Grid>
            {speakersIntervention && (
                <Grid item>
                    <VoiceSelector
                        turn={turn}
                        onVoiceChange={handleVoiceChange}
                        participants={participants}
                        speakersIntervention={speakersIntervention}
                        audioUrl={audioUrl}
                        participant={participant}
                    />
                </Grid>
            )}
            {availableTurns && (
                <Grid item>
                    <TurnSelector
                        turn={turn}
                        onTurnChange={handleTurnChange}
                        participants={participants}
                        availableTurns={availableTurns}
                    />
                </Grid>
            )}

            {editableName && (
                <Grid item>
                    <RoleSelector
                        currentRoleId={roleId}
                        onRoleChange={handleRoleChange}
                        rolesByCategory={rolesByCategory}
                        roles={roles}
                    />
                </Grid>
            )}

            <Grid
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}
            >
                <IconButtonWithTooltip
                    sx={{
                        borderRadius: '4px',
                        border: '0px solid'
                    }}
                    onClick={() => handleDeleteParticipant(currentName)}
                    icon={<CloseOutlinedIcon fontSize='small' />}
                    tooltipText='Eliminar'
                    size='small'
                />
            </Grid>
        </Grid>
    );
}
