import React from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';
import { fromArrayTimeToString, fromSecondsToArrayTime } from '@common/utils/time';

export default function DurationField({ currentDuration }) {
    const arrTime = fromSecondsToArrayTime(currentDuration);
    const durationString = fromArrayTimeToString(arrTime);

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}
            >
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1} sx={{ direction: 'row', alignItems: 'center' }}>
                        <Grid item>
                            <AccessTimeOutlinedIcon
                                fontSize='small'
                                sx={{ color: palette.primary[300] }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                component='span'
                                variant='body2'
                                color={palette.primary[300]}
                            >
                                Duración
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {durationString}
                </Grid>
            </Grid>
        </>
    );
}

DurationField.propTypes = {
    currentDuration: PropTypes.any
};
