import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { navigation } from '@common/helpers/navigation';
import { subscriptionPlans, userRoles } from '@common/helpers/user';
import { getImportedMeetings } from '@setup/api/meeting/meeting';
import { getUser } from '@setup/api/user';
import { setAuthToken } from '@setup/redux/reducers/authSlice';
import { setMonthlyMeetings, setTranscriptionMinutes } from '@setup/redux/reducers/statsSlice';
import {
    setUserAvatarUrl,
    setUserCreatedAt,
    setUserEmail,
    setUserGivenName,
    setUserPlan,
    setUserRole,
    setUserUseCase
} from '@setup/redux/reducers/userSlice';

export default function useUser() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const monthlyMeetings = useSelector((state) => state.stats.monthlyMeetings);
    const plan = useSelector((state) => state.user.userPlan);
    const userRole = useSelector((state) => state.user.userPlanId);

    const [userIsRunOutOfMinutes, setUserIsRunOutOfMinutes] = useState();
    const [usageMinutes, setUsageMinutes] = useState(0);

    const dispatchAsync = (action) =>
        new Promise((resolve) => {
            dispatch(action);
            resolve();
        });

    const handleUpdateReduxUserData = async (data) => {
        const promises = [];
        if (data.authToken) promises.push(dispatchAsync(setAuthToken(data.authToken)));
        promises.push(dispatchAsync(setUserGivenName(data.givenName)));
        promises.push(dispatchAsync(setUserEmail(data.email)));
        promises.push(dispatchAsync(setUserAvatarUrl(data.avatarUrl)));
        promises.push(dispatchAsync(setUserRole(data.role)));
        promises.push(dispatchAsync(setUserCreatedAt(data.createdAt)));
        promises.push(dispatchAsync(setUserPlan(data.subscriptionPlan)));
        promises.push(dispatchAsync(setUserUseCase(data.useCase)));
        await Promise.all(promises);
    };

    const handleUpdateReduxUserStats = async (data) => {
        setUsageMinutes(data.transcriptionSeconds / 60);
        await Promise.all([
            dispatchAsync(setTranscriptionMinutes(Math.round(data.transcriptionSeconds / 60))),
            dispatchAsync(setMonthlyMeetings(data.monthlyMeetings))
        ]);
    };

    const handleSignInDataAndRedirection = async (data, urlParams) => {
        // urlParams is used for detecting sign from extension
        await handleUpdateReduxUserData(data);
        await handleUpdateReduxUserStats(data);

        // Redirection
        const newUrl = `/${urlParams}`;
        if (location.hash === navigation.extension.hash.signin) {
            // Navigate and reload to load the signing extension script
            window.location.href = newUrl;
        } else {
            navigate(newUrl, { replace: true });
        }
    };

    const handleUpdateUserData = async (token) => {
        const userData = await getUser(token);

        handleUpdateReduxUserData(userData);
        handleUpdateReduxUserStats(userData.stats);
    };

    const handleUserIsRunOutOfMeetings = () => {
        return (
            plan.planName !== subscriptionPlans.unlimited.name &&
            monthlyMeetings >= plan.monthlyMeetings
        );
    };

    const handleUserIsRunOutOfImports = async () => {
        const imports = await getImportedMeetings();
        return (
            plan.planName !== subscriptionPlans.unlimited.name &&
            imports.count >= plan.monthlyDirectUploads
        );
    };

    const handleUserIsRunOutOfMinutes = async () => {
        return (
            plan?.planName !== subscriptionPlans.unlimited.name &&
            usageMinutes >= plan?.monthlyUsageMinutes
        );
    };

    const getPlan = () => {
        if (plan) {
            if (userRole === userRoles.admin) {
                return subscriptionPlans.unlimited;
            } else {
                return Object.values(subscriptionPlans).find(
                    (subscriptionPlan) => subscriptionPlan.name === plan?.planName
                );
            }
        } else {
            return null;
        }
    };

    useEffect(() => {
        (async () => {
            setUserIsRunOutOfMinutes(await handleUserIsRunOutOfMinutes());
        })();
    }, []);

    return {
        handleSignInDataAndRedirection,
        handleUpdateUserData,
        handleUserIsRunOutOfMeetings,
        handleUserIsRunOutOfImports,
        getPlan,
        userIsRunOutOfMinutes
    };
}
