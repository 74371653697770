import React from 'react';
import { Chip, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';
import '../components/Report/reportBlockStyles.css';
import { getTimeMeetingIntervention, timeKeyToSeconds } from '@common/utils/time';

export default function MeetingNotes({ meetingNotes, setCurrentAudioTime }) {
    const isValidURL = (string) => {
        const res = string.match(/(https?:\/\/[^\s]+|www\.[^\s]+)/g);
        return res !== null;
    };

    const handleClick = (event, note) => {
        event.preventDefault(); // Prevent the default anchor behavior if needed
        setCurrentAudioTime(timeKeyToSeconds(note.timeKey));
    };

    return (
        <Grid container direction='column' id='notes'>
            <Grid item sx={{ mb: 1 }}>
                <Divider>
                    <Chip label='Notas' size='small' />
                </Divider>
                {/* <Typography variant='h5'>Notas</Typography> */}
            </Grid>
            {meetingNotes.map((note) => (
                <Grid
                    container
                    sx={{
                        borderRadius: 2,
                        border: `1px solid ${palette.primary[50]}`,
                        //backgroundColor: palette.primary[50],
                        p: 2,
                        mt: 2
                    }}
                    key={note.timeKey}
                >
                    {note.content && (
                        <Grid item mb={1}>
                            {isValidURL(note.content) ? (
                                <Typography color={palette.primary.main} variant='body1'>
                                    <a
                                        href={
                                            note.content.startsWith('http')
                                                ? note.content
                                                : `http://${note.content}`
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{ color: palette.primary.main }}
                                    >
                                        {note.content}
                                    </a>
                                </Typography>
                            ) : (
                                <Typography color={palette.primary.main} variant='body1'>
                                    {note.content}
                                </Typography>
                            )}
                        </Grid>
                    )}

                    <Grid item>
                        <Typography
                            color={palette.primary[500]}
                            variant='body2'
                            dangerouslySetInnerHTML={{ __html: note.shortSummary }}
                        />
                    </Grid>
                    <Grid item onClick={(event) => handleClick(event, note)} mt={1}>
                        <Typography color={palette.primary.main} variant='caption'>
                            <a href='#' className='time-ref' data-time-key={note.timeKey}>
                                {getTimeMeetingIntervention({
                                    timeKey: note.timeKey,
                                    hoursDisabled: true
                                })}
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

MeetingNotes.propTypes = {
    meetingNotes: PropTypes.arrayOf(PropTypes.any).isRequired,
    setCurrentAudioTime: PropTypes.function
};
