import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

export default function CircularIconLoader() {
    return (
        <Stack sx={{ color: 'grey.500', p: 0.25 }} spacing={0} direction='row'>
            <CircularProgress color='primary' size='1rem' />
        </Stack>
    );
}
