import { v4 as uuidv4 } from 'uuid';
import { authApiCall, endpoints } from '.';

export async function getWriteSignedUrl({ filename, filetype }) {
    try {
        const res = await authApiCall({
            method: 'POST',
            endpoint: endpoints.gcs,
            path: '/write-url',
            data: { filename, filetype }
        });

        return res.data.url;
    } catch (error) {
        throw new Error(error);
    }
}

export async function getAudioBlob({ filename, allowExportInformation, avoidFormatSuffix }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.gcs,
        path: '/read-url-client',
        data: {
            filename: `recordings/${filename}${avoidFormatSuffix ? '' : '.mp3'}`,
            allowExportInformation
        }
    });

    const resp = await fetch(data.url, {
        method: 'GET'
    });

    const audioBlob = await resp.blob();

    return {
        audioBlob
    };
}

export async function getReadSignedAudioURL({ filename, removeType }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.gcs,
        path: '/read-url-client',
        // allowExportInformation is a way to skip backend auth
        data: {
            filename: `recordings/${filename}${removeType !== true ? '.mp3' : ''}`,
            allowExportInformation: true
        }
    });

    return data.url;
}

export async function uploadToGCS({ signedUrl, file }) {
    return await fetch(signedUrl, {
        body: file,
        method: 'PUT'
    });
}

export async function uploadAudioToGCS({ file }) {
    try {
        const filename = uuidv4();
        const signedUrl = await getWriteSignedUrl({
            filename: `recordings/${filename}`,
            filetype: file.type || 'unknown'
        });
        await uploadToGCS({ signedUrl, file });
        return filename;
    } catch (error) {
        throw new Error(error);
    }
}
