import React, { forwardRef, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import ItemsSelector from '@common/components/form/ItemsSelector';
import Loader from '@common/components/loaders/Loader';
import SummaryMockup from '@common/components/SummaryMockup';
import { navigation } from '@common/helpers/navigation';
import { DEFAULT_TEMPLATE_SECTIONS } from '@common/helpers/templates/defaultTemplateSections';
import predefinedTemplates from '@common/helpers/templates/predefinedTemplates';
import {
    getAllReportTemplates,
    getReportTemplateById
} from '@setup/api/reportTemplates/reportTemplates';
import { getSectionById } from '@setup/api/section/sections';

TemplatesDialog.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    appliedTemplate: PropTypes.any,
    handleApplyTemplate: PropTypes.func.isRequired
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function TemplatesDialog({
    openDialog,
    setOpenDialog,
    appliedTemplate,
    handleApplyTemplate
}) {
    const navigate = useNavigate();

    const [currentTemplateId, setCurrentTemplateId] = useState(null);
    const [selectedTemplateIds, setSelectedTemplateIds] = useState([]); // It will be always just one item
    const [templates, setTemplates] = useState([]);
    const [lastTemplate, setLastTemplate] = useState(
        templates.find((template) => template.id === template)
    );
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [applyInfoScreen, setApplyInfoScreen] = useState(false);

    useEffect(() => {
        if (currentTemplateId) {
            if (typeof currentTemplateId === 'number') {
                setLastTemplate(templates.find((template) => template.id === currentTemplateId));
            } else {
                setLastTemplate(
                    predefinedTemplates.find((template) => template.id === currentTemplateId)
                );
            }
        }
    }, [currentTemplateId]);

    useEffect(() => {
        if (openDialog) {
            setLoadingTemplates(true);
            (async () => {
                const { data } = await getAllReportTemplates();
                const orderedTemplates = [...data.userTemplates].sort(
                    (currentTemplate, nextTemplate) => nextTemplate?.name - currentTemplate?.name
                );

                if (appliedTemplate) {
                    const appliedTemplateSections = await getTemplateSections(appliedTemplate.id);
                    const usedTemplate = {
                        ...appliedTemplate,
                        info: 'En uso',
                        sections: appliedTemplateSections
                    };
                    const appliedTemplateIndex = templates.findIndex(
                        (template) => template.id === appliedTemplate.id
                    );
                    orderedTemplates[appliedTemplateIndex] = usedTemplate;

                    setSelectedTemplateIds([usedTemplate.id]);
                    setLastTemplate(usedTemplate);
                } else {
                    setLastTemplate(predefinedTemplates[6]);
                }
                setTemplates(orderedTemplates);
                setLoadingTemplates(false);
            })();
        }
    }, [openDialog]);

    useEffect(() => {
        if (selectedTemplateIds.length > 0)
            (async () => {
                const selectedTemplateIndex = templates.findIndex(
                    (template) => template.id === selectedTemplateIds[0]
                );

                if (!templates[selectedTemplateIndex].sections) {
                    setLoadingTemplates(true);
                    const updatedTemplates = [...templates];
                    const selectedTemplateSections = await getTemplateSections(
                        selectedTemplateIds[0]
                    );

                    const usedTemplate = {
                        ...templates[selectedTemplateIndex],
                        info:
                            updatedTemplates[selectedTemplateIndex].id === appliedTemplate?.id
                                ? 'En uso'
                                : '',
                        sections: selectedTemplateSections
                    };
                    updatedTemplates[selectedTemplateIndex] = usedTemplate;
                    setTemplates(updatedTemplates);
                    setLastTemplate(usedTemplate);
                    setLoadingTemplates(false);
                }
            })();
    }, [selectedTemplateIds]);

    const getTemplateSections = async (templateId) => {
        try {
            const sections = [];

            const { data: templateData } = await getReportTemplateById({
                reportTemplateId: templateId
            });

            for (let n = 0; n < templateData.sections.length; n++) {
                if (templateData.sections[n].type === 'personalized') {
                    const section = await getSectionById(templateData.sections[n].sectionId);
                    sections.push({ ...templateData.sections[n], title: section.name });
                } else {
                    sections.push({
                        ...templateData.sections[n],
                        title: Object.values(DEFAULT_TEMPLATE_SECTIONS).find(
                            (section) => section.type === templateData.sections[n].type
                        ).title
                    });
                }
            }
            return sections;
        } catch (error) {
            console.log('Error retrieving template section');
        }
    };

    const handleClose = () => {
        setCurrentTemplateId(null);
        setSelectedTemplateIds([]);
        setTemplates([]);
        setLastTemplate(templates.find((template) => template.id === template));
        setLoadingTemplates(false);
        setApplyInfoScreen(false);
        setOpenDialog(false);
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            TransitionComponent={Transition}
            maxWidth={'md'}
            fullWidth={true}
            sx={{
                '& .MuiDialog-paper': {
                    height: '90vh', // Set max height to 90% of the viewport height
                    overflow: 'auto' // Enable scrolling if content exceeds this height
                }
            }}
        >
            <DialogTitle>
                Librería de plantillas de informes
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {applyInfoScreen ? (
                    <Loader
                        minWidth={'20vh'}
                        text={`⚡Tu informe estará listo en unos pocos minutos.`}
                        caption={'Te avisaremos por correo.'}
                    />
                ) : (
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        justifyContent='space-evenly'
                        mt={4}
                    >
                        <Grid
                            item
                            xs={5}
                            sx={{
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <ItemsSelector
                                multipleSelection={false}
                                selectedItemsIds={selectedTemplateIds}
                                setSelectedItemsIds={setSelectedTemplateIds}
                                setCurrentItem={setCurrentTemplateId}
                                items={templates?.filter((template) => template.id !== 'other')}
                                spacing={0.5}
                                direction='column'
                                loading={loadingTemplates}
                                avatarTemplateName={true}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={7}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: -3
                            }}
                        >
                            <SummaryMockup
                                title={lastTemplate?.name}
                                sections={lastTemplate?.sections ?? []}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            {!loadingTemplates && (
                <DialogActions>
                    <Button
                        color='primary'
                        onClick={() => navigate(navigation.app.routes.newTemplate)}
                    >
                        Crear nueva
                    </Button>
                    {selectedTemplateIds.length > 0 && (
                        <Button
                            color='primary'
                            onClick={() =>
                                navigate(
                                    navigation.app.routes.editTemplate +
                                        `/${selectedTemplateIds[0]}`
                                )
                            }
                        >
                            Editar
                        </Button>
                    )}

                    <Button
                        onClick={async () => {
                            const result = await handleApplyTemplate(selectedTemplateIds[0]);
                            if (result) {
                                setApplyInfoScreen(true);
                                setTimeout(() => {
                                    navigate(navigation.app.routes.repository);
                                }, 3000);
                            }
                        }}
                        variant='contained'
                        disableElevation={true}
                    >
                        Aplicar y reprocesar
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
