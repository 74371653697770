import React, { forwardRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slide,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import palette from '@common/theme/palette/palette';
import Badge from '../Badge';
import IconButtonWithTooltip from '../buttons/IconButtonWithTooltip';
import Tooltip from '../Tooltip';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

DialogButton.propTypes = {
    withButton: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonRest: PropTypes.object,
    buttonIcon: PropTypes.node,
    dialogTitle: PropTypes.string,
    dialogText: PropTypes.string,
    onClose: PropTypes.func,
    onSuccessOnClose: PropTypes.func,
    newName: PropTypes.func,
    onSuccess: PropTypes.func,
    successButtonText: PropTypes.string,
    successButtonLoadingText: PropTypes.string,
    loading: PropTypes.bool,
    openDialog: PropTypes.bool,
    children: PropTypes.node,
    buttonVariant: PropTypes.string,
    buttonSize: PropTypes.string,
    iconButton: PropTypes.bool,
    disableActions: PropTypes.bool,
    iconButtonTooltip: PropTypes.string,
    maxWidth: PropTypes.string,
    buttonColor: PropTypes.string,
    fullWidthButton: PropTypes.bool,
    id: PropTypes.string,
    successButtonId: PropTypes.string,
    dialogIcon: PropTypes.node,
    isPremiumFeat: PropTypes.bool,
    buttonTooltipImage: PropTypes.string,
    buttonTooltipText: PropTypes.string,
    buttonTooltipTitle: PropTypes.string,
    badge: PropTypes.bool,
    disableSuccessButton: PropTypes.bool,
    setDialogOpened: PropTypes.func
};

export default function DialogButton(props) {
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [localLoading, setLocalLoading] = useState(props.loading ? props.loading : false);
    const [error, setError] = useState('');

    const handleClickOpen = () => {
        setError('');
        if (props.isPremiumFeat) {
            setOpenPremiumDialog(true);
        } else {
            setOpen(true);
            if (props.setDialogOpened) {
                props.setDialogOpened(true);
            }
        }
    };

    const handleClose = ({ closeOnSuccess }) => {
        if (props.isPremiumFeat) {
            setOpenPremiumDialog(true);
        } else {
            if (closeOnSuccess) {
                if (props.onSuccessOnClose) {
                    props.onSuccessOnClose();
                } else {
                    props.onClose();
                }
            } else if (props.onClose) {
                props.onClose();
            }
            if (props.setDialogOpened) {
                props.setDialogOpened(true);
            }
            setOpen(false);
        }
    };

    const handleOnSuccess = async () => {
        try {
            setLocalLoading(props.loading ? props.loading : true);
            if (props.onSuccess) {
                const result = await props.onSuccess();
                if (result) {
                    handleClose({ closeOnSuccess: true });
                }
            } else {
                handleClose();
            }
            setLocalLoading(props.loading ? props.loading : false);
        } catch (e) {
            setLocalLoading(false);
            if (e && e.response && e.response.data && e.response.data.message) {
                setError(e.response.data.message);
                return;
            }
            setError('Ha ocurrido un error inesperado');
        }
    };

    return (
        <div>
            {props.withButton ? (
                <>
                    {props.iconButton ? (
                        <Badge
                            invisible={!props.badge}
                            color={palette.secondary.main}
                            borderColor={palette.secondary[600]}
                            topPosition={'right'}
                            blinking={true}
                        >
                            <IconButtonWithTooltip
                                id={props.id}
                                icon={props.buttonIcon}
                                onClick={handleClickOpen}
                                tooltipText={props.iconButtonTooltip}
                                sx={{
                                    borderRadius: '4px',
                                    border: '0px solid'
                                }}
                            />
                        </Badge>
                    ) : (
                        <>
                            {props.buttonTooltipImage ||
                            props.buttonTooltipText ||
                            props.buttonTooltipTitle ? (
                                <Tooltip
                                    image={props.buttonTooltipImage}
                                    text={props.buttonTooltipText}
                                    title={props.buttonTooltipTitle}
                                    placement={'bottom'}
                                >
                                    <Button
                                        id={props.id}
                                        {...props.buttonRest}
                                        variant={
                                            props.buttonVariant ? props.buttonVariant : 'contained'
                                        }
                                        color={props.buttonColor ? props.buttonColor : 'primary'}
                                        size={props.buttonSize ? props.buttonSize : 'medium'}
                                        endIcon={props.buttonIcon}
                                        disableElevation={true}
                                        onClick={handleClickOpen}
                                        fullWidth={props.fullWidthButton ? true : false}
                                    >
                                        {props.buttonText}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Button
                                    id={props.id}
                                    {...props.buttonRest}
                                    variant={
                                        props.buttonVariant ? props.buttonVariant : 'contained'
                                    }
                                    color={props.buttonColor ? props.buttonColor : 'primary'}
                                    size={props.buttonSize ? props.buttonSize : 'medium'}
                                    endIcon={props.buttonIcon}
                                    disableElevation={true}
                                    onClick={handleClickOpen}
                                    fullWidth={props.fullWidthButton ? true : false}
                                >
                                    {props.buttonText}
                                </Button>
                            )}
                        </>
                    )}
                </>
            ) : (
                <> {/* Dialog open directly from other source */} </>
            )}
            {(open && !props.isPremiumFeat) || (props.openDialog && !props.isPremiumFeat) ? (
                <Dialog
                    open={open || props.openDialog}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    maxWidth={props.maxWidth ? props.maxWidth : undefined}
                    fullWidth={props.maxWidth ? true : false}
                >
                    <DialogTitle>
                        {props.dialogIcon}
                        {props.dialogTitle}
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500]
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText color={palette.primary[500]} sx={{ mb: 1 }}>
                            {props.dialogText}
                        </DialogContentText>
                        {/* Here is where we introduce the main actions of the dialogue */}
                        {props.children}
                        <Typography variant='subtitle2' style={{ color: '#F26767' }}>
                            {error}
                        </Typography>
                    </DialogContent>
                    {!props.disableActions && (
                        <DialogActions>
                            <Button color='primary' onClick={handleClose}>
                                Cancelar
                            </Button>
                            {localLoading ? (
                                <LoadingButton
                                    loading
                                    loadingPosition='start'
                                    startIcon={<SaveIcon />}
                                >
                                    {props.successButtonLoadingText}
                                </LoadingButton>
                            ) : (
                                <Button
                                    id={props.successButtonId}
                                    onClick={handleOnSuccess}
                                    variant='contained'
                                    disableElevation={true}
                                    disabled={props.disableSuccessButton}
                                >
                                    {props.successButtonText}
                                </Button>
                            )}
                        </DialogActions>
                    )}
                </Dialog>
            ) : (
                <> {/* Avoiding unnecessary renders */} </>
            )}
            {props.isPremiumFeat ? (
                <PricingDialog
                    openDialog={openPremiumDialog}
                    setOpenDialog={setOpenPremiumDialog}
                />
            ) : null}
        </div>
    );
}
