import React, { useEffect, useRef, useState } from 'react';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PropTypes from 'prop-types';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import './styles.css';
import { timeKeyToSeconds } from '@common/utils/time';

InterventionAudioPlayer.propTypes = {
    audioUrl: PropTypes.string.isRequired,
    timeKey1: PropTypes.number.isRequired,
    timeKey2: PropTypes.number.isRequired,
    setIsBeingReproduced: PropTypes.func.isRequired,
    stop: PropTypes.bool
};

export default function InterventionAudioPlayer({
    audioUrl,
    timeKey1,
    timeKey2,
    setIsBeingReproduced,
    stop
}) {
    // Using useRef to not remove audio data with each render
    const audioRef = useRef(new Audio(audioUrl));

    const [playing, setPlaying] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    // Setup and cleanup of the audio element
    useEffect(() => {
        audioRef.current = new Audio(audioUrl);

        // Cleanup on unmount
        return () => {
            if (audioRef?.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
        };
    }, [audioUrl]);

    // Cleanup on unmount
    useEffect(() => {
        // Stop the audio if the component is unmounted
        if (stop && audioRef?.current) {
            handlePause();
        }
    }, [stop]);

    const handlePlay = () => {
        const startTime = timeKeyToSeconds(timeKey1);
        const endTime = timeKeyToSeconds(timeKey2);

        audioRef.current.currentTime = startTime;
        audioRef.current.play();

        setPlaying(true);
        setIsBeingReproduced(true);
        const timeout = setTimeout(() => {
            handlePause();
        }, endTime * 1000);
        setTimeoutId(timeout);
    };

    const handlePause = () => {
        if (!audioRef.current) return;

        audioRef.current.pause();
        setIsBeingReproduced(false);
        setPlaying(false);
        clearTimeout(timeoutId);
    };

    return (
        <IconButtonWithTooltip
            id={'icon-player'}
            tooltipText={playing ? 'Parar' : 'Escuchar'}
            icon={
                playing ? (
                    <PauseRoundedIcon fontSize='small' />
                ) : (
                    <PlayArrowRoundedIcon fontSize='small' />
                )
            }
            onClick={playing ? handlePause : handlePlay}
            sx={{
                //backgroundColor: palette.primary.main,
                borderRadius: 10
            }}
        />
    );
}
