import { urls } from '../urls';

export const meetingNotification = [
    {
        type: 'meeting-success',
        message: 'Tu reunión ha comenzado a procesarse.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'meeting-failure',
        message: 'Ha ocurrido un error creando la reunión, intentalo de nuevo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'meeting-standBy-failure',
        message:
            'Ha ocurrido un error creando la reunión. Hemos descargado el audio. Súbelo manualmente.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'audio-failure',
        message:
            'Ha ocurrido un error procesando tu audio. Esto puede deberse a que el archivo no es compatible o está dañado.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'audio-failure-ext',
        message:
            'Ha ocurrido un error procesando tu audio. Vuelve a la extensión para descargarlo. Súbelo manualmente.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'audio-failure-upload',
        message: 'Ha ocurrido un error guardando tu audio. Se ha descargado en tu ordenador.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'deletion-success',
        message: 'Tu reunión se ha eliminado.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'deletion-failure',
        message: 'Ha ocurrido un error borrando la reunión, intentalo de nuevo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'transcription-processing',
        message: 'Hemos comenzado a procesar tu reunión.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'summary-failure',
        message:
            'Ha ocurrido un error creando tu resumen, intentalo de nuevo o contacta con soporte.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'pdf-failure-donwloaded',
        message: 'Error descargando PDF de tu conversación. Prueba de nuevo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'audio-success-donwloaded',
        message:
            'Audio de tu conversación descargado. Intenta subirlo de nuevo, si persiste el fallo contacto con soporte.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'shared-url-copied',
        message: 'URL copiada, utilízala para compartir la reunión',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'add-participant-error',
        message: 'Error introduciendo el nuevo participante, vuelve a intentarlo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'delete-participant-error',
        message: 'Error eliminando al participante, vuelve a intentarlo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'create-tag-error',
        message: 'Error creando etiqueta, vuelve a intentarlo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'change-participant-turn-error',
        message: 'Error cambiando turno, vuelve a intentarlo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'meetings-limit-reached',
        message:
            'Has alcanzado el límite de reuniones acorde a tu plan. Pásate a premium para seguir haciendo reuniones.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'meeting-shared',
        message: 'Hemos enviado un correo para compartir tu reunión.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'title-error',
        message: 'Ha ocurrido un error actualizando el título de la reunión.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'meeting-retry-processing',
        message:
            'Volviendo a procesar reunión. Contacta con soporte@voicit.com si sigues con problemas.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'meeting-direct-upload-limit-reached',
        message: 'Has alcanzado el límite de reuniones por subida directa del plan gratuito',
        actionTitle: 'Actualizar a Premium',
        actionFunc: () => {
            window.open(urls.payments.monthly);
        }
    },
    {
        type: 'meeting-tag-error',
        message: 'Ha ocurrido un error introduciendo tu etiqueta.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'audio-recording-error',
        message: 'Ha ocurrido un error grabando. Asegúrate de tener el micrófono habilitado.',
        actionTitle: null,
        actionFunc: null
    }
];
