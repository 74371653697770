import { authApiCall, endpoints } from '.';

export const chat = async ({ newMessage, thread, meetingId }) => {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: `/chat/${meetingId}`,
        data: {
            newMessage,
            thread
        }
    });

    return data;
};

export const preloadChatCache = async ({ meetingId }) => {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: `/chat/preload/${meetingId}`
    });

    return data;
};

export const cleanChatCache = async ({ meetingId }) => {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: `/chat/clean/${meetingId}`
    });

    return data;
};

export const completion = async ({ prompt, system, config, user }) => {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: `/create/completion`,
        data: {
            prompt,
            system,
            config: {
                model: config.model || 'gpt-3.5-turbo',
                temperature: config.temperature || 0.5,
                max_tokens: 400,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
                user
            }
        }
    });

    return data;
};

// ! Non used - delete after certain time, today is 04/03/2025
export const openaiUploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: '/openai/file/upload',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return data;
};

export const openaiRetrieveFile = async (fileId) => {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.nlp,
        path: `/openai/file/${fileId}`
    });

    return data;
};

export const openaiDeleteFile = async (fileId) => {
    const res = await authApiCall({
        method: 'DFLETE',
        endpoint: endpoints.nlp,
        path: `/openai/file/${fileId}`
    });

    return res;
};
