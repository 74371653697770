export const authNotification = [
    {
        type: 'unauthorized',
        message: 'Usuario no autenticado.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'sign-up-error',
        message: 'Error creando cuenta. Inténtalo de nuevo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'user-already-exists',
        message: 'El usuario ya existe. Prueba a iniciar sesión o cambiar la contraseña.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'grecaptcha-robot',
        message: 'Hemos detectado que eres un robot.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'grecaptcha-error',
        message: 'Error comprobando si eres humano. Recarga la página.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'plan-changed',
        message: 'Tu plan ha cambiado, vuelve a iniciar sesión.',
        actionTitle: null,
        actionFunc: null
    }
];
