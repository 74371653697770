import * as React from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import LoaderIcon from '@common/components/loaders/LoaderIcon';
import LoaderTitleWithSubtitle from '@common/components/loaders/LoaderTitleWithSubtitle';

ParticipantLoader.propTypes = {
    sx: PropTypes.object
};

export default function ParticipantLoader({ sx }) {
    return (
        <Stack
            direction='row'
            sx={{ ...sx, display: 'flex', alignItems: 'center', mb: 1 }}
            spacing={2}
        >
            <LoaderIcon size={'48px'} />
            <LoaderTitleWithSubtitle sx={{ flexGrow: 1 }} />
        </Stack>
    );
}
