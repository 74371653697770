import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { initializeRolesByUseCase } from '@common/components/participants/utils';
import { meetingStatus } from '@common/helpers/meetingStatus';
import { navigation } from '@common/helpers/navigation';
import useNotification from '@common/hooks/useNotification';
import { useRoles } from '@common/hooks/useRoles';
import useUser from '@common/hooks/useUser';
import { getAudioDuration } from '@common/utils/audio';
import { downloadFileFromBlob } from '@common/utils/downloadFileFromBlob';
import { getReadSignedAudioURL, uploadAudioToGCS } from '@setup/api/gcs';
import {
    createMeeting,
    mergeAudio,
    updateMeetingTag,
    updateMeetingTitle
} from '@setup/api/meeting/meeting';
import { getParticipantsByMeetingId } from '@setup/api/participants/participants';
import { getReportTemplateById } from '@setup/api/reportTemplates/reportTemplates';
import CreateMeetingDialogView from './CreateMeetingDialogView';
import CreateMeetingMethodSelection from './CreateMeetingMethodSelection';
import DialogLoadingState from './DialogLoadingState';
import DialogUsageLimit from './DialogUsageLimit';
import {
    showDurationAlert,
    showFileAlert,
    showFormatAlert,
    showParticipantsAlert,
    showParticipantsRoleAlert,
    showParticipantsTurnAlert,
    showTitleAlert
} from './utils';

CreateMeetingDialog.propTypes = {
    findAndUpdateMeeting: PropTypes.func.isRequired,
    isDirectUpload: PropTypes.bool.isRequired,
    currentMeeting: PropTypes.object,
    handleRetryMeeting: PropTypes.func,
    introduceNewMeeting: PropTypes.func,
    openFromButton: PropTypes.bool,
    setOpenButtonDialog: PropTypes.func,
    setIsAudioFromExtension: PropTypes.func,
    recordedFile: PropTypes.any,
    isRecordedFromVoicit: PropTypes.bool
};

export default function CreateMeetingDialog({
    currentMeeting,
    handleRetryMeeting,
    findAndUpdateMeeting,
    isDirectUpload,
    introduceNewMeeting,
    openFromButton,
    setOpenButtonDialog,
    setIsAudioFromExtension,
    recordedFile,
    isRecordedFromVoicit
}) {
    const uploadWaitingTime = 3000;

    const navigate = useNavigate();
    const notification = useNotification();
    const { userIsRunOutOfMinutes } = useUser();
    const audioMerge = useRef(false);
    const { roles, rolesByCategory } = useRoles();

    const userUseCase = useSelector((state) => state.user.userUseCase);
    const userPlan = useSelector((state) => state.user.userPlan);
    const useCase = useSelector((state) => state.user.userUseCase);
    const userName = useSelector((state) => state.user.userGivenName);

    const [title, setTitle] = useState('');
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('');
    const [audioDuration, setAudioDuration] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(openFromButton ? openFromButton : false);
    const [alertTitle, setAlertTitle] = useState(false);
    const [alertFile, setAlertFile] = useState(false);
    const [alertParticipant, setAlertParticipant] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [participants, setParticipants] = useState([]);
    const [tag, setTag] = useState();
    const [template, setTemplate] = useState();
    const [audioUrl, setAudioUrl] = useState('');
    const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);
    const [meetingCreated, setMeetingCreated] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setTitle('');
        setFilename('');
        setFile('');
        setAlertMessage('');
        setAlertTitle(false);
        setLoading(false);
    };

    const handleClose = async ({ closingOnSuccess } = {}) => {
        if (
            (closingOnSuccess !== true && !isDirectUpload) ||
            (closingOnSuccess !== true && isRecordedFromVoicit)
        ) {
            if (closeConfirmationOpen) {
                handleCleanDependencies();
                navigate('/');
            }
            setCloseConfirmationOpen(!closeConfirmationOpen);
        } else {
            handleCleanDependencies();
            if (isDirectUpload) {
                setOpenButtonDialog(false);
            } else {
                navigate('/');
            }
        }
    };

    const handleDeleteFile = () => {
        setFilename('');
        setFile('');
        setTitle('');
    };

    const handleDownloadAudio = () => {
        console.log(file, filename);
        downloadFileFromBlob({
            blob: file,
            filename: filename,
            extension: 'mp3'
        });
    };

    const handleOnFileChange = async (file) => {
        try {
            const duration = await getAudioDuration(file).catch(() => {
                return null;
            });

            if (
                showFormatAlert({ file, setAlertMessage, setAlertFile }) ||
                showDurationAlert({
                    duration,
                    planDurationLimit: userPlan.meetingMinutesDuration,
                    setAlertMessage,
                    setAlertFile
                })
            )
                return;

            setFile(file);
            setAudioDuration(duration);
            setFilename(file.name);
            setTitle(file.name);
            setAlertFile(false);
            setAlertMessage('');
        } catch (error) {
            setFilename('');
            console.error(error);
        }
    };

    const handleNavigateToRecorder = () => {
        navigate(navigation.app.routes.recorder);
        window.location.reload();
    };

    const handleCleanDependencies = () => {
        setTitle('');
        setFilename('');
        setFile('');
        setAlertMessage('');
        setAlertTitle(false);
        setLoading(false);
        setOpen(false);
        setAlertFile(false);
        setAlertParticipant(false);
        setParticipants([]);
        setTemplate();
        setTag();
    };

    const handleOnTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const setFailureNotification = ({ audioFailure }) => {
        if (audioFailure) {
            notification('audio-failure');
        } else {
            notification('meeting-failure');
        }
    };

    /**
     * Check for possible errors
     */
    const alertFilter = () => {
        if (isDirectUpload && showFileAlert({ file, setAlertMessage, setAlertFile })) {
            return true;
        }
        if (userUseCase === rolesByCategory[0].type) {
            if (
                showParticipantsAlert({
                    currentParticipants: participants,
                    setAlertMessage,
                    setAlertParticipant
                })
            ) {
                return true;
            }
        }
        if (
            showTitleAlert({ title, setAlertMessage, setAlertTitle }) ||
            showParticipantsTurnAlert({
                currentParticipants: participants,
                setAlertMessage,
                setAlertParticipant
            }) ||
            showParticipantsRoleAlert({
                currentParticipants: participants,
                setAlertMessage,
                setAlertParticipant
            })
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleDirectUploadCreateMeeting = async (e) => {
        e.preventDefault();

        // Don't check if participants, it is optional
        if (alertFilter()) return;
        if (loading) return; // To ensure we avoid repeated api calls

        setLoading(true);
        const startUploadTime = Date.now();

        if (!audioDuration) {
            handleClose();
            setFailureNotification({ audioFailure: true });
            return;
        }

        try {
            const filename = await uploadAudioToGCS({ file });
            const { meeting } = await createMeeting({
                title,
                filename: filename,
                duration: audioDuration,
                tag: tag?.name || null,
                templateId: template.id,
                platform: 'directUpload',
                participants
            });
            introduceNewMeeting(meeting);
            setMeetingCreated(true);

            const elapsedUploadTime = Date.now() - startUploadTime;
            const remainingTime = Math.max(uploadWaitingTime - elapsedUploadTime, 0);

            setTimeout(() => {
                handleClose({ closingOnSuccess: true });
                if (!userIsRunOutOfMinutes) notification('transcription-processing');
            }, remainingTime);
        } catch (error) {
            setLoading(false);
            setFailureNotification({ audioFailure: false });
        }
    };

    const handleStandByCreateMeeting = async () => {
        const meetingId = currentMeeting.id;
        try {
            if (alertFilter()) return;
            if (loading) return; // To ensure we avoid repeated api calls

            setLoading(true);
            const startUploadTime = Date.now();

            // Data update stage
            if (currentMeeting.title !== title) await updateMeetingTitle({ meetingId, title });
            if (tag?.name) await updateMeetingTag({ meetingId, tag: tag.name });

            if (!isRecordedFromVoicit) {
                await findAndUpdateMeeting({
                    label: tag?.name || null,
                    title: currentMeeting.title !== title ? title : null,
                    id: meetingId,
                    status: meetingStatus.AUDIO_STARTED
                });
            }

            // Meeting process starting stage
            await handleRetryMeeting({
                meetingId,
                participants,
                templateId: template.id
            });

            const elapsedUploadTime = Date.now() - startUploadTime;
            const remainingTime = Math.max(uploadWaitingTime - elapsedUploadTime, 0);

            setTimeout(() => {
                handleClose({ closingOnSuccess: true });
                notification('transcription-processing');

                if (!isRecordedFromVoicit) {
                    setIsAudioFromExtension(false); // To avoid opening the dialog again
                }
            }, remainingTime);
        } catch (error) {
            console.log(error);
            setFailureNotification({ audioFailure: false });
            setLoading(false);
        }
    };

    useEffect(() => {
        if (setOpenButtonDialog) {
            setOpen(openFromButton);
        }
    }, [openFromButton]);

    useEffect(() => {
        if (open && userIsRunOutOfMinutes) {
            setAlertFile(true);
            setAlertMessage('No dispones de más minutos de uso este mes');
        }
    }, [open]);

    useEffect(() => {
        (async () => {
            if (currentMeeting && !isDirectUpload && !isRecordedFromVoicit) {
                setOpen(true);
                setTitle(currentMeeting.title ? currentMeeting.title : 'Sin título');
                setFilename(currentMeeting.title ? currentMeeting.title : 'Sin título');

                const meetingParticipants = await getParticipantsByMeetingId(currentMeeting.id);

                if (meetingParticipants?.length && roles?.length) {
                    const initializedParticipants = initializeRolesByUseCase(
                        meetingParticipants,
                        roles,
                        useCase,
                        userName
                    );
                    setParticipants(initializedParticipants);
                }

                setAudioDuration(currentMeeting.duration);
                // Below is just used for retrying
                if (currentMeeting.templateId) {
                    const { data } = await getReportTemplateById({
                        reportTemplateId: currentMeeting.templateId
                    });
                    setTemplate(data);
                }
                try {
                    if (currentMeeting.filename) {
                        const url = await getReadSignedAudioURL({
                            filename: currentMeeting.filename,
                            removeType: true
                        });
                        setAudioUrl(url);
                    } else if (!audioMerge.current) {
                        // No filename means audio hasn´t been merged yet
                        audioMerge.current = true; // Set ref to not repeat mergeAudio calls
                        const { filename } = await mergeAudio(currentMeeting.id).catch(
                            () => (audioMerge.current = false)
                        );
                        const url = await getReadSignedAudioURL({
                            filename,
                            removeType: true
                        });
                        findAndUpdateMeeting({ id: currentMeeting.id, filename }); // Updates the meeting data locally
                        setAudioUrl(url);
                    }
                } catch (e) {
                    notification('audio-failure-ext');
                    handleClose({ closingOnSuccess: true });
                }
            }
        })();
        // TODO: check if using roles as a trigger create problems
    }, [currentMeeting, roles]);

    /**
     * To charge the recorded audio file from Voicit recording page
     */
    useEffect(() => {
        if (recordedFile) {
            handleOnFileChange(recordedFile);
        }
    }, [recordedFile]);

    if ((!isDirectUpload && userIsRunOutOfMinutes) || (userIsRunOutOfMinutes && meetingCreated)) {
        return <DialogUsageLimit />;
    } else if ((file || audioUrl) && filename) {
        return (
            <CreateMeetingDialogView
                handleOnTitleChange={handleOnTitleChange}
                title={title}
                loading={loading}
                handleClose={handleClose}
                open={open}
                alertTitle={alertTitle}
                alertFile={alertFile}
                alertMessage={alertMessage}
                alertParticipant={alertParticipant}
                filename={filename}
                file={file}
                participants={participants}
                tag={tag}
                setTag={setTag}
                template={template}
                setTemplate={setTemplate}
                setParticipants={setParticipants}
                audioDuration={audioDuration}
                audioUrl={audioUrl}
                handleCreateMeeting={
                    !isDirectUpload ? handleStandByCreateMeeting : handleDirectUploadCreateMeeting
                }
                closeConfirmationOpen={
                    isDirectUpload && !isRecordedFromVoicit ? false : closeConfirmationOpen
                }
                setCloseConfirmationOpen={setCloseConfirmationOpen}
                isDirectUpload={isDirectUpload}
                handleDeleteFile={isDirectUpload ? handleDeleteFile : null}
                handleDownload={!isDirectUpload && file ? handleDownloadAudio : null}
                handleClickOpen={isDirectUpload ? handleClickOpen : null}
            />
        );
    } else if (isDirectUpload && open && !isRecordedFromVoicit) {
        return (
            <CreateMeetingMethodSelection
                filename={filename}
                alertMessage={alertMessage}
                alertFile={alertFile}
                handleNavigateToRecorder={handleNavigateToRecorder}
                handleOnFileChange={handleOnFileChange}
                handleClose={handleClose}
            />
        );
    } else if (open) {
        return <DialogLoadingState />;
    }
}
