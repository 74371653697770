import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userGivenName: null,
    userEmail: null,
    userAvatarUrl: null,
    userRole: null,
    userPlanId: null,
    userCreatedAt: null,
    userPlan: null,
    userUseCase: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserGivenName: (state, action) => {
            state.userGivenName = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setUserAvatarUrl: (state, action) => {
            state.userAvatarUrl = action.payload;
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload;
        },
        setUserCreatedAt: (state, action) => {
            state.userCreatedAt = action.payload;
        },
        setUserPlan: (state, action) => {
            state.userPlan = action.payload;
        },
        setUserUseCase: (state, action) => {
            state.userUseCase = action.payload;
        },
        removeUser: (state) => {
            state.userGivenName = null;
            state.userEmail = null;
            state.userAvatarUrl = null;
            state.userRole = null;
            state.userPlanId = null;
            state.userCreatedAt = null;
            state.userPlan = null;
            state.userUseCase = null;
        }
    }
});

export const {
    setUserGivenName,
    setUserEmail,
    setUserAvatarUrl,
    setUserRole,
    removeUser,
    setUserCreatedAt,
    setUserPlan,
    setUserUseCase
} = userSlice.actions;
export default userSlice.reducer;
