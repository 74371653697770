import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text } from '@react-pdf/renderer';
import { PropTypes } from 'prop-types';
import logotype from '@assets/brand/voicit-logotype.png';
import { translations } from '@common/helpers/translations';
import { getTimeMeetingIntervention } from '@common/utils/time';

TranscriptionPDF.propTypes = {
    meetingTitle: PropTypes.string.isRequired,
    interventions: PropTypes.arrayOf(PropTypes.any).isRequired,
    creationDate: PropTypes.string.isRequired,
    participantNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    duration: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired
};

export default function TranscriptionPDF({
    interventions,
    meetingTitle,
    creationDate,
    participantNames,
    duration,
    language
}) {
    // TODO: Change date language to the meeting language
    return (
        <Document>
            <Page style={styles.body}>
                <Image style={styles.image} src={logotype} />
                <Text style={styles.title}>{meetingTitle}</Text>
                <Text style={styles.info}>
                    {creationDate}
                    {participantNames.length > 0 && (
                        <>
                            {'\n'}
                            {participantNames.join(', ')}.
                        </>
                    )}
                </Text>
                <Text style={styles.info}></Text>
                {interventions.map((intervention) => {
                    return (
                        <>
                            <Text style={styles.subtitle}>
                                {intervention.participant?.name ||
                                    `${translations[language].participant} ${intervention.turn}`}{' '}
                                (
                                {getTimeMeetingIntervention({
                                    hoursDisabled: duration > 60 * 60 ? false : true,
                                    timeKey: intervention.timeKey1
                                })}
                                ):
                            </Text>
                            <Text style={styles.text}>{intervention.lines}</Text>
                        </>
                    );
                })}
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    );
}

Font.register({
    family: 'Manrope',
    src: 'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap'
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    title: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: 16,
        marginBottom: 4,
        marginTop: 8
    },
    subtitle: {
        fontWeight: 300,
        fontSize: 10,
        lineHeight: 1.5,
        color: 'grey'
    },
    text: {
        fontWeight: 300,
        marginTop: 4,
        marginBottom: 12,
        lineHeight: 22 / 14,
        fontSize: 10,
        textAlign: 'justify'
    },
    info: {
        fontWeight: 300,
        marginTop: 4,
        marginBottom: 24,
        lineHeight: 22 / 14,
        fontSize: 12,
        textAlign: 'justify',
        color: 'grey'
    },
    image: {
        marginBottom: 24,
        maxWidth: 99,
        maxHeight: 18
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    }
});
