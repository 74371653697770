import { meetingStatus } from '@common/helpers/meetingStatus';

/**
 * Set to visual error state meetings on error state or those which has been more than one day in a not succeed state
 *
 * @param {date} createdAt creation date
 * @param {date} updatedAt updated date
 * @returns {boolean} should or not should be on error state
 */
export const shouldBeOnErrorState = ({ status, createdAt, updatedAt }) => {
    let shouldBe = false;
    const currentDate = new Date();
    const creationDate = new Date(createdAt);
    const updateDate = new Date(updatedAt);
    const delayInMinutes = 11; // Backend will automatically start the meeting process in 10 minutes
    const timeFromCreationInMilliseconds = currentDate - creationDate;
    const timeFromCreationInHours = timeFromCreationInMilliseconds / (1000 * 60 * 60);
    const timeFromUpdateInMilliseconds = currentDate - updateDate;
    const timeFromUpdateInHours = timeFromUpdateInMilliseconds / (1000 * 60 * 60);

    if (
        status === meetingStatus.TRANSCRIPTION_ERROR ||
        status === meetingStatus.SUMMARY_ERROR ||
        status === meetingStatus.AUDIO_ERROR ||
        status === meetingStatus.SERVER_ERROR
    ) {
        shouldBe = true;
    } else if (
        status === meetingStatus.TRANSCRIPTION_STARTED ||
        status === meetingStatus.AUDIO_STARTED
    ) {
        if (
            timeFromCreationInHours >= delayInMinutes / 60 &&
            timeFromUpdateInHours >= delayInMinutes / 60
        ) {
            // More than delayInMinutes from creation or udpate in started status
            shouldBe = true;
        }
    }
    return shouldBe;
};

/**
 * Returns if the meeting can be still being recorded
 *
 * @param {string} status meeting state
 * @param {date} updatedAt updated date
 * @param {string} filename name of the audio file
 * @returns {boolean} should or not should be on error state
 */
export const isRecordingState = ({ status, updatedAt, filename }) => {
    const currentDate = new Date();
    const updateDate = new Date(updatedAt);
    const delayInSeconds = 150; // Each backup is 90s + 60s marging
    const timeFromUpdateInMilliseconds = currentDate - updateDate;
    const timeFromUpdateInSeconds = timeFromUpdateInMilliseconds / 1000;

    if (status === meetingStatus.STAND_BY && !filename) {
        return timeFromUpdateInSeconds <= delayInSeconds;
    }
    return false;
};

/**
 * Return if the meeting is processing
 *
 * @param {string} createdAt creation date @param {number} createdAt
 * @param {date} updatedAt updated date @param {number} updatedAt
 * @returns {boolean} should or not should be on error state
 */
export const isProcessingState = (status) => {
    return (
        status !== meetingStatus.SUMMARY_ERROR &&
        status !== meetingStatus.SUMMARY_SUCCEED &&
        status !== meetingStatus.TRANSCRIPTION_ERROR &&
        status !== meetingStatus.STAND_BY &&
        status !== meetingStatus.AUDIO_ERROR &&
        status !== meetingStatus.SERVER_ERROR
    );
};

/**
 * Return if the meeting is in standby
 *
 * @param {string} createdAt creation date @param {number} createdAt
 * @param {date} updatedAt updated date @param {number} updatedAt
 * @returns {boolean} should or not should be on error state
 */
export const isStandByState = (status) => {
    return status === meetingStatus.STAND_BY;
};
