import { compareWithoutAccentsAndCase } from '@common/utils/strings';

export const handleCreateParticipant = ({
    name,
    turn,
    focus,
    participants,
    setAlertParticipant,
    setAlertMessage,
    setParticipants,
    setName,
    setTurn,
    alertParticipant,
    roleId
}) => {
    // TODO: Refactor this funciton based on the new way of creating participants
    const newParticipant = {
        name,
        turn,
        focus,
        roleId
    };
    let success = true;
    if (
        setTurn &&
        turn !== null &&
        participants.find((s) => s.turn === newParticipant.turn && newParticipant.turn !== null)
    ) {
        setAlertParticipant(true);
        setAlertMessage('Este turno ya ha sido establecido');
        success = false;
    } else if (participants.find((s) => compareWithoutAccentsAndCase(s.name, name))) {
        setAlertParticipant(true);
        setAlertMessage('Participante repetido');
        success = false;
    } else {
        setParticipants([newParticipant, ...(participants || [])]);
        setName('');
        if (setTurn) {
            setTurn('');
        }
        if (alertParticipant) {
            setAlertParticipant(false);
            setAlertMessage('');
        }
    }
    return success;
};

export const getParticipantTurnByName = (participantsWithTurn, name) => {
    const participant = participantsWithTurn.find((s) => s.name === name);
    return participant ? participant.turn : null;
};

export const getRoleIdByParticipant = (participants, name) => {
    const participant = participants.find((s) => s.name === name);
    if (participant && participant.focus !== undefined) {
        return participant.roleId;
    } else {
        return '';
    }
};

export const initializeRolesByUseCase = (participants, roles, useCase, userName) => {
    let updatedParticipants = [...participants];

    switch (useCase) {
        case 'hiring':
            {
                if (
                    participants.length === 2 &&
                    participants.some((participant) => participant.name === userName)
                ) {
                    updatedParticipants = updatedParticipants.map((participant) => {
                        const role =
                            participant.name === userName
                                ? roles.find((role) => role.role === 'interviewer')
                                : roles.find((role) => role.role === 'candidate');
                        return {
                            ...participant,
                            roleId: role.id,
                            focus: role.isDefaultFocused
                        };
                    });
                }
            }
            break;
        case 'clients':
        case 'sales':
            {
                if (
                    participants.length === 2 &&
                    participants.some((participant) => participant.name === userName)
                ) {
                    updatedParticipants = updatedParticipants.map((participant) => {
                        const role =
                            participant.name === userName
                                ? roles.find((role) => role.role === 'client_manager')
                                : roles.find((role) => role.role === 'client');
                        return {
                            ...participant,
                            roleId: role.id,
                            focus: role.isDefaultFocused
                        };
                    });
                }
            }
            break;
    }
    return updatedParticipants;
};
