import { ELanguage } from '@common/utils/languages';

export const translations = {
    [ELanguage.ESP]: {
        participant: 'Participante'
    },
    [ELanguage.CAT]: {
        participant: 'Participant'
    },
    [ELanguage.ENG]: {
        participant: 'Participant'
    },
    [ELanguage.FRA]: {
        participant: 'Participant'
    },
    [ELanguage.DEU]: {
        participant: 'Teilnehmer'
    },
    [ELanguage.ITA]: {
        participant: 'Partecipante'
    },
    [ELanguage.POR]: {
        participant: 'Participante'
    }
};
