import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoaderTable from '@common/components/loaders/LoaderTable';
import { meetingStatus } from '@common/helpers/meetingStatus';
import { navigation } from '@common/helpers/navigation';
import useNotification from '@common/hooks/useNotification';
import {
    deleteMeetingById,
    getMeetingById,
    getMeetingBySearchFilters,
    getPaginatedMeetings,
    retryMeeting
} from '@setup/api/meeting/meeting';
import { setTotalMeetings } from '@setup/redux/reducers/statsSlice';
import TableEmptyState from './TableEmptyState';
import TableView from './TableView';

export default function Table() {
    const notification = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentQueryPage = parseInt(queryParams.get('page')) || 0;

    const [openDialog, setOpenDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(true);
    const [loadingList, setLoadingList] = useState(true);
    const [meetingList, setMeetingList] = useState([]);
    const [currentMeeting, setCurrentMeeting] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(currentQueryPage);
    const [isAudioFromExtension, setIsAudioFromExtension] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const handleRetryMeeting = async ({ meetingId, participants, templateId }) => {
        try {
            const { meeting: retriedMeeting } = await retryMeeting({
                meetingId,
                templateId,
                participants
            });

            await findAndUpdateMeeting({
                label: retriedMeeting.tag,
                title: retriedMeeting.title,
                id: meetingId,
                status:
                    retriedMeeting.transcriptionStatus === meetingStatus.STAND_BY
                        ? meetingStatus.AUDIO_IN_PROGESS
                        : retriedMeeting.transcriptionStatus
            });
            notification('meeting-retry-processing');
            if (isAudioFromExtension) {
                setIsAudioFromExtension(false);
                // TODO: Improvement will be to not delete all params like page, just the not needed
                navigate('');
                if (location.hash.includes(navigation.app.hash.retry)) {
                    window.location.reload(); // TODO: this is because the state doesnt update dynamically
                }
            } else {
                window.location.reload(); // TODO: this is because the state doesnt update dynamically
            }
        } catch (error) {
            notification('meeting-failure');
        }
    };

    const handleDeleteMeeting = async (meetingId) => {
        try {
            setMeetingList(meetingList.filter((meetingData) => meetingData.id !== meetingId));
            await deleteMeetingById({ meetingId });
        } catch (error) {
            notification('deletion-failure');
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setLoadingDialog(false);
        setCurrentMeeting(null);
    };

    const handlePagination = async (page, pageSize) => {
        setLoadingList(true);
        if (page >= 0 && location.hash !== navigation.extension.hash.signin) {
            // Update the 'page' query parameter to keep track of the table page
            // ! Be careful, if meeting comes from extension
            // ! cannot delete the hash #ext-submit from the URL,
            // ! if so, it doesn't show the create meeting dialog

            queryParams.set('page', page);
            navigate(`?${queryParams.toString()}` + location.hash); // TODO: we should save the page if entering a meeting
        }

        const { meetings, count } = await getPaginatedMeetings({ page, pageSize });

        setMeetingList(meetings);
        setRowCount(count);
        dispatch(setTotalMeetings(count));
        setLoadingList(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = async ({ title, tag, date }) => {
        if (title === '' && tag === '' && date === '') {
            handlePagination(0, 5);
            return false;
        }

        const filteredTitle = title.replace(/[#, %]/g, ''); // special characters that broke the server

        const { meetings, count } = await getMeetingBySearchFilters({
            title: filteredTitle ? filteredTitle : null,
            tag: tag ? tag : null,
            date: date ? date : null
        });

        setMeetingList(meetings);
        setRowCount(count);
        setLoadingList(false);
        setIsSearch(true);
        return true;
    };

    const handleResetMeetingList = async () => {
        // ! Be careful with this function, can create loop effects
        setLoadingList(true);
        const { meetings, count } = await getPaginatedMeetings({ page, pageSize: rowsPerPage });
        setMeetingList(meetings);
        setRowCount(count);
        setLoadingList(false);
    };

    const handleOpenMeeting = async (currentMeeting, event) => {
        let updatedMeeting = {};
        const { meeting } = await getMeetingById({ meetingId: currentMeeting.id });
        updatedMeeting = await findAndUpdateMeeting({
            summary: meeting.summary,
            status: meeting.transcriptionStatus,
            id: meeting.id
        });
        if (Object.keys(updatedMeeting).length !== 0) {
            setCurrentMeeting(updatedMeeting);
        }
        if (meeting.transcriptionStatus === meetingStatus.SUMMARY_SUCCEED) {
            if (event && event.button === 1) {
                window.open(`/meeting/${meeting.id}`);
            } else {
                navigate(`/meeting/${meeting.id}`);
            }
        } else {
            setOpenDialog(true);
        }
    };

    const findAndUpdateMeeting = ({ label, title, summary, status, id, filename, updatedAt }) => {
        return new Promise((solve) => {
            const index = meetingList.findIndex((meeting) => {
                return meeting.id === (id ? id : currentMeeting.id);
            });

            let updatedMeetingsList = [...meetingList];
            if (label) {
                updatedMeetingsList[index] = { ...updatedMeetingsList[index], tag: label };
            }
            if (title) {
                updatedMeetingsList[index] = { ...updatedMeetingsList[index], title: title };
            }
            if (summary) {
                updatedMeetingsList[index] = { ...updatedMeetingsList[index], summary: summary };
            }
            if (status !== null && status !== undefined) {
                updatedMeetingsList[index] = {
                    ...updatedMeetingsList[index],
                    transcriptionStatus: status
                };
            }
            if (filename) {
                updatedMeetingsList[index] = { ...updatedMeetingsList[index], filename };
            }
            if (updatedAt) {
                updatedMeetingsList[index] = { ...updatedMeetingsList[index], updatedAt };
            }
            setMeetingList(updatedMeetingsList);
            solve(updatedMeetingsList[index]);
        });
    };

    const introduceNewMeeting = (newMeeting) => {
        setMeetingList([newMeeting, ...(meetingList || [])]);
    };

    /**
     * Effect to refresh the list of meetings when the pagination parameters change.
     * This effect is triggered whenever the 'page' or 'rowsPerPage' state variables are updated.
     * It asynchronously calls the 'handlePagination' function to fetch and update the meetings.
     *
     * @param {number} page - The current page number in the pagination.
     * @param {number} rowsPerPage - The number of rows per page in the pagination.
     */
    useEffect(() => {
        (async () => {
            await handlePagination(page, rowsPerPage);
        })();
    }, [page, rowsPerPage]);

    /**
     * Effect to fill the meeting table when there are no meetings in the current page.
     * It triggers a new pagination request if the current list of meetings is empty or contains only one item
     * and there are more meetings available in subsequent pages.
     * This effect is triggered whenever the 'meetingList' state variable changes.
     *
     * @param {Array} meetingList - The current list of meetings.
     * @param {number} rowCount - The total number of meetings in the dataset.
     * @param {number} page - The current page number in the pagination.
     * @param {number} rowsPerPage - The number of rows per page in the pagination.
     */
    useEffect(() => {
        if (rowCount > 0 && meetingList.length <= 1 && rowCount - (page + 1) * rowsPerPage > 0) {
            (async () => {
                await handlePagination(page, rowsPerPage);
            })();
        }
    }, [meetingList]);

    /**
     * Effect to handle the creation of a meeting from the browser extension.
     * It is triggered when the URL hash includes the submit hash of the extension navigation.
     * If triggered, it sets the 'isAudioFromExtension' state to true,
     * retrieves the 'id' parameter from the URL search, and fetches the meeting details using 'getMeetingById'.
     * The meeting details are then logged, and the current meeting state is updated.
     * If not triggered, it sets 'isAudioFromExtension' to false.
     *
     * @param {Object} location - The location object representing the current URL.
     */
    useEffect(() => {
        if (
            location.hash.includes(navigation.extension.hash.submit) ||
            location.hash.includes(navigation.app.hash.retry)
        ) {
            setIsAudioFromExtension(true);
            const idParam = new URLSearchParams(location.search).get('id');
            if (idParam) {
                (async () => {
                    try {
                        const response = await getMeetingById({ meetingId: idParam });
                        setCurrentMeeting(response.meeting);
                    } catch (error) {
                        setIsAudioFromExtension(false);
                        notification('meeting-failure');
                    }
                })();
            } else {
                setIsAudioFromExtension(false);
                notification('meeting-failure');
            }
        } else {
            setIsAudioFromExtension(false);
        }
    }, [location]);

    // ------- conditional rendering -------
    if (rowCount < 1 && !meetingList[0]) {
        if (loadingList) {
            // Loading meetings
            return <LoaderTable />;
        } else if (!isSearch && !loadingList && !meetingList[0]) {
            // Empty state for no meetings created
            return (
                // ! If we search and there is no meeting no to show this empty state for onboarding
                <TableView
                    rowCount={rowCount}
                    meetingsList={[]}
                    introduceNewMeeting={introduceNewMeeting}
                >
                    <TableEmptyState type='no-meetings' />
                </TableView>
            );
        } else if (isSearch) {
            // Empty state for no meetings found
            return (
                // ! If we search and there is no meeting no to show this empty state for onboarding
                <TableView
                    rowCount={rowCount}
                    meetingsList={meetingList}
                    handleSearch={handleSearch}
                    handleResetMeetingList={handleResetMeetingList}
                    introduceNewMeeting={introduceNewMeeting}
                >
                    <TableEmptyState type='not-found' />
                </TableView>
            );
        }
    } else if (meetingList[0]) {
        // Loaded Meetings
        return (
            <TableView
                handleOpenMeeting={handleOpenMeeting}
                meetingList={meetingList}
                setMeetingList={setMeetingList}
                handleCloseDialog={handleCloseDialog}
                openDialog={openDialog}
                loadingDialog={loadingDialog}
                page={page}
                rowsPerPage={rowsPerPage}
                rowCount={rowCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleDeleteMeeting={handleDeleteMeeting}
                currentMeeting={currentMeeting}
                findAndUpdateMeeting={findAndUpdateMeeting}
                introduceNewMeeting={introduceNewMeeting}
                handleSearch={handleSearch}
                handleResetMeetingList={handleResetMeetingList}
                handleRetryMeeting={handleRetryMeeting}
                isAudioFromExtension={isAudioFromExtension}
                setIsAudioFromExtension={setIsAudioFromExtension}
            />
        );
    }
}
