import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Chip, Grid, Popover, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import InterventionAudioPlayer from '@pages/meeting/components/Transcription/InterventionAudioPlayer';

VoiceSelector.propTypes = {
    setTemplate: PropTypes.func,
    participant: PropTypes.any,
    onVoiceChange: PropTypes.func,
    participants: PropTypes.any,
    speakersIntervention: PropTypes.arrayOf(PropTypes.any),
    audioUrl: PropTypes.string
};

export default function VoiceSelector({
    participant,
    onVoiceChange,
    participants,
    speakersIntervention,
    audioUrl
}) {
    const [anchorElChip, setAnchorElChip] = useState(null);
    const [stopPlayer, setStopPlayer] = useState(false);
    const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(null);
    const [currentIntervention, setCurrentIntervention] = useState(null);

    const handleOpenList = (event) => {
        setStopPlayer(false);
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setStopPlayer(true);
        setAnchorElChip(null);
    };

    const handleOnTurnChange = (data) => {
        onVoiceChange(data);
        handleCloseList();
    };

    const handleSetIsBeingReproduced = (index, isPlaying) => {
        setCurrentlyPlayingIndex(isPlaying ? index : null);
    };

    useEffect(() => {
        const foundIntervention = speakersIntervention.find(
            (intervention) => participant.turn === intervention.turn
        );
        setCurrentIntervention(foundIntervention ?? null);
    }, [participant]);

    return (
        <Grid
            container
            sx={{ direction: 'row', alignItems: 'center', flexDirection: 'row', flexGrow: 1 }}
        >
            <Grid item>
                {audioUrl && currentIntervention !== null && (
                    <InterventionAudioPlayer
                        audioUrl={audioUrl}
                        timeKey1={currentIntervention.timeKey1}
                        timeKey2={currentIntervention.timeKey2}
                        setIsBeingReproduced={(isPlaying) =>
                            handleSetIsBeingReproduced(currentIntervention.turn, isPlaying)
                        }
                        stop={
                            stopPlayer ||
                            (currentlyPlayingIndex !== null &&
                                currentlyPlayingIndex !== currentIntervention.turn)
                        }
                    />
                )}
            </Grid>
            <Grid item mr={1}>
                <Tooltip title={'Cambiar voz del participante'}>
                    <Chip
                        sx={{
                            flexGrow: 1,
                            borderRadius: 2,
                            justifyContent: 'left'
                        }}
                        label={
                            participant.turn == null ? 'Asignar voz' : `Voz ${participant.turn + 1}`
                        }
                        color={participant.turn == null ? 'chip_primary_soft' : 'chip_grey'}
                        size='small'
                        onDelete={
                            participant.turn !== null
                                ? () =>
                                      onVoiceChange({
                                          oldParticipant: null,
                                          newParticipant: participants.find(
                                              (p) => p.turn === participant.turn
                                          ),
                                          turn: null
                                      })
                                : null
                        }
                        onClick={handleOpenList}
                        deleteIcon={participant.turn !== null && <CancelIcon />}
                        icon={participant.turn == null && <ExpandMoreOutlinedIcon />}
                    />
                </Tooltip>
            </Grid>
            <Popover
                sx={{ mt: '45px', maxHeight: 450, display: 'flex' }}
                id='menu-filters'
                anchorEl={anchorElChip}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={Boolean(anchorElChip)}
                onClose={handleCloseList}
            >
                <Grid
                    container
                    spacing={0}
                    direction={'column'}
                    sx={{ alignItems: 'left', m: 0, mb: 1 }}
                >
                    <Grid item>
                        <Grid
                            container
                            spacing={0}
                            direction={'column'}
                            sx={{ alignItems: 'left', pl: 2, pr: 2, pb: 1, pt: 1 }}
                        >
                            <Typography variant='caption'>Escucha la voz para asignar</Typography>
                            {speakersIntervention.map((intervention, index) => (
                                <Grid item key={index} sx={{ mt: 1, ml: 1, mr: 1 }}>
                                    <Stack
                                        direction='row'
                                        spacing={1}
                                        alignItems='center'
                                        display='flex'
                                    >
                                        {audioUrl && intervention.timeKey1 && (
                                            // TODO: stop the player when another start
                                            <InterventionAudioPlayer
                                                audioUrl={audioUrl}
                                                timeKey1={intervention.timeKey1}
                                                timeKey2={intervention.timeKey2}
                                                setIsBeingReproduced={(isPlaying) =>
                                                    handleSetIsBeingReproduced(
                                                        intervention.turn,
                                                        isPlaying
                                                    )
                                                }
                                                stop={
                                                    stopPlayer ||
                                                    (currentlyPlayingIndex !== null &&
                                                        currentlyPlayingIndex !== intervention.turn)
                                                }
                                            />
                                        )}
                                        {participants.find((p) => p.turn === intervention.turn) ? (
                                            <Tooltip title='Quítala del participante actual para reasignar'>
                                                <Chip
                                                    sx={{
                                                        flexGrow: 1,
                                                        borderRadius: 2,
                                                        justifyContent: 'left'
                                                    }}
                                                    label={`Voz ${intervention.turn + 1}`}
                                                    color={'chip_disabled'}
                                                    icon={
                                                        participant.turn !== null &&
                                                        participant.turn === intervention.turn ? (
                                                            <DoneIcon />
                                                        ) : null
                                                    }
                                                    size='small'
                                                />
                                            </Tooltip>
                                        ) : (
                                            <Chip
                                                sx={{
                                                    flexGrow: 1,
                                                    borderRadius: 2,
                                                    justifyContent: 'left'
                                                }}
                                                label={`Voz ${intervention.turn + 1}`}
                                                onClick={
                                                    participants.find(
                                                        (p) => p.turn === intervention.turn
                                                    )
                                                        ? null
                                                        : () => {
                                                              handleOnTurnChange({
                                                                  newParticipant: participant,
                                                                  turn: intervention.turn
                                                              });
                                                          }
                                                }
                                                color={'chip_grey'}
                                                icon={
                                                    participant.turn !== null &&
                                                    participant.turn === intervention.turn ? (
                                                        <DoneIcon />
                                                    ) : null
                                                }
                                                size='small'
                                            />
                                        )}
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    );
}
