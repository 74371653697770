import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MicNoneIcon from '@mui/icons-material/MicNone';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
    Box,
    Button,
    Chip,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Toolbar
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import TagsButtonSelector from '@common/components/tagsComponents/TagsButtonSelector';
import { navigation } from '@common/helpers/navigation';
import rolesByCategory from '@common/helpers/rolesByCategory.json';
import { utcToLocaleDate } from '@common/utils/time';
import DatePickerButtonDialog from './DatePickerButtonDialog';
import CreateMeetingDialog from '../../createMeetingDialog/CreateMeetingDialog';

TableToolbar.propTypes = {
    introduceNewMeeting: PropTypes.func.isRequired,
    handleSearch: PropTypes.func,
    handleResetMeetingList: PropTypes.func,
    currentMeeting: PropTypes.object,
    isAudioFromExtension: PropTypes.bool.isRequired,
    setIsAudioFromExtension: PropTypes.func.isRequired,
    rowCount: PropTypes.number,
    handleDeleteMeeting: PropTypes.func.isRequired,
    handleRetryMeeting: PropTypes.func.isRequired,
    findAndUpdateMeeting: PropTypes.func.isRequired
};

export default function TableToolbar({
    introduceNewMeeting,
    handleSearch,
    handleResetMeetingList,
    currentMeeting,
    handleDeleteMeeting,
    findAndUpdateMeeting
}) {
    const navigate = useNavigate();
    const userUseCase = useSelector((state) => state.user.userUseCase);

    const [searchInputTitle, setSearchInputTitle] = useState('');
    const [searchInputTag, setSearchInputTag] = useState('');
    const [searchInputDate, setSearchInputDate] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [openButtonDialog, setOpenButtonDialog] = useState(false);
    const [cleanData, setCleanData] = useState(false);

    const onClickSearch = () => {
        if (openSearch) {
            setOpenSearch(false);
            setSearchInputTitle('');
        } else {
            setOpenSearch(true);
        }
    };

    const handleUpdateSearch = (field, value) => {
        switch (field) {
            case 'title':
                setSearchInputTitle(value);
                break;
            case 'tag':
                setSearchInputTag(value);
                break;
            case 'date':
                setSearchInputDate(value);
                break;
            default:
                break;
        }
        if (!value) setCleanData(true);
    };

    const handleNavigateToRecorder = () => {
        navigate(navigation.app.routes.recorder);
        window.location.reload();
    };

    /**
     * Control the search or cleaning of the meeting list
     */
    useEffect(() => {
        const inputSearch = {
            title: searchInputTitle,
            tag: searchInputTag,
            date: searchInputDate
        };
        const isAnyFilterActive = inputSearch.title || inputSearch.tag || inputSearch.date;

        if (isAnyFilterActive) {
            handleSearch(inputSearch);
        } else {
            if (cleanData) {
                handleResetMeetingList();
            }
        }
        setCleanData(false);
    }, [searchInputDate, searchInputTag, searchInputTitle]);

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                justifyContent: 'flex-end',
                alignItems: 'center'
            }}
        >
            {searchInputTag ? (
                <Chip
                    sx={{
                        mr: 2
                    }}
                    key={searchInputTag}
                    label={searchInputTag}
                    size='small'
                    onDelete={() => handleUpdateSearch('tag', '')}
                />
            ) : null}
            {searchInputDate ? (
                <Chip
                    sx={{
                        mr: 2
                    }}
                    key={'date'}
                    label={`${utcToLocaleDate({
                        utcDate: searchInputDate.from,
                        timeZone: 'es-ES'
                    })} - ${utcToLocaleDate({
                        utcDate: searchInputDate.to,
                        timeZone: 'es-ES'
                    })}`}
                    size='small'
                    onDelete={() => handleUpdateSearch('date', '')}
                />
            ) : null}
            <TagsButtonSelector
                iconButton={<FilterListIcon />}
                onTagSelection={(chip) => handleUpdateSearch('tag', chip.name)}
                tooltipLabel='Filtrar por etiqueta'
                toolBar={true}
            />
            {handleSearch !== undefined ? (
                <DatePickerButtonDialog
                    setSearchInputDate={(date) => handleUpdateSearch('date', date)}
                />
            ) : null}
            {openSearch ? (
                <>
                    <FormControl sx={{ pr: 1, pb: 1, pl: 2 }} fullWidth={true} variant='outlined'>
                        <InputLabel htmlFor='input-searched-text'>
                            Buscar en tus reuniones
                        </InputLabel>
                        {searchInputTitle ? (
                            <Input
                                id='input-searched-text'
                                type='text'
                                size='small'
                                value={searchInputTitle}
                                onChange={(e) => handleUpdateSearch('title', e.target.value)}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButtonWithTooltip
                                            tooltipText={'Limpiar buscador'}
                                            onClick={() => handleUpdateSearch('title', '')}
                                            icon={<CancelIcon fontSize='small' />}
                                            size='small'
                                        />
                                    </InputAdornment>
                                }
                                label='Buscar en tus reuniones'
                                autoFocus
                            />
                        ) : (
                            <Input
                                id='input-searched-text'
                                type='text'
                                size='small'
                                value={searchInputTitle}
                                onChange={(e) => handleUpdateSearch('title', e.target.value)}
                                label='Buscar en tus reuniones'
                                autoFocus
                            />
                        )}
                    </FormControl>
                    <IconButtonWithTooltip
                        tooltipText={'Esconder buscador'}
                        onClick={onClickSearch}
                        icon={<KeyboardArrowRightIcon />}
                        size='medium'
                        sx={{ mr: 1 }}
                    />
                </>
            ) : (
                <>
                    {handleSearch !== undefined ? (
                        <IconButtonWithTooltip
                            id='clickFilterByMeeting'
                            tooltipText={'Buscar'}
                            onClick={onClickSearch}
                            icon={<SearchOutlinedIcon id='clickFilterByMeeting' />}
                            size='medium'
                            sx={{ pr: 1 }}
                        />
                    ) : null}
                </>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    variant='text'
                    color='primary'
                    size='medium'
                    endIcon={<MicNoneIcon />}
                    disableElevation={true}
                    sx={{ mr: 1 }}
                    onClick={handleNavigateToRecorder}
                >
                    Grabar
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    endIcon={<AddIcon />}
                    disableElevation={true}
                    onClick={() => setOpenButtonDialog(true)}
                >
                    {userUseCase === rolesByCategory[0].type ? 'Crear Informe' : 'Crear Resumen'}
                </Button>
            </Box>
            <CreateMeetingDialog
                openFromButton={openButtonDialog}
                setOpenButtonDialog={setOpenButtonDialog}
                introduceNewMeeting={introduceNewMeeting}
                currentMeeting={currentMeeting}
                isDirectUpload={true}
                handleDeleteMeeting={handleDeleteMeeting}
                findAndUpdateMeeting={findAndUpdateMeeting}
            />
        </Toolbar>
    );
}
