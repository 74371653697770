import React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const size = 8;

const StyledBadge = styled(Badge, {
    shouldForwardProp: (prop) => prop !== 'disable' // Ensures disable prop is passed correctly
})(({ theme, disable }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: disable ? 'gray' : '#E84D47',
        color: disable ? 'gray' : '#E84D47',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        width: size, // Size of the badge
        height: size,
        borderRadius: '50%',
        '&::after': disable
            ? {}
            : {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  animation: 'ripple 1.2s infinite ease-in-out',
                  border: '1px solid currentColor',
                  content: '""'
              }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
}));

RecordingStatusBadge.propTypes = {
    disable: PropTypes.bool
};

export default function RecordingStatusBadge({ disable }) {
    return (
        <StyledBadge
            overlap='circular'
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            variant='dot'
            disable={disable}
            sx={{
                ml: 1
            }}
        >
            <div style={{ width: size, height: size }} /> {/* Invisible placeholder */}
        </StyledBadge>
    );
}
