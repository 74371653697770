import { authApiCall, endpoints } from '..';

export async function getAllRoles() {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.participants,
        path: `/role/all`
    });

    return res.data;
}

export async function getParticipantsByMeetingId(meetingId) {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.participants,
        path: `/${meetingId}`
    });

    return data;
}

export async function saveAllParticipants({ meetingId, participants }) {
    const res = await authApiCall({
        method: 'POST',
        endpoint: endpoints.participants,
        path: `/update/bulk/${meetingId}`,
        data: {
            participants
        }
    });

    return res.status;
}
