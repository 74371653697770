// These are the default z-index for the Material-UI

const zIndex = {
    speedDial: 1050,
    appbar: 1100,
    Dialog: 1300,
    popover: 1500,
    snackbar: 1400,
    tooltip: 1500
};

export default zIndex;
