import { authApiCall, endpoints } from '..';

export async function getPaginatedMeetings({ page, pageSize }) {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetings,
        path: `/paginated/${page}/${pageSize}`
    });

    return {
        meetings: data.data,
        count: data.count
    };
}

export async function getMeetingById({ meetingId }) {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetings,
        path: `/${meetingId}`
    });

    return {
        meeting: data
    };
}

export async function getImportedMeetings() {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetings,
        path: '/platform/directUpload'
    });

    return data;
}

export async function getSharingMeetingUrl({ meetingId, isPremiumUser }) {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetings,
        path: `/${meetingId}/share/url/${isPremiumUser}`
    });

    return {
        sharedUrl: data
    };
}

export async function shareMeetingByMail({ meetingId, isPremiumUser, to }) {
    const { status } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.meetings,
        path: `/${meetingId}/share/email/${isPremiumUser}`,
        data: {
            to
        }
    });

    return status;
}

export async function mergeAudio(meetingId) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.meetings,
        path: `/audio/merge/${meetingId}`
    });

    return data;
}

export async function createMeeting({
    title,
    filename,
    duration,
    tag,
    templateId,
    platform,
    notes,
    standBy,
    participants
}) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.meetings,
        path: '/create',
        data: {
            title,
            filename,
            duration,
            tag,
            templateId,
            platform,
            notes,
            standBy,
            participants
        }
    });

    return {
        meeting: data.meeting
    };
}

export async function retryMeeting({ meetingId, templateId, participants }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.meetings,
        path: `/retry/${meetingId}`,
        data: {
            templateId,
            participants
        }
    });

    return {
        meeting: data.meeting
    };
}

export async function submitReportCreation(meetingId) {
    const res = await authApiCall({
        method: 'POST',
        endpoint: endpoints.meetings,
        path: `/create/report/${meetingId}`
    });

    return res.status;
}

export async function getMeetingBySearchFilters({ title, tag, date }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.meetings,
        path: '/search',
        data: {
            title,
            tag,
            date
        }
    });

    return {
        meetings: data.data,
        count: data.count
    };
}

export async function updateMeetingTag({ meetingId, tag }) {
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.meetings,
        path: '/update/tag',
        data: {
            meetingId,
            tag
        }
    });
}

export async function updateMeetingTitle({ meetingId, title }) {
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.meetings,
        path: '/update/title',
        data: {
            meetingId,
            title
        }
    });
}

export async function updateMeetingTemplate({ meetingId, templateId }) {
    console.log(templateId);
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.meetings,
        path: `/update/${meetingId}`,
        data: {
            properties: {
                templateId
            }
        }
    });
}

export async function deleteMeetingById({ meetingId }) {
    await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.meetings,
        path: `/delete/${meetingId}`
    });
}
