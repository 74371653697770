import React from 'react';
import MUIBadge from '@material-ui/core/Badge';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

Badge.propTypes = {
    children: PropTypes.any,
    invisible: PropTypes.bool,
    borderColor: PropTypes.string,
    color: PropTypes.string,
    tooltipTitle: PropTypes.string,
    topPosition: PropTypes.string,
    blinking: PropTypes.bool
};

// ! CustomBadge is defined to be able to override the primary color
const CustomBadge = styled(MUIBadge)(({ blinking, color, borderColor }) => ({
    '& .MuiBadge-dot': {
        backgroundColor: color,
        outline: `2px solid ${borderColor}`,
        animation: blinking ? 'blink 1s infinite' : 'none'
    },
    '@keyframes blink': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 }
    }
}));

export default function Badge(props) {
    return (
        <>
            {!props.tooltipTitle || props.invisible ? (
                <CustomBadge
                    color='primary'
                    blinking={props.blinking}
                    sx={{
                        '& .MuiBadge-colorPrimary': {
                            backgroundColor: props.color,
                            outline: `2px solid ${props.borderColor}`
                        }
                    }}
                    variant='dot'
                    invisible={props.invisible}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: props.topPosition ?? 'left'
                    }}
                >
                    {props.children}
                </CustomBadge>
            ) : (
                <Tooltip title='Nuevo'>
                    <CustomBadge
                        color='primary'
                        sx={{
                            '& .MuiBadge-colorPrimary': {
                                backgroundColor: props.color,
                                outline: `3px solid ${props.borderColor}`
                            }
                        }}
                        variant='dot'
                        invisible={props.invisible}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        {props.children}
                    </CustomBadge>
                </Tooltip>
            )}
        </>
    );
}
