import React, { useEffect, useState } from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Box, Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import TemplatesNonUsedAlert from '@common/components/alerts/TemplatesNonUsedAlert';
import IconPhrase from '@common/components/icons/IconPhrase';
import HideableLayout from '@common/components/layout/HideableLayout';
import LoaderSummary from '@common/components/loaders/LoaderSummary';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import { fromArrayTimeToString } from '@common/utils/time';
import { updateMeetingTitle } from '@setup/api/meeting/meeting';
import { getParticipantsByMeetingId } from '@setup/api/participants/participants';
import Chat from '../components/Chat/Chat';
import MeetingNotes from '../components/MeetingNotes';
import MeetingSideMenu from '../components/meetingSideMenu/MeetingSideMenu';
import Report from '../components/Report/Report';
import Summary from '../components/Summary/Summary';

MeetingPageView.propTypes = {
    meeting: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    isShared: PropTypes.bool.isRequired,
    authToken: PropTypes.string,
    reportBlocks: PropTypes.arrayOf(PropTypes.object),
    getReportBlocksHandler: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isFromPremiumUser: PropTypes.bool.isRequired,
    setBlocks: PropTypes.func.isRequired,
    handleUpdateBlocksWords: PropTypes.func.isRequired,
    meetingNotes: PropTypes.arrayOf(PropTypes.any),
    loadingCache: PropTypes.bool.isRequired
};

export default function MeetingPageView({
    meeting,
    date,
    time,
    isShared,
    authToken,
    reportBlocks,
    getReportBlocksHandler,
    loading,
    isFromPremiumUser,
    setBlocks,
    handleUpdateBlocksWords,
    meetingNotes,
    loadingCache
}) {
    const navigate = useNavigate();
    const notification = useNotification();
    const [currentPage, setCurrentPage] = useState(meeting.title);
    const [currentAudioTime, setCurrentAudioTime] = useState(0);
    const [messages, setMessages] = useState([]);
    const [participants, setParticipants] = useState(null);

    useEffect(() => {
        if (!isShared) {
            (async () => {
                if (!participants) {
                    const meetingParticipants = await getParticipantsByMeetingId(meeting.id);
                    setParticipants(meetingParticipants);
                }
            })();
        }
    }, []);

    const handleUpdateTitle = async (title) => {
        try {
            await updateMeetingTitle({ meetingId: meeting.id, title });
            setCurrentPage(title);
            return true;
        } catch (error) {
            notification('title-error');
        }
    };

    const handleScrollToElement = (targetId) => {
        // TODO: we should show the notes button in all tabs but if we are not in the summary to redirect to the summary
        if (targetId) {
            const element = document.getElementById(targetId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    return (
        <Page title={currentPage}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    paddingTop: 8,
                    minHeight: '100vh'
                }}
            >
                <Box
                    component='main'
                    sx={{
                        backgroundColor: 'white',
                        flexGrow: 1,
                        overflow: 'auto',
                        height: 'auto'
                    }}
                >
                    <HideableLayout
                        sticky={true}
                        breadcrumbProps={{
                            previousPage: null,
                            currentPageTitle: currentPage || 'Tu reunión',
                            disabled: isShared
                        }}
                        handleChangeTitle={isShared ? null : handleUpdateTitle}
                        content={
                            <>
                                <Grid container spacing={2} direction='column'>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction='column'
                                            alignItems='left'
                                            spacing={2}
                                            display='flex'
                                        >
                                            {isShared && (
                                                <Grid item>
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        alignItems='center'
                                                    >
                                                        <IconPhrase
                                                            text={`${date} a las ${time}`}
                                                            icon={
                                                                <CalendarTodayOutlinedIcon
                                                                    fontSize='small'
                                                                    sx={{
                                                                        color: palette.primary[300]
                                                                    }}
                                                                />
                                                            }
                                                            color={palette.primary[500]}
                                                            fitContainer={true}
                                                        />
                                                        <IconPhrase
                                                            text={fromArrayTimeToString(
                                                                meeting.duration
                                                            )}
                                                            icon={
                                                                <AccessTimeOutlinedIcon
                                                                    fontSize='small'
                                                                    sx={{
                                                                        color: palette.primary[300]
                                                                    }}
                                                                />
                                                            }
                                                            color={palette.primary[500]}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            )}
                                            {!isShared && (
                                                <Grid item xs={12} sm={8} md={6}>
                                                    <TemplatesNonUsedAlert />
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={8} md={6} sx={{ mr: 2, mb: 10 }}>
                                                <>
                                                    {loading ? (
                                                        <LoaderSummary />
                                                    ) : (
                                                        <>
                                                            {reportBlocks !== null ? (
                                                                <>
                                                                    <Report
                                                                        meetingId={meeting.id}
                                                                        isShared={isShared}
                                                                        blocks={reportBlocks}
                                                                        getReportBlocksHandler={
                                                                            getReportBlocksHandler
                                                                        }
                                                                        setBlocks={setBlocks}
                                                                        setCurrentAudioTime={
                                                                            setCurrentAudioTime
                                                                        }
                                                                    />
                                                                    {!isShared &&
                                                                        meetingNotes?.length >
                                                                            0 && (
                                                                            <MeetingNotes
                                                                                meetingNotes={
                                                                                    meetingNotes
                                                                                }
                                                                                setCurrentAudioTime={
                                                                                    setCurrentAudioTime
                                                                                }
                                                                            />
                                                                        )}
                                                                    {!isShared && (
                                                                        <Chat
                                                                            meetingId={meeting.id}
                                                                            meetingDuration={
                                                                                meeting.duration
                                                                            }
                                                                            meetingTitle={
                                                                                meeting.title
                                                                            }
                                                                            messages={messages}
                                                                            setMessages={
                                                                                setMessages
                                                                            }
                                                                            loadingCache={
                                                                                loadingCache
                                                                            }
                                                                            setBlocks={setBlocks}
                                                                            blocks={reportBlocks}
                                                                            participants={
                                                                                participants
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <Summary
                                                                    content={meeting.summary}
                                                                    transcriptionStatus={
                                                                        meeting.transcriptionStatus
                                                                    }
                                                                    meetingId={meeting.id}
                                                                    isShared={isShared}
                                                                    authToken={authToken}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {isShared && !isFromPremiumUser ? (
                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        TransitionComponent={Slide}
                                        open={true}
                                        message='Crea tus propios resúmenes automáticos con
                                                                Voicit -&gt;'
                                        action={
                                            <Button
                                                onClick={() =>
                                                    navigate(navigation.app.routes.signup)
                                                }
                                                variant='contained'
                                                color='secondary'
                                                disableElevation={true}
                                            >
                                                Usar Voicit Gratis
                                            </Button>
                                        }
                                        key={Slide}
                                    />
                                ) : null}
                            </>
                        }
                        disabled={isShared}
                        hideableContent={
                            <MeetingSideMenu
                                language={meeting.language}
                                tag={meeting.tag}
                                title={meeting.title}
                                platform={meeting.platform}
                                templateId={meeting.templateId}
                                date={date}
                                time={time}
                                filename={meeting.filename}
                                duration={meeting.duration}
                                isShared={isShared}
                                meetingId={meeting.id}
                                authToken={authToken}
                                handleUpdateBlocksWords={handleUpdateBlocksWords}
                                setCurrentAudioTime={setCurrentAudioTime}
                                currentAudioTime={currentAudioTime}
                                participants={participants}
                            />
                        }
                        fabButtons={[
                            {
                                icon: <BookmarksOutlinedIcon fontSize='small' />,
                                function: () => handleScrollToElement('notes'),
                                tooltip: 'Ver notas',
                                hidden: isShared || meetingNotes?.length < 1
                            },
                            {
                                icon: <ForumOutlinedIcon fontSize='small' />,
                                function: () => handleScrollToElement('chat'),
                                tooltip: 'Chat IA',
                                hidden: isShared || messages?.length < 1
                            }
                        ]}
                    />
                </Box>
            </Box>
        </Page>
    );
}
